import { Close, Group, Info, Percent, PercentRounded, Person3 } from '@mui/icons-material'
import { Box, Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material'
import React from 'react'
import CardLayout from '../CardLayout'
import useUserLists from '../../activity/hook/useUserlist'
import { useSelector } from 'react-redux'
import CardComponent from '../../layouts/card/CardComponent'
import { TbReport } from "react-icons/tb";
const Conversion = ({ registrants, totalusers, handleClose }) => {

    const { liveUsers, onDemandUsers } = useUserLists(registrants);

    return (


        // <Box sx={{display:'flex', background:'red', justifyContent:'center'}}>


        <Box sx={{ height: '250px', background: '', m: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography sx={{ fontSize: '2rem' }}>Overview</Typography>



                <IconButton p={0}>
                    <Close fontSize="large" onClick={handleClose} />
                </IconButton>

            </Box>


            <Paper>
                <Grid container display={'flex'} justifyContent={'center'} sx={{ background: '#f5f6fa' }}>


                    {/* <Grid
                        item
                        xs={4}
                        md={4}
                        lg={2}
                        sx={{ display: "flex", justifyContent: "center", alignItems: 'center', background: '', height: '200px' }}
                    >
                        <Box sx={{ height: '150px', width: '150px', borderRadius: '50%', background: 'linear-gradient(#273c75, #192a56)', display: 'flex', placeContent: 'center', placeItems: 'center' }}>
                            <Typography sx={{ fontSize: '2.5rem', color: 'white' }}>
                                {
                                    Math.round((totalusers * 100) / registrants.length)
                                }%
                            </Typography>

                        </Box>
                        
                    </Grid>

                    <Grid
                        item
                        xs={8}
                        md={8}
                        lg={10}
                        sx={{ display: "flex", justifyContent: "start", background: '', alignItems: 'center', height: '200px', placeItems: 'center' }}
                    >
                        <Typography sx={{ fontSize: '1.2rem' }}>
                         (Engagement)   {totalusers} users viewed the event out of {registrants.length} registered users
                        </Typography>
                    </Grid> */}

                    <Grid
                        item
                        xs={6}
                        md={6}
                        lg={6}
                        sx={{ display: "flex", justifyContent: "center", alignItems: 'center', background: '', height: '200px' }}
                    >
                        <Box sx={{ width: '400px' }}>
                        <CardComponent
     title={"Engagement percentage (LIVE)"}
     details={`${liveUsers.length} users watched the Live event out of ${registrants.length} registered users`}
     icon={
       <TbReport fontSize="inherit" color='white' sx={{ color: "white" }} />
     }
     numbers={Math.round((liveUsers.length * 100) / registrants.length) + '%'
    }
     color1={"rgb(14, 149, 148, 1)"}
                      color2={"white"}
                      fontColor={'black'}
                      fontColorTitle={'gray'}
                      fontColorDetails={'gray'}
   />
            
                        </Box>

                    </Grid>

                    <Grid
                        item
                        xs={6}
                        md={6}
                        lg={6}
                        sx={{ display: "flex", justifyContent: "center", alignItems: 'center', background: '', height: '200px' }}
                    >
                        <Box sx={{ width: '400px' }}>

                        <CardComponent
     title={"Engagement percentage (ONDEMAND)"}
     details={`${onDemandUsers.length} users watched the OnDemand event out of ${registrants.length} registered users`}
     icon={
       <TbReport fontSize="inherit" color='white' sx={{ color: "white" }} />
     }
     numbers={Math.round((onDemandUsers.length * 100) / registrants.length) + '%'
    }
     color1={"rgb(14, 149, 148, 1)"}
                      color2={"white"}
                      fontColor={'black'}
                      fontColorTitle={'gray'}
                      fontColorDetails={'gray'}
   />

                        {/* <CardLayout
                                title={"Engagement percentage (LIVE)"}
                                additional_title_component={
                                (<Tooltip title={`${onDemandUsers.length} users watched the OnDemand event out of ${registrants.length} registered users`}>
                                <IconButton>
                                  <Info  style={{color:'white'}}/>
                                </IconButton>
                              </Tooltip>)
                              }
                              height={'160px'}

                                fontColor='black'
                                color1={"#273c75"}
                                color2={"#273c75)"}
                            >

                                 <Box sx={{ height: '90px', width: '90px', borderRadius: '50%', background: 'linear-gradient(#273c75, #192a56)', display: 'flex', placeContent: 'center', placeItems: 'center' }}>
                            <Typography sx={{ fontSize: '2.5rem', color: 'white' }}>
                                {
                                    Math.round((onDemandUsers.length * 100) / registrants.length)
                                }%
                            </Typography>
                        </Box>
                                 </CardLayout> */}
                        </Box>
                    </Grid>






                </Grid>
            </Paper>
        </Box>

        // </Box>


    )
}

export default Conversion