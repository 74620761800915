import React from "react";
import { useEffect } from "react";
import { connect, useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useState } from "react";
import {
  getAllUserLocationNoReg,
  getLiveUsersByEventID,
  getRegistrantDetails,
  getStatByID,
  setUserActivity,
} from "../redux/action/stats";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import Spinner from "./ui/Spinner";
import CardLayout from "./patials/CardLayout";
import { AppBar, CardMedia, Grid, Toolbar, Typography } from "@mui/material";
import CardLayoutRegistrantModal from "./patials/CardLayoutRegistrantModal";
import GeoChart from "./patials/GeoChart";
import CardLayoutInteraction from "./patials/CardLayoutInteraction";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import GroupIcon from "@mui/icons-material/Group";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import PersonIcon from "@mui/icons-material/Person";
import TourIcon from "@mui/icons-material/Tour";
import CardLayoutLiveUsers from "./patials/CardLayoutLiveUsers";
import DeviceChartLayout from "./patials/DeviceChart";
import logo from "../assets/logo.png";
import CardLayoutLiveUsersNoReg from "./patials/CardLayoutLiveUsersNoReg";
import CardLayoutInteractionNoReg from "./patials/CardLayoutInteractionNoReg";
import CardLayoutRegistrantModalNoReg from "./patials/CardLayoutRegistrantModalNoReg";
import CardLayoutLive from "./patials/CardLayoutLive";
import GraphContainer from "./patials/GraphContainer";
import Brightcove_Referrer from "./patials/brightcoveStats/CardReferrer";
import CardLayoutOverview from "./patials/CardLayoutOverview";
import { Groups3, QueryStats } from "@mui/icons-material";
import CardComponent from "./layouts/card/CardComponent";
import RegModal from "./layouts/RegModal";
import { BsBookHalf, BsBugFill, BsFillInfoCircleFill, BsPinMapFill, BsSliders2Vertical } from "react-icons/bs";
import GeoChartByCity from "./patials/GeoChartByCity";
import { MdOutlineDevices } from "react-icons/md";
import OverallpieChart from "./layouts/pieChart/OverallpieChart";
import { VscGraph } from "react-icons/vsc";
import PrePostReg from "./layouts/graph/PrePostReg";
import OnDemandGraphWatched from "./layouts/graph/OnDemandGraphWatched";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    flexGrow: 1,
  },
}));
const StatsForClient = (props) => {
  let {socketWc} = props

  let { clientToken } = useParams();
  let clientJWT = jwt_decode(clientToken);

  const [eventFound, setEventFound] = useState(false);

  const stats_by_id = useSelector((state) => state.stats.statsByID);
  const loading = useSelector((state) => state.stats.loading);

  // RT
  const rt_Users = useSelector((state) => state.stats.rtActiveUsers);
  const rtTotalActiveUsers = useSelector(
    (state) => state.stats.rtTotalActiveUsers
  );
  const rtLoading = useSelector((state) => state.stats.rtLoading);
  const rtTimeStamp = useSelector((state) => state.stats.rtTimeStamp);

  // Registrants
  const registrants = useSelector((state) => state.stats.registrant);
  const totalLiveUsers = useSelector((state) => state.stats.totalLiveUsers);
  const totalOnDemandUsers = useSelector(
    (state) => state.stats.totalOnDemandUsers
  );
  const avgTimeOnPage = useSelector((state) => state.stats.averageTimeOnPage);
  //Event
  const event_id = useSelector((state) => state.stats.event_ID);
  const eventStartTime = useSelector((state) => state.stats.eventStartTime);
  const eventEndTime = useSelector((state) => state.stats.eventEndTime);
  const geoData = useSelector((state) => state.stats.geoData);
  const deviceInformation = useSelector(
    (state) => state.stats.deviceInformation
  );
  const isLive = useSelector((state) => state.stats.isLive);
  const eventWithNoReq = useSelector((state) => state.stats.eventNoReg);
  const graphData = useSelector((state) => state.stats.graphData);
  const eventType = useSelector((state) => state.stats.eventType);
  const brightcoveView = useSelector((state) => state.stats.brightcoveView)
  const brightcove_stats_referrer_domain = useSelector((state) => state.stats.brightcove_stats_referrer_domain);
  const timeStamp_event_started = useSelector((state) => state.stats.timeStamp_event_started)
  const timeStamp_event_ended = useSelector((state) => state.stats.timeStamp_event_ended)
  const regCount = useSelector((state) => state.stats.regCount);
  const storesUserActivity = useSelector((state) => state.stats.userActivityByEvent)

  
  useEffect(() => {
    console.log("JWT WITH DATA", clientJWT);
    props.dispatch(getStatByID(clientJWT.eventID));
    // props.dispatch(getRegistrantDetails(clientJWT.eventID));
  }, []);

  // const [geoData, setGeoData] = useState(null);
  const pollingRT = () => {
    // props.dispatch(getLiveUsersByEventID(clientJWT.eventID));
  };

  useEffect(() => {
    const timer = setInterval(pollingRT, 30000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (eventWithNoReq) {
      console.log('eventNoRegeventNoRegeventNoReg', eventWithNoReq)
      props.dispatch(setUserActivity(event_id))
      props.dispatch(getAllUserLocationNoReg(event_id))
    }
  }, [eventWithNoReq])

  // useEffect(() => {
  //   if (!loading) {
  //     let TempGeoData = [];
  //     let countries = stats_by_id.arrGeographCountries;
  //     let counts = stats_by_id.arrGeographCount;

  //     var totalLength = countries.length;
  //     var i = 0;
  //     while (totalLength > 0) {
  //       TempGeoData.push([countries[i], counts[i]]);
  //       totalLength--;
  //       i++;
  //     }
  //     setGeoData(TempGeoData);
  //   }
  // }, []);

  useEffect(() => {
    // fetch event by id
    if (clientJWT) {
      // console.log('token')
    }
  }, []);
  const classes = useStyles();

  return (
    <div className={classes.rootContainer}>
      {loading ? (
        <Box sx={{ display: "flex",flexDirection:'column' ,alignItems: "center", justifyContent:'center', marginTop:'10%'}}>
       
          <Spinner />
          <Typography>
          Please wait while we load your content...
          </Typography>
        </Box>
      ) : (
        <>
          <AppBar
            position="sticky"
            elevation={4}
            style={{
              // position: "fixed",
              top: 0,
              left: 0,
              margin: 0,
              background: "#2d3436",
            }}
          >
            <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                {clientJWT.eventName}
              </Typography>

              <CardMedia
                component="img"
                height="40"
                image={logo}
                alt="BRR Logo"
                sx={{
                  objectFit: "contain",
                  width: "auto",
                  textAlign: "center",
                  m: "0.5rem",
                }}
              />
            </Toolbar>
          </AppBar>

          <Grid container justifyContent={"center"} spacing={4} p={2}>


              {
                eventType === 'bc' ?


                <>
                
                <Grid item xs={12} md={6} lg={3}>
                    {
                      clientJWT.bcViews === true ? (
                        <>
                  <CardLayout
                    title={"Views"}
                    details={"Total views"}
                    icon={
                      <GroupIcon fontSize="large" sx={{ color: "white" }} />
                    }
                    numbers={brightcoveView}
                    color1={"rgba(18, 115, 117,1)"}
                    color2={"rgba(18, 115, 117,0.8)"}
                  />
                        </>

                      ) : (<></>)
                    }


                    
                  
</Grid>


{clientJWT.bcReferrer === true ? (
                        <>
                            <Grid item  xs={12} md={6} lg={10}>
                 
                 <Brightcove_Referrer  brightcove_stats_referrer_domain={brightcove_stats_referrer_domain}/>
             </Grid>
                        </>
                      ) : (
                        <></>
                      )}
<Grid
                      item
                      container
                      justifyContent={"center"}
                      spacing={4}
                      p={2}
                    >
                      {clientJWT.geoData === true ? (
                        <>
                          {geoData?.length > 0 &&
                            geoData !== "Error" &&
                            geoData !== "No data found" && (
                              <Grid
                                item
                                sx={{
                                  width: {
                                    xs: "90%",
                                    sm: "90%",
                                    md: "50%",
                                    lg: "50%",
                                  },
                                }}
                              >
                                {loading ? (
                                  <Spinner />
                                ) : (
                                  <GeoChart geoData={geoData} />
                                )}
                              </Grid>
                            )}
                        </>
                      ) : (
                        <></>
                      )}
    
                      {clientJWT.platform === true ? (
                        <>
                          {deviceInformation?.length > 0 &&
                            deviceInformation !== "Error" &&
                            deviceInformation !== "No data found" && (
                              <Grid
                                item
                                sx={{
                                  width: {
                                    xs: "90%",
                                    sm: "90%",
                                    md: "50%",
                                    lg: "50%",
                                  },
                                }}
                              >
                                {loading ? (
                                  <Spinner />
                                ) : (
                                  <DeviceChartLayout
                                    deviceData={deviceInformation}
                                  />
                                )}
                              </Grid>
                            )}
                        </>
                      ) : (
                        <></>
                      )}
    
              
                    </Grid>
</>








                :
                <>
             
                {registrants?.length > 0 ? (
                  <>
                    {clientJWT.usersNow === true ? (
                      <>
                        {rt_Users?.length > 0 && isLive ? (
                          <Grid item xs={12} md={6} lg={3}>
                            {/* Registrant Modal  */}
    
                            {eventWithNoReq ? (
                              <CardLayoutLiveUsersNoReg
                                icon={
                                  <LiveTvIcon
                                    fontSize="large"
                                    sx={{ color: "white" }}
                                  />
                                }
                                registrants={rt_Users}
                                eventStartDate={eventStartTime}
                                eventEndDate={eventEndTime}
                                title={"Users now"}
                                live={isLive}
                                numbers={loading ? <Spinner /> : rtTotalActiveUsers}
                                color1={"rgba(242, 84, 45, 1)"}
                                color2={"rgba(242, 84, 45, 0.8)"}
                              />
                            ) : (
                              <CardLayoutLiveUsers
                                icon={
                                  <LiveTvIcon
                                    fontSize="large"
                                    sx={{ color: "white" }}
                                  />
                                }
                                registrants={rt_Users}
                                eventStartDate={eventStartTime}
                                eventEndDate={eventEndTime}
                                title={"Users now"}
                                live={isLive}
                                numbers={loading ? <Spinner /> : rtTotalActiveUsers}
                                color1={"rgba(242, 84, 45, 1)"}
                                color2={"rgba(242, 84, 45, 0.8)"}
                              />
                            )}
                            {/* Registrant Modal  */}
                          </Grid>
                        ) : (
                          <Grid item xs={12} md={6} lg={3}>
                            <CardLayoutLive
                              title={"Users now"}
                              numbers={"-"}
                              color1={"rgba(242, 84, 45, 1)"}
                              color2={"rgba(242, 84, 45, 0.8)"}
                            >
                              <p style={{ color: "#fbc531", textAlign: "center" }}>
                                {/* {rtTimeStamp} */}
                              </p>
                            </CardLayoutLive>
                          </Grid>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
    
                    {clientJWT.interactions === true ? (
                      <>
                        {/* {registrants?.length > 0 && (
                          <Grid item xs={12} md={6} lg={3}>
                            {eventWithNoReq ? (
                              <CardLayoutInteractionNoReg
                                icon={
                                  <TourIcon
                                    fontSize="large"
                                    sx={{ color: "white" }}
                                  />
                                }
                                registrants={registrants}
                                title={"User Interactions"}
                                color1={"#e58e26"}
                                color2={"#fa983a"}
                              />
                            ) : (
                              <CardLayoutInteraction
                                icon={
                                  <TourIcon
                                    fontSize="large"
                                    sx={{ color: "white" }}
                                  />
                                }
                                registrants={registrants}
                                title={"User Interactions"}
                                color1={"#e58e26"}
                                color2={"#fa983a"}
                              />
                            )}
                          </Grid>
                        )} */}


{
                          registrants?.length && (
                                <>
                                    {!eventWithNoReq && 
                                      <Grid item xs={12} md={6} lg={3}>
                                            <CardLayoutInteraction
                        icon={
                          <TourIcon fontSize="large" sx={{ color: "white" }} />
                        }
                        socketWc={socketWc}
                        registrants={registrants}
                        title={"User Interactions"}
                        color1={"#cfbc9f"}
                        color2={"rgb(207 188 159 / 80%)"}
                      />
                                      </Grid>
                                    }
                                </>
                          )
                        }
                      </>
                    ) : (
                      <></>
                    )}
    


              {/* Overview  */}
              {clientJWT.overview === true ? (
                       registrants?.length && (
                        <>
                            {!eventWithNoReq && 
                              <Grid item xs={12} md={6} lg={3}>
          <CardLayoutOverview
                icon={
                  <QueryStats fontSize="large" sx={{ color: "white" }} />
                }
                socketWc={socketWc}
                registrants={registrants}
                totalLiveUsers={loading ? 0 : totalLiveUsers}
                totalOnDemandUsers={loading ? 0 : totalOnDemandUsers}
                totalusers={totalLiveUsers + totalOnDemandUsers}
                title={"Overview"}
                color1={"#273c75"}
                color2={"#40739e"}
              />
                              </Grid>
                            }
                        </>
                  )
                    ) : (
                      <></>
                    )}
              {/* No Reg total views  */}
              {clientJWT.noRegTotalviews === true ? (
          



          <Grid item xs={12} md={6} lg={3}>

                    <CardComponent
                          title={"Total Views"}
                          details={"Total unique views"}
                          icon={
                            <Groups3 fontSize="inherit" sx={{ color: "white" }} />
                          }
                          numbers={loading ? <Spinner /> : registrants?.length}
                          color1={"#2d3436"}
                          color2={"white"}
                          fontColor={'black'}
                          fontColorTitle={'gray'}
                          fontColorDetails={'gray'}
                          height={'170px'}
                        />
</Grid>






                    ) : (
                      <></>
                    )}
  {clientJWT.totalRegistrant === true ? (
                      <Grid item xs={12} md={6} lg={3}>
                        {/* Registrant Modal  */}
                        {eventWithNoReq ? (
                          <CardComponent
                          title={"Total Views"}
                          details={"Total unique views"}
                          icon={
                            <Groups3 fontSize="inherit" sx={{ color: "white" }} />
                          }
                          numbers={loading ? <Spinner /> : registrants?.length}
                          color1={"#2d3436"}
                          color2={"white"}
                          fontColor={'black'}
                          fontColorTitle={'gray'}
                          fontColorDetails={'gray'}
                        />
                        ) : (
                          <CardLayoutRegistrantModal
                            registrants={registrants}
                            eventStartDate={eventStartTime}
                            eventEndDate={eventEndTime}
                            title={"Total Registrants"}
                            numbers={loading ? <Spinner /> : registrants?.length}
                            color1={"#2d3436"}
                            color2={"#636e72"}
                            hideTimeSpent={true}
                          />
                        )}
                        {/* Registrant Modal  */}
                      </Grid>
                    ) : (
                      <></>
                    )}
                    {clientJWT.liveUsers === true ? (
                      <Grid item xs={12} md={6} lg={3}>
                        {/* <CardLayout
                          title={"Live Users"}
                          icon={
                            <PersonIcon fontSize="large" sx={{ color: "white" }} />
                          }
                          details={"Users who have watched live"}
                          numbers={loading ? <Spinner /> : totalLiveUsers}
                          color1={"rgb(14, 149, 148, 1)"}
                          color2={"rgb(14, 149, 148, 0.8)"}
                        /> */}
                        {
                      registrants?.length && (
                        <CardComponent
                        title={"Watched Live"}
                        details={"Users who have watched live"}
                        icon={
                          <PersonIcon fontSize="inherit" sx={{ color: "white" }} />
                        }
                        numbers={loading ? <Spinner /> : totalLiveUsers}
                        color1={"rgb(14, 149, 148, 1)"}
                        color2={"white"}
                        fontColor={'black'}
                        fontColorTitle={'gray'}
                        fontColorDetails={'gray'}
                        height={'170px'}
                      >
                        {
                          !eventWithNoReq && <RegModal data={loading ? <Spinner /> : registrants.filter((each) => each.watchedLive === true)} />

                        }

                      </CardComponent>
                      )
                    }
                      </Grid>
                    ) : (
                      <></>
                    )}


                    {
                      clientJWT.preRegWatchedLive === true && (
                     <Grid item xs={12} md={6} lg={3}>

                          {registrants?.length && (
                        <CardComponent
                        title={"Pre Reg + Watched Live"}
                        details={"Users who had registered before event and watched  Live"}
                        icon={
                          <PersonIcon fontSize="inherit" sx={{ color: "white" }} />
                        }
                        numbers={loading ? <Spinner /> : registrants.filter((each) => new Date(each.date).getTime() <= new Date(timeStamp_event_started).getTime()).filter((each) =>(each.watchedLive === true && each.watchedOnDemand === false)).length}
                        color1={"rgb(14, 149, 148, 1)"}
                        color2={"white"}
                        fontColor={'black'}
                        fontColorTitle={'gray'}
                        fontColorDetails={'gray'}
                        height={'170px'}
                      >
                        {
                          !eventWithNoReq && <RegModal data={loading ? 'loading' : registrants.filter((each) => new Date(each.date).getTime() <= new Date(timeStamp_event_started).getTime()).filter((each) => (each.watchedLive === true && each.watchedOnDemand === false))} />

                        }

                      </CardComponent>
                    )}
                        </Grid>
                      )
                    }
    
                    {clientJWT.donDemandUsers === true ? (
                      <Grid item xs={12} md={6} lg={3}>
                        {/* <CardLayout
                          title={"On Demand users"}
                          details={"Users who have watched on demand"}
                          icon={
                            <PersonIcon fontSize="large" sx={{ color: "white" }} />
                          }
                          numbers={loading ? <Spinner /> : totalOnDemandUsers}
                          color1={"rgb(14, 149, 148, 1)"}
                          color2={"rgb(14, 149, 148, 0.8)"}
                        /> */}
                             {registrants?.length && (
                                 <CardComponent
                                 title={"Watched On Demand"}
                                 details={"Users who have watched on demand"}
                                 icon={
                                   <PersonIcon fontSize="inherit" sx={{ color: "white" }} />
                                 }
                                 numbers={loading ? <Spinner /> : totalOnDemandUsers}
                                 color1={"rgb(14, 149, 148, 1)"}
                                 color2={"white"}
                                 fontColor={'black'}
                                 fontColorTitle={'gray'}
                                 fontColorDetails={'gray'}
                                 height={'170px'}
                               >
                                 {
                                   !eventWithNoReq && <RegModal data={loading ? 'loading' : registrants.filter((each) => each.watchedOnDemand === true)} />
         
                                 }
         
                               </CardComponent>
                    )}
                      </Grid>
                    ) : (
                      <></>
                    )}


{
                      clientJWT.preRegWatchedOnDemand === true && (
                     <Grid item xs={12} md={6} lg={3}>

{registrants?.length && (   <CardComponent
                        title={"Pre Reg + On Demand"}
                        details={"Users who had registered before event and watched On Demand"}
                        icon={
                          <PersonIcon fontSize="inherit" sx={{ color: "white" }} />
                        }
                        numbers={loading ? <Spinner /> : registrants.filter((each) => new Date(each.date).getTime() <= new Date(timeStamp_event_started).getTime()).filter((each) => (each.watchedOnDemand === true && each.watchedLive === false )).length}
                        color1={"rgb(14, 149, 148, 1)"}
                        color2={"white"}
                        fontColor={'black'}
                        fontColorTitle={'gray'}
                        fontColorDetails={'gray'}
                        height={'170px'}
                      >
                        {
                          !eventWithNoReq && <RegModal data={loading ? 'loading' : registrants.filter((each) => new Date(each.date).getTime() <= new Date(timeStamp_event_started).getTime()).filter((each) => (each.watchedOnDemand === true && each.watchedLive === false ) )} />

                        }

                      </CardComponent>)}
                        </Grid>
                      )
                    }



{
                      clientJWT.didNotWatched === true && (
                     <Grid item xs={12} md={6} lg={3}>

{registrants?.length && ( <CardComponent
                        title={"Not watched"}
                        details={"Users who had registered but did not watched Live or On Demand"}
                        icon={
                          <PersonIcon fontSize="inherit" sx={{ color: "white" }} />
                        }
                        numbers={loading ? <Spinner /> : registrants.filter((each) => !each.watchedOnDemand && !each.watchedLive).length}
                        color1={"rgb(14, 149, 148, 1)"}
                        color2={"white"}
                        fontColor={'black'}
                        fontColorTitle={'gray'}
                        fontColorDetails={'gray'}
                        height={'180px'}
                      >
                        {

                          !eventWithNoReq && <RegModal data={loading ? 'loading' : registrants.filter((each) => !each.watchedOnDemand && !each.watchedLive)} />
                        }


                      </CardComponent>)}
                        </Grid>
                      )
                    }


                    
    
                  
    
                    {clientJWT.totalUsers === true ? (
                      <Grid item xs={12} md={6} lg={3}>
                         {registrants?.length && (    <CardComponent
                        title={"Total users"}
                        details={"(Live or On demand)"}
                        icon={
                          <GroupIcon fontSize="inherit" sx={{ color: "white" }} />
                        }
                        //  numbers={loading ? <Spinner /> : totalLiveUsers + totalOnDemandUsers}
                        numbers={loading ? <Spinner /> : registrants.filter((each) => (each.watchedOnDemand === true || each.watchedLive === true) || (each.watchedOnDemand === true && each.watchedLive === true)).length}
                        color1={"rgba(18, 115, 117,1)"}
                        color2={"white"}
                        fontColor={'black'}
                        fontColorTitle={'gray'}
                        fontColorDetails={'gray'}
                        height={'180px'}

                      >
                        {
                          !eventWithNoReq && <RegModal data={loading ? 'loading' : registrants.filter((each) => (each.watchedOnDemand === true || each.watchedLive === true) || (each.watchedOnDemand === true && each.watchedLive === true))} />

                        }

                      </CardComponent>)}
                      </Grid>
                    ) : (
                      <></>
                    )}
    
                    {clientJWT.averageTime === true ? (
                      <>
                        {avgTimeOnPage && (
                          <Grid item xs={12} md={6} lg={3}>
                               <CardComponent
                  title={"Average Time"}
                  details={"Average time spent on event"}
                  icon={
                    <AccessTimeFilledIcon fontSize="inherit" sx={{ color: "white" }} />
                  }
                  timeFormat={true}
                  numbers={avgTimeOnPage}
                  color1={"rgba(242, 84, 45, 1)"}
                  color2={"white"}
                  fontColor={'black'}
                  fontColorTitle={'gray'}
                  fontColorDetails={'gray'}
                  height={'180px'}

                />
                          </Grid>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
    
                    <Grid
                      item
                      container
                      justifyContent={"center"}
                      spacing={4}
                      p={2}
                    >
                      {clientJWT.geoData === true ? (
                        <>
                          {geoData?.length > 0 &&
                            geoData !== "Error" &&
                            geoData !== "No data found" && (
                              <Grid
                              item
                              xs={12} md={6} lg={6}
                            >
                                {loading ? (
                                  <Spinner />
                                ) : (
                                  <CardComponent
                                  title={"Geo Data Map"}
                                  details={"The geolocation data only shows the location of users who are sharing their public IP address"}
                                  icon={
                                    <BsPinMapFill fontSize="inherit" color="white" sx={{ color: "white" }} />
                                  }
                                  color1={"rgb(45, 52, 54)"}
                                  height={'550px'}
                                  color2={"white"}
                                  fontColor={'black'}
                                  fontColorTitle={'black'}
                                  fontColorDetails={'gray'}
                                >
            
                                  {loading ? <Spinner /> : <GeoChart geoData={geoData} />}
            
                                </CardComponent>
                                )}
                              </Grid>
                            )}
                        </>
                      ) : (
                        <></>
                      )}




{
                      clientJWT.geoCity === true && (
                        <Grid
                        item
                        xs={12} md={6} lg={6}
                      >

{registrants?.length && (   <CardComponent
                      title={"User Distribution by City"}
                      details={"The geolocation data only shows the location of users who are sharing their public IP address"}
                      icon={
                        <BsPinMapFill fontSize="inherit" color="white" sx={{ color: "white" }} />
                      }
                      color1={"rgb(45, 52, 54)"}
                      height={'550px'}
                      color2={"white"}
                      fontColor={'black'}
                      fontColorTitle={'black'}
                      fontColorDetails={'gray'}
                    >

                      {loading ? <Spinner /> : (eventWithNoReq ? <GeoChartByCity registrants={registrants} geoData={geoData} /> : <GeoChartByCity registrants={registrants} geoData={geoData} />)}

                    </CardComponent>)}
                        </Grid>
                      )
                    }

{
                      clientJWT.NoRegActivityOverView === true && (
                        <Box elevation={2} sx={{ background: '', m: 1, mt: 4, borderRadius: '10px' }}>

                        <Typography sx={{ fontSize: '1.4rem', marginTop: '2rem', textAlign: 'center' }}>
                          Activity Overview
                        </Typography>
                        <Grid item container justifyContent={"center"} spacing={4} p={2}>
          
          
          
                          <Grid item xs={12} md={6} lg={3} xl={2}>
          
          
                            <CardComponent
                              title={"Slider Count"}
                              details={"Total number of time Slider was clicked"}
                              icon={
                                <BsSliders2Vertical fontSize="inherit" color="white" sx={{ color: "white" }} />
                              }
                              numbers={loading ? <Spinner /> : storesUserActivity?.filter((each) => each.type === 'SLIDER').length}
                              color1={"#273c75"}
                              color2={"white"}
                              fontColor={'black'}
                              fontColorTitle={'gray'}
                              fontColorDetails={'gray'}
                            />
           
                          </Grid>
          
                          <Grid item xs={12} md={6} lg={3} xl={2}>
                            <CardComponent
                              title={"Notes  accessed"}
                              details={"Total number of time Notes was used"}
                              icon={
                                <BsBookHalf fontSize="inherit" color="white" sx={{ color: "white" }} />
                              }
                              numbers={loading ? <Spinner /> : storesUserActivity?.filter((each) => each.type === 'NOTE').length}
                              color1={"#273c75"}
                              color2={"white"}
                              fontColor={'black'}
                              fontColorTitle={'gray'}
                              fontColorDetails={'gray'}
                            />

                          </Grid>
          
                          <Grid item xs={12} md={6} lg={3} xl={2}>
                            <CardComponent
                              title={"No. of issues"}
                              details={"Total number of time issues were occured"}
                              icon={
                                <BsBugFill fontSize="inherit" color="white" sx={{ color: "white" }} />
                              }
                              numbers={loading ? <Spinner /> : storesUserActivity?.filter((each) => each.type === 'HELP').length}
                              color1={"#273c75"}
                              color2={"white"}
                              fontColor={'black'}
                              fontColorTitle={'gray'}
                              fontColorDetails={'gray'}
                            />

                          </Grid>
          
                          <Grid item xs={12} md={6} lg={3} xl={2}>
                            <CardComponent
                              title={"Info Tab clicked"}
                              details={"Total number of time info tab was clicked"}
                              icon={
                                <BsFillInfoCircleFill fontSize="inherit" color="white" sx={{ color: "white" }} />
                              }
                              numbers={loading ? <Spinner /> : storesUserActivity?.filter((each) => each.type === 'INFO').length}
                              color1={"#273c75"}
                              color2={"white"}
                              fontColor={'black'}
                              fontColorTitle={'gray'}
                              fontColorDetails={'gray'}
                            />

                          </Grid>
          
          
                        </Grid>
          
                      </Box>
                      )
                    }
    
                      {clientJWT.platform === true ? (
                        <>
                          {deviceInformation?.length > 0 &&
                            deviceInformation !== "Error" &&
                            deviceInformation !== "No data found" && (
                              <Grid
                                item
                                xs={12} md={6} lg={6}
                              >
                                {loading ? (
                                  <Spinner />
                                ) : (
                                  <CardComponent
                                  title={"Platform distribution"}
                                  icon={
                                    <MdOutlineDevices fontSize="inherit" color="white" sx={{ color: "white" }} />
                                  }
                                  color1={"rgb(45, 52, 54)"}
                                  height={'550px'}
                                  color2={"white"}
                                  fontColor={'black'}
                                  fontColorTitle={'black'}
                                  fontColorDetails={'gray'}
                                >
            
                                  <DeviceChartLayout deviceData={deviceInformation} />
            
                                </CardComponent>
                                )}
                              </Grid>
                            )}
                        </>
                      ) : (
                        <></>
                      )}




                      {
                        clientJWT.viewsDistribution === true && (
                          <>
                           {
              registrants.length > 0 && <Grid
                item
                xs={12} md={6} lg={6}
              >
                <CardComponent
                  title={"Overall views distribution"}
                  icon={
                    <MdOutlineDevices fontSize="inherit" color="white" sx={{ color: "white" }} />
                  }
                  color1={"rgb(45, 52, 54)"}
                  height={'550px'}
                  color2={"white"}
                  fontColor={'black'}
                  fontColorTitle={'black'}
                  fontColorDetails={'gray'}
                >

                  <OverallpieChart registrants={registrants} />

                </CardComponent>
              </Grid>

            }
                          </>
                        )
                      }
                   

    
                      {clientJWT.graph === true ? (
                        <>
                          <Grid
                            item
                            container
                            justifyContent={"center"}
                            spacing={4}
                            p={2}
                          >
                            {graphData?.length > 0 &&
                              graphData !== "Error" &&
                              graphData !== "No data found" && (
                                <Grid
                                  item
                                  xs={12} md={6} lg={12}
                                >
                                  {loading ? (
                                    <Spinner />
                                  ) : (
                                    // <GraphLayout graphData={graphData} />
                                    <CardComponent
                                    title={"Graph"}
                                    icon={
                                      <VscGraph fontSize="inherit" color="white" sx={{ color: "white" }} />
                                    }
                                    color1={"rgb(45, 52, 54)"}
                                    height={'550px'}
                                    color2={"white"}
                                    fontColor={'black'}
                                    fontColorTitle={'black'}
                                    fontColorDetails={'gray'}
                                  >
              
                                    <GraphContainer graphData={graphData} />
              
                                  </CardComponent>
                                  )}
                                </Grid>
                              )}
                          </Grid>
                        </>
                      ) : (
                        <></>
                      )}


                          {
                            clientJWT.prePostRegGrpah === true && (
                              <>
                                {regCount && !eventWithNoReq && (

                                  <Grid
                                    item
                                    xs={12} md={6} lg={12}
                                  >
                                    {loading ? (
                                      <Spinner />
                                    ) : (

                                      <CardComponent
                                        title={"Graph (Number of Registrations Pre and Post event)"}

                                        icon={
                                          <VscGraph fontSize="inherit" color="white" sx={{ color: "white" }} />
                                        }
                                        color1={"rgb(45, 52, 54)"}
                                        height={'500px'}
                                        color2={"white"}
                                        fontColor={'black'}
                                        fontColorTitle={'black'}
                                        fontColorDetails={'gray'}
                                      >

                                        <PrePostReg regCount={regCount} />
                                      </CardComponent>
                                    )}
                                  </Grid>
                                )}

                              </>
                            )
                        }



                        {
                          clientJWT.seperateOnDemandGraph === true && (
                            <>
                             {graphData?.length > 0 &&
              graphData !== "Error" &&
              graphData !== "No data found" && (


                <Grid
                  item
                  xs={12} md={6} lg={12}
                >
                  {loading ? (
                    <Spinner />
                  ) : (

                    <CardComponent
                      title={"Graph (Number of users watched On Demand)"}

                      icon={
                        <VscGraph fontSize="inherit" color="white" sx={{ color: "white" }} />
                      }
                      color1={"rgb(45, 52, 54)"}
                      height={'auto'}
                      color2={"white"}
                      fontColor={'black'}
                      fontColorTitle={'black'}
                      fontColorDetails={'gray'}
                    >

                      <OnDemandGraphWatched graphData={graphData} />
                    </CardComponent>
                  )}
                </Grid>




              )}
                            </>
                          )
                        }






                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12} md={6} lg={3}>
                    <CardLayout
                      title={"No data found"}
                      color1={"#fa983a"}
                      color2={"#ffeaa7"}
                    />
                  </Grid>
                )}
   </>

              }    


          








          </Grid>
        </>
      )}
    </div>
  );
};
export default connect()(StatsForClient);
