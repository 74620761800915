import {
  AppBar,
  Box,
  Button,
  Grid,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect, useSelector } from "react-redux";
import { getAuthToken } from "../redux/action/auth";
import { useNavigate } from "react-router-dom";
import Header from "./ui/Header";
import { setAlert } from "../redux/action/alert";
import AlertCard from "./alert/AlertCard";

const Auth = (props) => {
  let navigate = useNavigate();

  const [tokenField, setTokenField] = useState("");
  const auth = useSelector((state) => state.auth);

  const { isAuth, error } = auth;

  //Already authenticcated with token
  const redirect = () => {
    navigate("/statshome");
  };

  useEffect(() => {
    // console.log(auth);
    if (localStorage.getItem("statsToken")) {
      redirect();
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(tokenField)

    props.dispatch(getAuthToken(tokenField, redirect));
  };

  const handleChange = (e) => {
    setTokenField(e.target.value);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>                    
<AlertCard/>
     
      <Header title={"BRR Stats Portal"} />

      <Grid justifyContent={"center"}>
        <form onSubmit={handleSubmit}>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            display="flex"
            justifyContent="center"
            m={2}
          >
            <TextField
              value={tokenField}
              onChange={handleChange}
              sx={{ width: "50%" }}
              label="Enter Token"
              variant="outlined"
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            display="flex"
            justifyContent="center"
            m={2}
          >
            <Button type="submit" variant="outlined">
              Submit
            </Button>
          </Grid>
        </form>
      </Grid>
    </Box>
  );
};

export default connect()(Auth);
