import React from "react";
import { useEffect } from "react";
import { useState } from "react";

//MUI
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  Fade,
  Grid,
  Modal,
  Paper,

  Typography,
} from "@mui/material";

import { makeStyles } from "@mui/styles";
import moment from "moment/moment";
import { DataGrid, GridToolbar, GridColDef, useGridApiRef } from '@mui/x-data-grid';
import { v4 as uuidv4 } from 'uuid';

//Redux
import { connect, useSelector } from "react-redux";

//Utils
import { secondsToHour } from "../../utils/secondsToHr";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  // height: "90vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const useStyles = makeStyles((theme) => ({
  hoverCard: {
    transition: "background-Color 0.5s ease",
    "&:hover": {
      backgroundColor: "#576574",
      cursor: "default",
    },
  },


}));

const CardLayoutRegistrantModal = ({
  title,
  numbers,
  color1,
  color2,
  timeFormat,
  spanTag,
  ExtraOptions,
  children,
  registrants,
  eventStartDate,
  eventEndDate,
  icon,
  cpLayout,
  hideTimeSpent
}) => {
  const removeExportButtonCSS = `
  #:r12: {
    display: none;
  }
`;

// Create a style element to apply the custom CSS
const styleElement = document.createElement('style');
styleElement.type = 'text/css';
styleElement.appendChild(document.createTextNode(removeExportButtonCSS));

// Add the style element to the head of the document
document.head.appendChild(styleElement);
const elements = document.querySelector(`[aria-label="Export"]`);

  // elements.style.display = 'none'

console.log('aria-label="export',elements)

  const stats = useSelector((state) => state.stats);

  const { globalFilter, searchText, loading } = stats;
  const d = new Date();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // console.log(registrants)

  const [parsedRegistrant, setParsedRegistrant] = useState([]);
  const [registrantColumn, setRegistrantColumn] = useState([]);

  useEffect(() => {
    if (registrants) {
      let p = registrants.map((eachReg) => {
        let parsed = JSON.parse(eachReg.fields);

          if(hideTimeSpent) {
            return {
              id: uuidv4(),
              ...parsed,
              registration: moment(eachReg.date).format('LLL'),
              // Live: eachReg.watchedLive === true ? 'Yes' : 'No',
              // OnDemand: eachReg.watchedOnDemand === true ? 'Yes' : 'No',
              accessed: eachReg.loggedInTime === '0' ?  'n/a' :eachReg.loggedInTime
            };
          } else {
            return {
              id: uuidv4(),
              ...parsed,
              registration: moment(eachReg.date).format('LLL'),
              // timeSpent: eachReg.timeSpent ? secondsToHour(eachReg.timeSpent) : 'N/A',
              // Live: eachReg.watchedLive === true ? 'Yes' : 'No',
              // OnDemand: eachReg.watchedOnDemand === true ? 'Yes' : 'No',
              Referrer: eachReg?.domain ? eachReg.domain : 'n/a',
              region:eachReg.geo_location?.region ? eachReg.geo_location.region : 'n/a',
              city:eachReg.geo_location?.city ? eachReg.geo_location.city : 'n/a',
              accessed: eachReg.loggedInTime === '0' ?  'n/a' :eachReg.loggedInTime
              
            };
            
          }



      });
      setParsedRegistrant(p);

      setRegistrantColumn(Object.keys(p[0]))

      let parsedColumn = Object.keys(p[0]).map((eachCo) => {
        console.log('referrerCOlumn',eachCo)
        return {

          field: eachCo,
          headerName: eachCo,
          align: "left",
          width:eachCo === 'Referrer' ? 300 : 240,

        }
      })

      parsedColumn.shift()
      setRegistrantColumn(parsedColumn)
    }
  }, []);


  const apiRef = useGridApiRef();


  return (


    <>
      <Card
        className={classes.hoverCard}
        elevation={6}
        sx={{
          width:cpLayout ? '200px' : 'auto',
          height: "150px",
          // backgroundColor: `${color}`,
          background: `linear-gradient(to right bottom, ${color1}, ${color2})`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius:'20px',

        }}
      >
        <CardContent>
          <Box sx={{ justifyContent: "center" }}>
            {/* Active Users have chlid component for RT  */}

            <Typography sx={{ fontSize: "1rem" }} color="white">
              {title}
            </Typography>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {icon}
</div>
            <Typography
              sx={{ color: "white", fontSize: "1.5rem", textAlign: "center" }}
            >
              {timeFormat ? secondsToHour(numbers) : numbers}
            </Typography>
            {children}

            {/* Modal start  */}
            <div style={{ display: "flex", justifyContent: "center" }}>
           
              <Button variant={"contained"} onClick={handleOpen} style={{background:'#BBB8B2'}}>
                View
              </Button>
              <Modal
                keepMounted
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >


                <Fade in={open}>
                  <Box sx={style}>
                    {

                      (parsedRegistrant && registrantColumn) &&

                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={12}
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Paper sx={{ height: '70vh', width: '100%' }}>
                            <DataGrid
                              apiRef={apiRef}
                              components={{ Toolbar: GridToolbar }}
                              rows={parsedRegistrant}
                              columns={registrantColumn}
                              pageSize={15}
                              rowsPerPageOptions={[5]}
                              sx={{
                                '& .MuiDataGrid-columnHeader': {
                                  fontSize: '20px',
                                  textTransform: 'uppercase',
                                }
                              }}
                              disableRowSelectionOnClick={true}
                              disableSelectionOnClick={true}
                            />
                          </Paper>
                        </Grid>
                      </Grid>
                    }



                  </Box>
                </Fade>
              </Modal>


            </div>

            {/* Modal start  */}
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default connect()(CardLayoutRegistrantModal);
