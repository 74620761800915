import React from 'react';
import { Route, Routes } from 'react-router-dom';

// COMPONENTS
import StatsHome from '../pages/StatsHome';




const StatsHomeProteted = ({socketWc}) => {
  return (
    <Routes>
      <Route path='/' element={<StatsHome  socketWc={socketWc}/>} />

    </Routes>
  );
};

export default StatsHomeProteted;
