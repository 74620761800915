import React from "react";
import {
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  InputLabel,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";
import { connect, useSelector } from "react-redux";
import { getEventByCompanyID, setGlobalFilter } from "../../redux/action/stats";



const SearchByCompanyID = (props) => {
  const [companyID, setCompanyID] = useState('');

  const handleClick = (e) => {
    e.preventDefault()
    props.dispatch(getEventByCompanyID(companyID));
    props.dispatch(setGlobalFilter("company", companyID));


  }


  return (
    <Grid item xs={12} sm={12} md={6} lg={4} sx={{ display: 'flex', justifyContent: 'center' }}>

        <form onSubmit={handleClick}>

      <TextField
        sx={{ width: 400 }}
        label="Company ID"
        id="fullWidth"
        size="medium"
        value={companyID}
        onChange={(e) => setCompanyID(e.target.value)}
        
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton  aria-label="search by company" edge="end">
                <SearchIcon onClick={handleClick} />
              </IconButton>
            </InputAdornment>
          ),
        }}
        />
        </form>

    </Grid>
  );
};

export default connect()(SearchByCompanyID);
