import React from 'react';
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
// import { Chart } from 'react-chartjs-2';
import { Chart } from "react-google-charts";

  import { Box, Card, CardContent, Typography } from "@mui/material";





  const Brightcove_Referrer = ({brightcove_stats_referrer_domain})=>{

    console.log('brightcove_stats_referrer_domain', brightcove_stats_referrer_domain)

        const data = [
            ["referrer_domain", "video_view"],
            ...brightcove_stats_referrer_domain.map(item => [item.referrer_domain || 'Unknown', item.video_view])
          ];
          

          const options = {
            title: "Video Views by Referrer Domain",
            hAxis: {
              title: "Referrer Domain",
            },
            vAxis: {
              title: "Video Views",
            },
            legend: { position: "none" },
          };

    console.log('brightcove_stats_referrer_domain', brightcove_stats_referrer_domain.length)

    



  

    return (
  
        <Card
        sx={{
            height:'420px'
          }}
      >
        <Typography sx={{ fontSize: "200%", textAlign:'center',  background: "#2d3436" }} color="white">
              Referrer Domain
            </Typography>
        <CardContent>
          <Box sx={{ justifyContent: "center" }}>

          {/* <Pie  options={options} data={data} height='350px' width={'100%'} /> */}
          {/* <Chart chartType="ColumnChart" width="100%" height="400px" data={data} /> */}
                    {
                      brightcove_stats_referrer_domain.length >0 &&  <Chart
                            chartType="BarChart"
                            width="100%"
                            height="400px"
                            data={data}
                            options={options}
                        />
        } 

          </Box>
          
        </CardContent>
      </Card>
    )   
  }

  export default Brightcove_Referrer;