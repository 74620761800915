import React, { useCallback, useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import './style.css'
//MUI
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DataGrid, GridToolbar, GridColDef, useGridApiRef } from '@mui/x-data-grid';
import { v4 as uuidv4 } from 'uuid';
import { useDebounce } from 'use-debounce';

//Redux
import { getLiveUsersByEventID } from "../../../redux/action/stats";
import { connect, useDispatch, useSelector } from "react-redux";

//Utils
import { secondsToHour } from "../../../utils/secondsToHr";
import axios from "axios";
import { Clear, ClearAll } from "@mui/icons-material";
import { setAlert } from "../../../redux/action/alert";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "80vh",
  overflowX: 'scroll',
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  padding: "20px 20px 20px 20px",
};
const useStyles = makeStyles((theme) => ({
  hoverCard: {
    transition: "background-Color 0.5s ease",
    "&:hover": {
      backgroundColor: "#576574",
      cursor: "default",
    },
  },

}));

const CardLayoutLiveUsersScoketioCustomTable = (props) => {
  const dispatch = useDispatch()
  let { socketWc } = props
  const stats = useSelector((state) => state.stats);

  const { globalFilter, searchText, loading, event_ID, rtActiveUsers } = stats;
  const d = new Date();
  const classes = useStyles();
  const [totalItems, setTotalItems] = useState(0);


  const fetchParticipants = useCallback((page, pageSize) => {
    try {
      socketWc.emit('getLiveParticipants', { eventID: event_ID, page, pageSize });
    } catch (error) {
      console.log('ERROR getLiveParticipants', error)
    }
  }, [socketWc]);

  useEffect(() => {
    // Fetch participants for the initial page when component mounts
    fetchParticipants(1, 10);
    socketWc.on('liveParticipantsByEvent', handleParticipantsUpdate);

    // Subscribe to socket event for participants
    socketWc.on('participantLeft', handleParticipantLeft);
    socketWc.on('participantJoined', handleParticipantJoined);


    // Cleanup socket connection when component unmounts
    return () => {
      // Unsubscribe from socket events
      socketWc.off('liveParticipantsByEvent', handleParticipantsUpdate);

      socketWc.off('participantLeft', handleParticipantLeft);
      socketWc.off('participantJoined', handleParticipantJoined);


      // Add any additional cleanup operations here (e.g., disconnecting the socket)
    };
  }, []);

  // }, [socketWc, fetchParticipants]);


  const handleParticipantsUpdate = (updatedParticipants, newPage, newPageSize, totalItems) => {
    if (updatedParticipants) {
      setTotalItems(totalItems);
    }
  };

  const handleParticipantJoined = (newParticipant) => {

    setTotalItems(newParticipant.totalLive);
  };



  const handleParticipantLeft = (participant) => {
    console.log('jishan/handleParticipantLeft/', participant)

    
    setTotalItems(participant.totalLive);
  };




  return (


    <>
      <Card
        className={classes.hoverCard}
        sx={{
          width: props.cpLayout ? '200px' : 'auto',
          height: "150px",
          // backgroundColor: `${color}`,
          background: `linear-gradient(to right bottom, ${props.color1}, ${props.color2})`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: '20px',
        }}
      >
        <CardContent>
          <Box sx={{ justifyContent: "center" }}>
            {/* Active Users have chlid component for RT  */}

            <Typography sx={{ fontSize: "1rem" }} color="white">
              {props.title}
            </Typography>

            {!props.live && <Typography sx={{ textAlign: 'center', fontSize: '0.8rem' }} color="white">
              (On Demand)
            </Typography>}
            <div style={{ display: "flex", justifyContent: "center" }}>
              {props.icon}
            </div>
            <Typography
              sx={{ color: "white", fontSize: "1ren", textAlign: "center" }}
            >
              {/* {props.timeFormat ? secondsToHour(props.numbers) : props.numbers} */}
              {props.timeFormat ? secondsToHour(totalItems) : (props.eventStatus !== 'stopped'? totalItems : '-')}

            </Typography>
            {props.children}

          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default connect()(CardLayoutLiveUsersScoketioCustomTable);
