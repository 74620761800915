import React, { useEffect, useRef, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Auth from './components/Auth';
import StatsHome from './pages/StatsHome'
import './index.css'

import { Provider } from 'react-redux';
import store from './redux/store/store';
import PrivateRoute from './routes/PrivateRoute';
import StatsHomeProteted from './routes';
import StatsForClient from './components/StatsForClient';
import StatsForCP from './components/StatsForCP';
import Header from './components/ui/Header';
import io from 'socket.io-client';
import UserActivity from './pages/UserActivity';

document.body.style.backgroundColor = '#f9f9f9'


const App = ()=>{
  const [socketWc, setSocketWc] = useState(null);
  const socketRef = useRef(null);

  const [socketConnected, setSocketConnected] = useState(false);

  // const [socketWc, setSocketWc] = useState(null);
  // const socket_wc = io('http://localhost:1000/webcasting'); // Update with your server URL

  useEffect(() => {
    if (!socketRef.current?.connected) {
      const socket = io(`${process.env.REACT_APP_SOCKET_IO_URL}/webcasting`);      
      socketRef.current = socket;
      setSocketConnected(true);
      setSocketWc(socket)
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        setSocketConnected(false);
      }
    };
  }, []); 

  useEffect(() => {
    console.log('Socket Connected:', socketRef);
  }, [socketConnected]);





  return (
    
    <Provider store={store}>
      
    <Routes>

           {/* <Route exact path='/' element={<Auth/>}/> */}
          <Route exact path='/' element={<Auth/>} />
          
          <Route
            path='/statshome'
            element={<PrivateRoute route={'/statshome'} socketWc={socketWc} component={StatsHomeProteted} />}
          />
{/* <Route
            path='/statshome/userActivity'
            element={<PrivateRoute route={'/statshome/userActivity'} socketWc={socketWc} component={UserActivity} />}
          /> */}
 

        <Route exact path='/clientLink/:clientToken'  element={<StatsForClient socketWc={socketWc}/>} />
        
        <Route exact path='/clientPortal/:clientToken' element={<StatsForCP/>} />

    </Routes>
    </Provider>
  
  )
}

export default App;