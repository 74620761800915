


 export const formateDate = (dateToBeChecked, startDate, endDate)=>{
  // console.log('formateDate' )
  
    let cleaned = dateToBeChecked.replace(',', '')
    let cleanedArray = cleaned.split(' ')
    let newCleanedArray = cleanedArray[2] + ',' + cleanedArray[0] + ',' + cleanedArray[1].slice(0, 2) + ',' + cleanedArray[3];
    
    const loggedIn = new Date(newCleanedArray).getTime();


    //  console.log('new new',(new Date(newCleanedArray).getTime() >= new Date(startDate).getTime()) && (new Date(newCleanedArray).getTime() <= new Date(endDate).getTime()))
    return  ((loggedIn > startDate) && (loggedIn < endDate))
   
    
  }

  export const getValidDate = (dateToBeChecked)=>{
    // console.log('valid date' )
    
      let cleaned = dateToBeChecked.replace(',', '')
      let cleanedArray = cleaned.split(' ')
      let cleanedArrayOnlyDigits = cleanedArray[1].replace(/\D/g,'')

      let newCleanedArray = cleanedArray[2] + ',' + cleanedArray[0] + ',' + cleanedArrayOnlyDigits + ',' + cleanedArray[3];
      
      const loggedIn = new Date(newCleanedArray).getTime();
      return loggedIn
     
      
    }
  
  