import {
  Accordion,
  Box,
  Grid,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Paper,
} from "@mui/material";
import { Backdrop, Card, CardContent, Fade, Modal } from "@mui/material";
import { CopyToClipboard } from 'react-copy-to-clipboard';

import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import axios from "axios";

// import SearchByCompany from "./searchInputs/SearchByCompany";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import SearchByCompanyID from "./searchInputs/SearchByCompanyID";
import AutoCompleteCompanyAndEvent from "./searchInputs/AutocompleteSearch";
import DatesInput from "./searchInputs/DatesInput";
import SearchButton from "./searchInputs/SearchButton";
import Spinner from "./ui/Spinner";
import { useState } from "react";
import { CheckBox } from "@mui/icons-material";
import ReplayIcon from '@mui/icons-material/Replay';
import { getEventByCompanyID, getEventByKeyStroke, getLiveUsersByEventID, getRegistrantDetails, getStatByID } from "../redux/action/stats";
const sign = require("jwt-encode");
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "calc(100% - 200px)",
  // height: "calc(100vh - 50vh)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  // overflowY: "scroll",
  p: 4,
};

const ClientLinkC = (props) => {
  const stats = useSelector((state) => state.stats);

  const { globalFilter, searchText, loading, company_id, event_ID, eventNoReg } = stats;
  const eventType = useSelector((state) => state.stats.eventType);


  //Modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
    SetClientLink("");
    setShortenedLink("")
    setCopied(false);
    setState({
      usersNow: false,
      totalRegistrant: false,
      interactions: false,
      liveUsers: false,
      donDemandUsers: false,
      totalUsers: false,
      averageTime: false,
      geoData: false,
      platform:false,
      graph:false,
      overview:false,
      noRegTotalviews:false

    })
  }


  const handleClose = () => setOpen(false);
  const [clientLink, SetClientLink] = useState("");
  //Modal

  // static data link generation individual states

  const [state, setState] = useState({
    usersNow: false,
    totalRegistrant: false,
    bcViews:false,
    bcReferrer:false,
    interactions: false,
    liveUsers: false,
    donDemandUsers: false,
    totalUsers: false,
    averageTime: false,
    geoData: false,
    platform:false,
    graph:false,
    overview:false,
    noRegTotalviews:false,
    //Stats revist
    preRegWatchedLive:false,
    preRegWatchedOnDemand:false,
    didNotWatched:false,
    prePostRegGrpah:false,
    geoCity:false,
    viewsDistribution:false,
    seperateOnDemandGraph:false,
    NoRegActivityOverView:false

  });

  // static data link generation individual states


  //copy state
  const [copied, setCopied] = useState(false)
  const [shortenedLink, setShortenedLink] = useState("")

  const handleStaticLinkCreation = () => {
    // console.log(stats);

    const staticData = {
      tokenValid: true,
      eventID: stats.event_ID,
      eventName: stats.searchText,
      usersNow: usersNow,
      totalRegistrant: totalRegistrant,
      interactions: interactions,
      liveUsers: liveUsers,
      donDemandUsers: donDemandUsers,
      totalUsers: totalUsers,
      averageTime: averageTime,
      geoData: geoData,
      platform:platform,
      graph:graph,
      bcViews:bcViews,
      bcReferrer:bcReferrer,
      overview:overview,
      noRegTotalviews:noRegTotalviews,
      //stats revisit
      preRegWatchedLive:preRegWatchedLive,
      preRegWatchedOnDemand:preRegWatchedOnDemand,
      didNotWatched:didNotWatched,
      prePostRegGrpah:prePostRegGrpah,
      geoCity:geoCity,
      viewsDistribution:viewsDistribution,
      seperateOnDemandGraph:seperateOnDemandGraph,
      NoRegActivityOverView:NoRegActivityOverView

    };

    // console.log(staticData);
    const secret = "secret";

    const jwt = sign(staticData, secret);
    console.log(jwt);
    SetClientLink(jwt); 

    shortenJwtlinlk(`https://${window.location.hostname}/clientLink/${jwt}`)
  };


  const shortenJwtlinlk = async (jwt)=>{
    try {
        const response = await axios.post(
          `${process.env.REACT_APP_STATS_API_URL}/event/generateShortLink/?token=${process.env.REACT_APP_TOKEN}`,{
            originalUrl:jwt
          }
        );


        setShortenedLink(response.data.shortLink);
        console.log('Short Link', shortenedLink)
      } catch (e) {
        console.log(e);
      }
    }
  

  const handleReload = () => {
    if (globalFilter === 'company') {
      props.dispatch(getEventByCompanyID(company_id))

    } else if (globalFilter === 'event') {
      props.dispatch(getEventByKeyStroke(event_ID));
      props.dispatch(getStatByID(event_ID));
      props.dispatch(getLiveUsersByEventID(event_ID));
      // props.dispatch(getRegistrantDetails(event_ID));
    }
  }

  const {
    usersNow,
    totalRegistrant,
    interactions,
    liveUsers,
    donDemandUsers,
    totalUsers,
    averageTime,
    geoData,
    platform,
    graph,
    bcViews,
    bcReferrer,
    overview,
    noRegTotalviews,
    preRegWatchedLive,
    preRegWatchedOnDemand,
    didNotWatched,
    prePostRegGrpah,
    geoCity,
    viewsDistribution,
    seperateOnDemandGraph,
    NoRegActivityOverView

  } = state;
  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };
  return (
    <>
      {globalFilter === "event" && (
        <Button onClick={handleOpen}

          sx={{
            m:'0.5rem',
            color: "white",
            background:'rgba(92, 133, 37, 0.9)',
            fontSize: {
              xs: "0.7rem",
              sm: "0.8rem",
              md: "1rem",
              lg: "1rem",
            },
            display: {
              xs: 'none',
              md: 'block',
              lg: 'block'
            }
          }}
          variant="contained">
          Generate client Link
        </Button>
      )}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>

{
  eventType === 'bc' ? 


  <FormControl
              sx={{ m: 1, display: "flex" }}
              component="fieldset"
              variant="standard"
            >
              <FormLabel component="legend" sx={{ fontSize: { xs: '0.7rem', sm: '0.8rem', md: '0.9rem' } }}>
                Check which data you want to show and uncheck to hide
              </FormLabel>
              <FormGroup>
                <Grid >
        

           
                  <FormControlLabel
                    label={<Typography sx={{ fontSize: { xs: '0.7rem', sm: '0.8rem', md: '0.9rem' } }}>
                      Total Views
                    </Typography>}
                    control={
                      <Checkbox
                        checked={bcViews}
                        onChange={handleChange}
                        name="bcViews"
                      />
                    }
                  />
     
                  <FormControlLabel
                    label={<Typography sx={{ fontSize: { xs: '0.7rem', sm: '0.8rem', md: '0.9rem' } }}>
                      Geo Data
                    </Typography>}
                    control={
                      <Checkbox
                        checked={geoData}
                        onChange={handleChange}
                        name="geoData"
                      />
                    }
                  />



            <FormControlLabel
                    label={<Typography sx={{ fontSize: { xs: '0.7rem', sm: '0.8rem', md: '0.9rem' } }}>
                      Platform
                    </Typography>}
                    control={
                      <Checkbox
                        checked={platform}
                        onChange={handleChange}
                        name="platform"
                      />
                    }
                  />


<FormControlLabel
                    label={<Typography sx={{ fontSize: { xs: '0.7rem', sm: '0.8rem', md: '0.9rem' } }}>
                      Referrer domain
                    </Typography>}
                    control={
                      <Checkbox
                        checked={bcReferrer}
                        onChange={handleChange}
                        name="bcReferrer"
                      />
                    }
                  />


                </Grid>
              </FormGroup>
            </FormControl>

  :   <FormControl
  sx={{ m: 1, display: "flex" }}
  component="fieldset"
  variant="standard"
>
  <FormLabel component="legend" sx={{ fontSize: { xs: '0.7rem', sm: '0.8rem', md: '0.9rem' } }}>
    Check which data you want to show and uncheck to hide
  </FormLabel>
  <FormGroup>
    <Grid >
      <FormControlLabel
        label={<Typography sx={{ fontSize: { xs: '0.7rem', sm: '0.8rem', md: '0.9rem' } }}>
          User now
        </Typography>}
        control={
          <Checkbox
            checked={usersNow}
            onChange={handleChange}
            name="usersNow"
          />
        }
      />
      
      {  !eventNoReg &&
        <>
       <FormControlLabel
        label={<Typography sx={{ fontSize: { xs: '0.7rem', sm: '0.8rem', md: '0.9rem' } }}>
          User Interactions
        </Typography>} control={
          <Checkbox
            checked={interactions}
            onChange={handleChange}
            name="interactions"
          />
        }
      />


    {/* <FormControlLabel
        label={<Typography sx={{ fontSize: { xs: '0.7rem', sm: '0.8rem', md: '0.9rem' } }}>
         Overview
        </Typography>} control={
          <Checkbox
            checked={overview}
            onChange={handleChange}
            name="overview"
          />
        }
      /> */}
</>
}

  { eventNoReg ?  
   <FormControlLabel
   label={<Typography sx={{ fontSize: { xs: '0.7rem', sm: '0.8rem', md: '0.9rem' } }}>
     Total Views
   </Typography>}
   control={
     <Checkbox
       checked={noRegTotalviews}
       onChange={handleChange}
       name="noRegTotalviews"
     />
   }
  />
  
  :
 <FormControlLabel
 label={<Typography sx={{ fontSize: { xs: '0.7rem', sm: '0.8rem', md: '0.9rem' } }}>
   Total Registrants
 </Typography>}
 control={
   <Checkbox
     checked={totalRegistrant}
     onChange={handleChange}
     name="totalRegistrant"
   />
 }
/>
  }
     


      <FormControlLabel
        label={<Typography sx={{ fontSize: { xs: '0.7rem', sm: '0.8rem', md: '0.9rem' } }}>
          Total live users
        </Typography>} control={
          <Checkbox
            checked={liveUsers}
            onChange={handleChange}
            name="liveUsers"
          />
        }
      />

<FormControlLabel
        label={<Typography sx={{ fontSize: { xs: '0.7rem', sm: '0.8rem', md: '0.9rem' } }}>
          Total Pre Reg live users
        </Typography>} control={
          <Checkbox
            checked={preRegWatchedLive}
            onChange={handleChange}
            name="preRegWatchedLive"
          />
        }
      />


      <FormControlLabel
        label={<Typography sx={{ fontSize: { xs: '0.7rem', sm: '0.8rem', md: '0.9rem' } }}>
          Total On Demand users
        </Typography>}
        control={
          <Checkbox
            checked={donDemandUsers}
            onChange={handleChange}
            name="donDemandUsers"
          />
        }
      />

<FormControlLabel
        label={<Typography sx={{ fontSize: { xs: '0.7rem', sm: '0.8rem', md: '0.9rem' } }}>
          Total Pre Reg On Demand users
        </Typography>} control={
          <Checkbox
            checked={preRegWatchedOnDemand}
            onChange={handleChange}
            name="preRegWatchedOnDemand"
          />
        }
      />

<FormControlLabel
        label={<Typography sx={{ fontSize: { xs: '0.7rem', sm: '0.8rem', md: '0.9rem' } }}>
         Did not watched
        </Typography>} control={
          <Checkbox
            checked={didNotWatched}
            onChange={handleChange}
            name="didNotWatched"
          />
        }
      />


      <FormControlLabel
        label={<Typography sx={{ fontSize: { xs: '0.7rem', sm: '0.8rem', md: '0.9rem' } }}>
          Total users
        </Typography>}
        control={
          <Checkbox
            checked={totalUsers}
            onChange={handleChange}
            name="totalUsers"
          />
        }
      />
      <FormControlLabel
        label={<Typography sx={{ fontSize: { xs: '0.7rem', sm: '0.8rem', md: '0.9rem' } }}>
          Average time
        </Typography>}
        control={
          <Checkbox
            checked={averageTime}
            onChange={handleChange}
            name="averageTime"
          />
        }
      />
      <FormControlLabel
        label={<Typography sx={{ fontSize: { xs: '0.7rem', sm: '0.8rem', md: '0.9rem' } }}>
          Geo Data
        </Typography>}
        control={
          <Checkbox
            checked={geoData}
            onChange={handleChange}
            name="geoData"
          />
        }
      />

<FormControlLabel
        label={<Typography sx={{ fontSize: { xs: '0.7rem', sm: '0.8rem', md: '0.9rem' } }}>
          Geo Data By City
        </Typography>}
        control={
          <Checkbox
            checked={geoCity}
            onChange={handleChange}
            name="geoCity"
          />
        }
      />



<FormControlLabel
        label={<Typography sx={{ fontSize: { xs: '0.7rem', sm: '0.8rem', md: '0.9rem' } }}>
          Platform
        </Typography>}
        control={
          <Checkbox
            checked={platform}
            onChange={handleChange}
            name="platform"
          />
        }
      />

<FormControlLabel
        label={<Typography sx={{ fontSize: { xs: '0.7rem', sm: '0.8rem', md: '0.9rem' } }}>
         Overall views distribution
        </Typography>}
        control={
          <Checkbox
            checked={viewsDistribution}
            onChange={handleChange}
            name="viewsDistribution"
          />
        }
      />

<FormControlLabel
        label={<Typography sx={{ fontSize: { xs: '0.7rem', sm: '0.8rem', md: '0.9rem' } }}>
          Graph
        </Typography>}
        control={
          <Checkbox
            checked={graph}
            onChange={handleChange}
            name="graph"
          />
        }
      />

      { !eventNoReg && 
<FormControlLabel
        label={<Typography sx={{ fontSize: { xs: '0.7rem', sm: '0.8rem', md: '0.9rem' } }}>
          Graph - Registrants (Pre and Post Event)
        </Typography>}
        control={
          <Checkbox
            checked={prePostRegGrpah}
            onChange={handleChange}
            name="prePostRegGrpah"
          />
        }
      />

      }

{ eventNoReg && 
<FormControlLabel
        label={<Typography sx={{ fontSize: { xs: '0.7rem', sm: '0.8rem', md: '0.9rem' } }}>
          Activity Overview (slider count, note accessed count, no of issues count, info tab clicked count)
        </Typography>}
        control={
          <Checkbox
            checked={NoRegActivityOverView}
            onChange={handleChange}
            name="NoRegActivityOverView"
          />
        }
      />

      }

<FormControlLabel
        label={<Typography sx={{ fontSize: { xs: '0.7rem', sm: '0.8rem', md: '0.9rem' } }}>
          Graph - On Demand Users
        </Typography>}
        control={
          <Checkbox
            checked={seperateOnDemandGraph}
            onChange={handleChange}
            name="seperateOnDemandGraph"
          />
        }
      />


    </Grid>
  </FormGroup>
</FormControl>
}


          



            <Button onClick={handleStaticLinkCreation} variant="contained" sx={{m:'0.5rem'}}>
              Generate Link
            </Button>

            {shortenedLink && (
              <Paper sx={{ wordWrap: 'break-word', p: '5px', m: 2 }}>
                <Typography>
                  {/* https://{window.location.hostname}/clientLink/{clientLink} */}
                  {/* http://localhost:3000/clientLink/{clientLink} */}
                  {shortenedLink}

                </Typography>

                {/* <CopyToClipboard text={`http://${window.location.hostname}/clientLink/${clientLink}`} */}
                <CopyToClipboard text={shortenedLink}

                  onCopy={() => setCopied(true)}>
                  <Button variant="outlined">Copy to clipboard</Button>
                </CopyToClipboard>

                {copied ? (<span style={{ color: 'red' }}>Copied</span>) : ''}

              </Paper>
            )}
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default connect()(ClientLinkC);
