import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
  import { Box, Card, CardContent, IconButton, Tooltip as TooltipMUI,Typography } from "@mui/material";
import { Info } from '@mui/icons-material';


  ChartJS.register(ArcElement, Tooltip, Legend);




  const DeviceChartLayout = (props)=>{

      // console.log(props)
    // let datesFromStats = props.allStats.map((each)=> each.dateIso);
    let deviceInformation = props.deviceData;
    let deviceName = props.deviceData.map((eachData)=>  eachData.deviceName)
    let devicePercentage = props.deviceData.map((eachData)=>  eachData.percentage)

    console.log('devicePercentage', deviceInformation)

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Different platform used by users',
        },
      },
    };

    
    const data = {
        labels: deviceName,
        datasets: [
          {
            label: '% of users',
            data:devicePercentage,
            backgroundColor: [
              "#2C3365", "#4A5596", "#6978C9", "#97A1D9"
            ],
            // borderColor: [
            //   'rgba(255, 99, 132, 1)',
            //   'rgba(54, 162, 235, 1)',
            //   'rgba(255, 206, 86, 1)',
            //   'rgba(75, 192, 192, 1)',
            //   'rgba(153, 102, 255, 1)',
            //   'rgba(255, 159, 64, 1)',
            // ],

            borderColor: [
                'white'
              ],
            borderWidth: 1,
          },
        ],
      };
    return (

          <Box sx={{ justifyContent: "center" }}>

          <Pie  options={options} data={data} height='400px' width={'100%'} />

          </Box>
          
      
    )   
  }

  export default DeviceChartLayout;