import React from "react";

import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";


import { secondsToHour } from "../../utils/secondsToHr";
import { Analytics } from "@mui/icons-material";
const useStyles = makeStyles((theme) => ({
  hoverCard: {
    transition: "background-Color 0.5s ease",
    "&:hover": {
      opacity:0.8,
      cursor: "default",
    },
  },

}));

const NoDataCardLayout = ({
  title,
  numbers,
  color1,
  color2,
  timeFormat,
  icon,
  children,
  details,
  cpLayout
}) => {
  const classes = useStyles();

  return (
    <>
      <Card
        className={classes.hoverCard}
        sx={{
          width:cpLayout ? '300px' : 'auto',
          height: "auto",
          backgroundColor: `${color2}`,
          

        }}
      >

        
        <CardContent>
          <Box sx={{ justifyContent: "center" }}>

            {/* <Typography sx={{ fontSize: "200%", textAlign:'center' }} color="white">
              {title}
            </Typography>
         */}
    

<Typography  color="black" textAlign={'center'} sx={{fontSize:cpLayout? '1.2rem' :'1rem'}}>
              {title}
            </Typography>

 
            <div style={{ display: "flex", justifyContent: "center" }}>
              
              <Analytics  fontSize="large"
              sx={{ color: "black" }}/>
                
  </div>
 
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default NoDataCardLayout;
