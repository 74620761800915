import React from "react";

import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";


import { secondsToHour } from "../../utils/secondsToHr";
const useStyles = makeStyles((theme) => ({
  hoverCard: {
    transition: "background-Color 0.5s ease",
    "&:hover": {
      opacity:0.8,
      cursor: "default",
    },
  },

}));

const CardLayoutLive = ({
  title,
  numbers,
  color1,
  color2,
  timeFormat,
  icon,
  children,
  details,
  cpLayout
}) => {
  const classes = useStyles();

  return (
    <>
      <Card
        className={classes.hoverCard}
        sx={{
          width:cpLayout ? '200px' : 'auto',
          height: "150px",
          backgroundColor: `${color2}`,
          background: `linear-gradient(to right bottom, ${color1}, ${color2})`,
        }}
      >
                <Typography sx={{ fontSize: "150%", textAlign:'center', padding:'5px' }} color="white">
         {title}
        </Typography>
        
        <CardContent>
          <Box sx={{ justifyContent: "center" }}>

            {/* <Typography sx={{ fontSize: "200%", textAlign:'center' }} color="white">
              {title}
            </Typography>
         */}
    
            <div style={{ display: "flex", justifyContent: "center" }}>
              {icon}
              
</div>
<Typography  color="white" textAlign={'center'}>
              {details}
            </Typography>

            <Typography
              sx={{ color: "white", fontSize: "350%", textAlign: "center" }}
            >
                {timeFormat ? secondsToHour(numbers) : numbers} 
            </Typography>
            {children}
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default CardLayoutLive;
