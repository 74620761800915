import { Backdrop, Box, Fade, Grid, Paper } from "@mui/material";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { DataGridProps } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import moment from "moment/moment";
import { DataGrid, GridToolbar, GridColDef, useGridApiRef } from '@mui/x-data-grid';
import { v4 as uuidv4 } from 'uuid';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    // height: "90vh",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
const RegModal = ({data})=>{
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    // console.log(data)
  
    const [parsedRegistrant, setParsedRegistrant] = useState([]);
    const [registrantColumn, setRegistrantColumn] = useState([]);



    useEffect(()=>{
      console.log('regmodaldata', data)
        if (data.length>0) {
            let p = data.map((eachReg) => {
              let parsed = JSON.parse(eachReg.fields);

                  return {
                    id: uuidv4(),
                    ...parsed,
                    registration: moment(eachReg.date).format('LLL'),
                    // timeSpent: eachReg.timeSpent ? secondsToHour(eachReg.timeSpent) : 'N/A',
                    // Live: eachReg.watchedLive === true ? 'Yes' : 'No',
                    // OnDemand: eachReg.watchedOnDemand === true ? 'Yes' : 'No',
                    region:eachReg.geo_location?.region ? eachReg.geo_location.region : 'n/a',
                    city:eachReg.geo_location?.city ? eachReg.geo_location.city : 'n/a',
                    accessed: eachReg.loggedInTime === '0' ?  'n/a' :eachReg.loggedInTime,
                    
                  };
                  
                
      
      
      
            });
            setParsedRegistrant(p);
            console.log('setParsedRegistrant', p)
            setRegistrantColumn(Object.keys(p[0]))
      
            let parsedColumn = Object.keys(p[0]).map((eachCo) => {
              return {
      
                field: eachCo,
                headerName: eachCo,
                align: "left",
                width: 240,
      
              }
            })
      
            parsedColumn.shift()
            setRegistrantColumn(parsedColumn)
          }
    },[data])

    console.log('dataRegModal', data)
    const apiRef = useGridApiRef();

        return (

            <div style={{ display: "flex", justifyContent: "center" }}>
           
            <Button variant={"contained"} onClick={handleOpen} style={{background:'#BBB8B2'}}>
              View
            </Button>
            <Modal
              keepMounted
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >


              <Fade in={open}>
                <Box sx={style}>
                  {

                    (parsedRegistrant && registrantColumn) &&

                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={12}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Paper sx={{ height: '70vh', width: '100%' }}>
                          <DataGrid
                            apiRef={apiRef}
                            components={{ Toolbar: GridToolbar }}
                            rows={parsedRegistrant}
                            columns={registrantColumn}
                            pageSize={15}
                            rowsPerPageOptions={[5]}
                            sx={{
                              '& .MuiDataGrid-columnHeader': {
                                fontSize: '20px',
                                textTransform: 'uppercase',
                              }
                            }}
                            disableRowSelectionOnClick={true}
                            disableSelectionOnClick={true}
                          />
                        </Paper>
                      </Grid>
                    </Grid>
                  }



                </Box>
              </Fade>
            </Modal>


          </div>
        )
}


export default RegModal