export const SET_LOADING = 'SET_LOADING';
//Do not use (Only use for exceptional case)
export const SET_LOADING_FALSE = 'SET_LOADING_FALSE';


export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REMOVE_ALL_ALERT = 'REMOVE_ALL_ALERT';

export const SET_ACTIVE_ICON = 'SET_ACTIVE_ICON';
export const REMOVE_ACTIVE_ICON = 'REMOVE_ACTIVE_ICON';
  //Graph data

export const SET_LOADING_GRAPH='SET_LOADING_GRAPH';
export const SET_LOADING_GRAPH_FALSE='SET_LOADING_GRAPH_FALSE'