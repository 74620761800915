import { useEffect, useState } from 'react';
import useDialog from './useModal';
import { Button } from 'rsuite';

function useDynamicColumnsOndemand(data) {
  const [columns, setColumns] = useState([]);
  const dialog = useDialog();
  const [dialogContent, setDialogContent] = useState('');
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedUserEmail, setSelectedUserEmail] = useState('');

  useEffect(() => {
    if (data.length > 0) {
      // Collect all unique field names from the data
      const allFields = data.reduce((fields, user) => {
        const userFields = JSON.parse(user.fields);
        Object.keys(userFields).forEach((field) => {
          if (!fields.includes(field)) {
            fields.push(field);
          }
        });
        return fields;
      }, []);

      // Generate columns based on the unique field names
      const dynamicColumns = allFields.map((field) => ({
        field,
        headerName: field,
        flex: 1,
        valueGetter: (params) => {
          const userFields = JSON.parse(params.row.fields);
          return userFields[field];
        },
      }));



      // Add columns for the static properties like _id, eventid, and so on
      dynamicColumns.push(
        { field: 'loggedInTime', headerName: 'Logged In Time', flex: 1 },
        // { field: 'watchedLive', headerName: 'Watched Live', flex: 1 },
        // { field: 'watchedOnDemand', headerName: 'Watched OnDemand', flex: 1 },

        // Add more static columns as needed
      );

      dynamicColumns.push({
        field: 'watchedLive',
        headerName: 'Watched Live',
        flex: 1,
        valueGetter: (params) => (params.row.watchedLive ? 'Yes' : 'No'),
      });
    
      dynamicColumns.push({
        field: 'watchedOnDemand',
        headerName: 'Watched On Demand',
        flex: 1,
        valueGetter: (params) => (params.row.watchedOnDemand ? 'Yes' : 'No'),
      });


      dynamicColumns.push({
        field: 'view',
        headerName: 'View',
        flex: 1,
        renderCell: (params) => (
            <Button

            onClick={() => {
              setDialogContent(JSON.stringify(params.row.onDemandVisits, null, 2));
              setSelectedUser(params.row.findId);
              setSelectedUserEmail(JSON.parse(params.row.fields).email)


              dialog.handleOpen();
            }}
            style={{ background: 'rgb(14, 149, 148, 1)', color: 'white' }}
          >
            View
          </Button>

        ),
      });

      setColumns(dynamicColumns);
    }
  }, [data]);

  return { columns: columns, dialog: { ...dialog, content: dialogContent,  userID:selectedUser, email:selectedUserEmail  } };
}

export default useDynamicColumnsOndemand;
