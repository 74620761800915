import React, { createContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLiveUsersByEventID_SocketIO } from "../../redux/action/stats";
import { setAlert } from "../../redux/action/alert";


const SocketContainer = createContext(null);

export { SocketContainer };

export default ({ children, socketWc, event_ID}) => {
    
    const dispatch = useDispatch()
    const eventWithNoReq = useSelector((state) => state.stats.eventNoReg);

    const [socketioMethods, setSocketioMethods] = useState({});

    useEffect(() => {
        console.log('Whats in socket',socketWc)

        if (socketWc.connected) {
            socketWc.on('connection', (data) => {
                console.log('connected', data)
            });

            socketWc.on('connect_error', (error) => {
                console.error('Socket.io connection error:', error);
            });

            socketWc.on("disconnect", (reason) => {
                console.log('Socket.io connection error Reason ---->>>>>>>:', reason);

            });

        }
        return () => {
            socketWc.emit('leaveEventForStats', event_ID);
            try {
                // socket.disconnect();
                console.log("Disconneting from socket.io");

            } catch (error) {

                console.error('Error disconnecting socket:', error);
            }
        }

    }, []);


    useEffect(() => {
        // if (socketWc.connected) {


            try {

                
                socketWc.on('participantJoined', (data) => {

                if(eventWithNoReq) {
                    // dispatch(setAlert(`Participant Joined`, "success", 1000, "7rem"));

                } else {
                    console.log(`ParticipantParticipant (${data}) Joined`)
                    // dispatch(setAlert(`Participant (${data.email}) Joined`, "success", 1000, "7rem"));

                }

                })
                socketWc.emit('joinEventForStats',event_ID);

                // socketWc.on('liveParticipantsUpdateByEvent', (data) => {
                //     console.log('From liveUsers', data)
                // })

                // socketWc.on('liveParticipantsByEvent', (data) => {
                //     // dispatch(getLiveUsersByEventID_SocketIO(data))
                //     console.log('Live user updates', data)
                // })
            } catch (error) {

                console.error('Socket.io connection error:', error);

            }

        // }

        return () => {
            // socketWc.off('message')
            // socket.disconnect()

        }
    }, [socketWc])




    return (
        <SocketContainer.Provider value={socketioMethods}>
            {children}
        </SocketContainer.Provider>
    );
};
