import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// REDUX
import { removeAlert } from '../../redux/action/alert';

// MUI
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const AlertCard = ({ alerts, removeAlert }) => {
  const [open, setOpen] = useState(false);
  const [alertId, setAlertId] = useState('');

  useEffect(() => {
    if (alerts !== null && alerts.length > 0) {
      setOpen(true);
      setAlertId(alerts[0].id);
    }
  }, [alerts]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;

    removeAlert(alertId);
    setOpen(false);
  };

  return (
    alerts !== null &&
    alerts.length > 0 &&
    alerts.map((alert) => (
      <Snackbar
        key={alert.id}
        open={open}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          marginTop: `${
            alert.marginTop !== undefined ? alert.marginTop : '3.5rem'
          }`,
        }}
      >
        <Alert
          variant='filled'
          severity={alert.alertType}
          sx={{ alignItems: 'center' }}
        >
          {alert.msg}
          <IconButton
            size='small'
            aria-label='close'
            color='inherit'
            onClick={handleClose}
            sx={{ marginLeft: 3 }}
          >
            <CloseIcon fontSize='small' />
          </IconButton>
        </Alert>
      </Snackbar>
    ))
  );
};

AlertCard.propTypes = {
  alerts: PropTypes.array.isRequired,
  removeAlert: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  alerts: state.alert,
});

export default connect(mapStateToProps, { removeAlert })(AlertCard);
