import { useEffect, useState } from 'react';

function useUserLists(users) {
  const [liveUsers, setLiveUsers] = useState([]);
  const [onDemandUsers, setOnDemandUsers] = useState([]);

  useEffect(() => {
    // Filter the users into "live" and "onDemand" lists
    const liveUsersList = users.filter((user) => user.watchedLive);
    const onDemandUsersList = users.filter((user) => user.watchedOnDemand);

    setLiveUsers(liveUsersList);
    setOnDemandUsers(onDemandUsersList);
  }, [users]);

  return { liveUsers, onDemandUsers };
}

export default useUserLists;
