

export const StatsConstant = {
  GET_ALL_STATS_BY_EVENTID: 'GET_ALL_STATS_BY_EVENTID',
  GET_ALL_STATS_BY_EVENTID_NO_REG: 'GET_ALL_STATS_BY_EVENTID_NO_REG',


  SET_GEO_DATA: 'SET_GEO_DATA',
  GET_RT_STATS_BY_EVENTID: 'GET_RT_STATS_BY_EVENTID',
  GET_RT_STATS_BY_EVENTID_NO_REG: 'GET_RT_STATS_BY_EVENTID_NO_REG',


  //Get events based on company ID
  GET_EVENT_BASED_ON_COMPANY_ID: 'GET_EVENT_BASED_ON_COMPANY_ID',

  // Get company on keystroke
  GET_COMPANY_KEYSTROKE: 'GET_COMPANY_KEYSTROKE',
  // Get event on keystroke
  GET_EVENT_KEYSTROKE: 'GET_EVENT_KEYSTROKE',

  //ERROR
  SET_ERROR: 'SET_ERROR',


  //Global filter
  SET_GLOBAL_FILTER: 'SET_GLOBAL_FILTER',

  //Reset Stats
  RESET_FILTER: 'RESET_FILTER',


  //loadimg
  SET_LOADING: 'SET_LOADING',
  //Do not use (Only use for exceptional case)
  SET_LOADING_FALSE: 'SET_LOADING_FALSE',

  //Registarnt detail 

  GET_REGISTRANT_DETAILS: 'GET_REGISTRANT_DETAILS',
  SET_REGISTRANT: 'SET_REGISTRANT',


  //Set company id and name in title

  SET_COMPANY_ID_NAME: 'SET_COMPANY_ID_NAME',


  SET_EVENT_TYPE:'SET_EVENT_TYPE',
  GET_ALL_STATS_BY_EVENTID_BRIGHTCOVE:'GET_ALL_STATS_BY_EVENTID_BRIGHTCOVE',

  SET_USER_ACTIVITY:'SET_USER_ACTIVITY',


  GET_USER_LOCATION_NO_REG:'GET_USER_LOCATION_NO_REG',


  //Get reg count
  GET_REG_COUNT_BY_EVENT:'GET_REG_COUNT_BY_EVENT'


}


