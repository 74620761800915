import { Box, Button, Grid, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import Header from "../components/ui/Header";
import styled from "@emotion/styled";
import { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { DataGrid } from "@mui/x-data-grid";
import { v4 as uuidv4 } from 'uuid';
import moment from "moment";
import Liveusers from "../components/activity/Liveusers";
import OnDemandUsers from "../components/activity/OnDemandUsers";
import { useDispatch, useSelector } from "react-redux";
import { setUserActivity } from "../redux/action/stats";
import { Close } from "@mui/icons-material";

// import { Button } from "rsuite";



const LiveButton = styled(Button)(({ theme,bgColor, fontColor }) => ({
        background:bgColor,
        color:fontColor,
        "&:hover": {
                background:bgColor
        },

}))

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    tableCell: {
      fontWeight: 'bold',
      fontSize: '16px',
      padding: '12px',
      color:'white'
    },
    tableRow: {
      '&:nth-of-type(odd)': {
        backgroundColor: '#f2f2f2',
      },
    },
    tableHeader: {
      backgroundColor: 'rgb(45, 52, 54)',
      color: 'white',
    },
  });

const UserActivity = ({socketWc, handleClose})=>{
    const classes = useStyles();

    const eventID = useSelector((state)=> state.stats.event_ID)
    const storesUserActivity = useSelector((state)=> state.stats.userActivityByEvent)

    const dispatch = useDispatch()

            //Active tab 
       const [activeTab, setActiveTab] = useState('live')
       const [allActivity, setAllActivity] = useState([])
       const [rows, setRows] = useState([]);

    useEffect(()=>{
        dispatch(setUserActivity(eventID))
        setAllActivity(storesUserActivity)
    },[])

    useEffect(()=>{
        setAllActivity(storesUserActivity)

    },[storesUserActivity])
       useEffect(() => {
        // Update the rows whenever the data prop changes
        setRows([...allActivity].reverse().map((item) => ({
            id: uuidv4(),
            userID:item.userID,
          action: item.action,
          type: item.type,
          Time:  moment(item.time).format('LLL') ,
        })));
      }, [allActivity]);

      console.log('storesUserActivity', storesUserActivity)



    //   console.log('modifiedDatamodifiedData',modifiedData)


    const columns = [
          { field: 'Time', headerName: 'Time', flex: 1 },
        { field: 'action', headerName: 'Action', flex: 1 },
        { field: 'type', headerName: 'Type', flex: 1 },
      ];
    

       useEffect(()=>{

        socketWc &&    socketWc.on('userActionPerformed', (data) => {

          if(data.action.eventID === eventID) {
            
            setAllActivity((prevItems) => {
              // Create a new array by spreading the previous items and adding the new item
              return [...prevItems, data.action];
            });
          }
          
            console.log('storesUserActivity userActionPerformed', data.action)
        })
        return ()=>{
            socketWc.off('userActionPerformed')
        }
    },[socketWc])



            const handleTabClick = (value)=>{
                setActiveTab(value)
            }
            const reversedData = allActivity.slice().reverse();

    return (    
      <>
       <Grid  sx={{m:1}}>
        <Box sx={{display:'flex', justifyContent:'space-between', p:'1rem'}}>
        <Typography variant="h3">
            Activity
        </Typography>


        <IconButton>
          <Close fontSize="large" onClick={handleClose} />
        </IconButton>

        </Box>

        </Grid>     

        <Grid  sx={{m:1, mt:2}}>
            <Stack direction={'row'} gap={2}>

            <Box>
                <LiveButton onClick={()=>handleTabClick('live')} 
                bgColor={activeTab ==='live' ? 'rgb(14, 149, 148, 1)' :'white'} 
                fontColor={activeTab ==='live' ? 'white' : 'black'} variant="contained">Live users</LiveButton>
            </Box>

            <Box>
                <LiveButton onClick={()=>handleTabClick('ondemand')}  
                bgColor={activeTab ==='ondemand' ? 'rgb(14, 149, 148, 1)' :'white'} 
              fontColor={activeTab ==='ondemand' ? 'white' : 'black'}  variant="contained">On Demand users</LiveButton>
            </Box>


            <Box>
                <LiveButton onClick={()=>handleTabClick('activity')}  
                bgColor={activeTab ==='activity' ? 'rgb(14, 149, 148, 1)' :'white'} 
              fontColor={activeTab ==='activity' ? 'white' : 'black'}  variant="contained">Activity</LiveButton>
            </Box>

            </Stack>
          

        </Grid>




        <Grid  sx={{m:4, mt:2}}>
                {
                    activeTab === 'live' && 
                                        <Grid >
                                    <Liveusers activity={ {rows, columns}}/>
                    </Grid>
                }

{
                    activeTab === 'ondemand' &&    <Grid >
                    <OnDemandUsers activity={ {rows, columns}}/>
    </Grid>
                }

{
                    activeTab === 'activity' &&


                    <Grid >
<div style={{ height: '65vh', width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        
        pageSize={10}
        rowsPerPageOptions={[5, 10, 20]}
      />
      </div>
                    </Grid>
        
                }
        </Grid>

      </>
    )
  }


export default UserActivity;