import {AuthConstant} from '../constants/authConstant'

// state
    // token string
    // isAuth bool
const initialState = {
    token:null,
    isAuth:null,
    loading:true,
    error:null
}


function authReducer(state = initialState, action) {

    // const {type,action} = action;

    switch(action.type) {
        case AuthConstant.GET_TOKEN:
            //store to local storage
            localStorage.setItem('statsToken', action.payload);
            return {
                ...state,
                token:action.payload,
                loading:false,
                isAuth:true,
                error:null
            }

        case AuthConstant.AUTH_ERROR:

        return{
            ...state,
            token:null,
            loading:false,
            error:action.payload,
            isAuth:false
        }

        default:
            return state
    }

}


export default authReducer;

// login via token 


//If there is no token, then redirect to login via token page

