import moment from "moment/moment";
import { SET_LOADING, SET_LOADING_FALSE, SET_LOADING_GRAPH , SET_LOADING_GRAPH_FALSE } from "../constants/utilsConstants";

import { StatsConstant } from "../constants/statsConstant";
import { getValidDate } from "../../utils/formateDate";

const initialState = {
  globalFilter: null,
  searchText: null,
  companyName: "",
  companyId: "",
  company_id: "",
  statsByID: {},
  eventStartTime: "",
  eventEndTime: "",
  event_ID: "",
  event:{},
  geoData: [],
  deviceInformation:[],
  rtActiveUsers: [],
  rtTotalActiveUsers: 0,
  rtLoading: true,
  rtTimeStamp: "",
  eventReleaseTime:"",
  loading: true,
  autoCompleteLoading: true,
  error: null,
  companies: [],
  events: [],
  registrant: [],
  totalLiveUsers: 0,
  totalOnDemandUsers: 0,
  averageTimeOnPage: 0,
  isLive:false,
  liveStartTime:0,
  liveEnded:0,
  liveStarted:0,
  eventStatus:'',
  eventNoReg:false,
  loading_graph:false,
  graphData:[],
  brightcoveView:0,
  brightcove_stats_referrer_domain:[],
  locationNoReg:[],
  //event type
  eventType:'',
  dateRange:{
    selected:false,
    start:0,
    end:0
  },
  userActivityByEvent:[],
  timeStamp_event_started:'',
  timeStamp_event_ended:'',
  regCount:{},
  eventReleaseDate:""
};

function statsReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {

    //Set event type
    case StatsConstant.SET_EVENT_TYPE:
      return {
        ...state,
        eventType:payload
      }

      //User activity
      case StatsConstant.SET_USER_ACTIVITY:
        return {
          ...state,
          userActivityByEvent:payload
        };

      //User Location NoReg
      case StatsConstant.GET_USER_LOCATION_NO_REG:
        return {
          ...state,
          locationNoReg:payload
        };

        

    //set company id and name
    case StatsConstant.SET_COMPANY_ID_NAME:
      return {
        ...state,
        statsByID: {},
        companyName: payload.Cname,
        companyId: payload.Cid
      };



    //Global filter
    case StatsConstant.SET_GLOBAL_FILTER:
      return {
        ...state,
        globalFilter: payload.eventOrCompany,
        searchText: payload.searchText,
        eventNoReg:false,
        registrant:[]

      };

       //Reg count per day
    case StatsConstant.GET_REG_COUNT_BY_EVENT:
      return {
        ...state,
        regCount:payload.data,
        loading:false

      };


    case StatsConstant.GET_ALL_STATS_BY_EVENTID:
      return {
        ...state,
        // statsByID: payload.data,
        event_ID: payload.data.eventID,
        eventType:payload.eventType,
        registrant: payload.data.registrants? payload.data.registrants : payload.data.uniqueUsers,
        totalLiveUsers: payload.data.totalLiveUsers,
        totalOnDemandUsers: payload.data.totalOnDemandUsers,
        averageTimeOnPage: payload.data.averageTimeOnPage,
        eventStartTime:  payload.eventStartDate,
        eventEndTime: payload.eventEndDate,
        deviceInformation:payload.device,
        eventReleaseTime:payload.eventReleaseTime,
        geoData:payload.geo,
        isLive:payload.isLive,
        liveStartTime:payload.liveStartTime,
        liveEnded:payload.liveEnded,
        liveStarted :payload.liveStarted,
        eventStatus:payload.event,
        event:payload.eventData,
        loading: false,
        loading_graph:false,
        graphData:payload.graph,
        timeStamp_event_started:payload.data.EventStartedAt,
        timeStamp_event_ended:payload.data.EventEndeddAt,
        dateRange:{
          selected:payload.dateRangeStart ? true :false,
          start:payload.dateRangeStart ? payload.dateRangeStart : 0,
          end: payload.dateRangeEnd ? payload.dateRangeEnd : 0 
        }
      };


      case StatsConstant.GET_ALL_STATS_BY_EVENTID_NO_REG:
        return {
          ...state,
          // statsByID: payload.data,
          event_ID: payload.data.eventID,
          registrant: payload.data.uniqueUsers,
          totalLiveUsers: payload.data.totalLiveUsers,
          eventReleaseTime:payload.eventReleaseTime,

          totalOnDemandUsers: payload.data.totalOnDemandUsers,
          averageTimeOnPage: payload.data.averageTimeOnPage,
          eventStartTime:  payload.eventStartDate,
          eventEndTime: payload.eventEndDate,
          deviceInformation:payload.device,
          geoData:payload.geo,
          isLive:payload.isLive,
          liveStartTime:payload.liveStartTime,
          liveEnded:payload.liveEnded,
          liveStarted :payload.liveStarted,
          eventStatus:payload.event,
          eventNoReg:true,
          loading: false,
          event:payload.eventData
        };

        case StatsConstant.GET_ALL_STATS_BY_EVENTID_BRIGHTCOVE:
          console.log('PAYLOAD BRIGHTCOVE', payload)
          return {
            ...state,
            event_ID: payload.eventID,
            deviceInformation:payload.device,
            geoData:payload.geo,
            brightcoveView:payload.views,
            brightcove_stats_referrer_domain:payload.stats_referrer_domain,
            loading: false,
  
          };

    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };

    case SET_LOADING_FALSE:
      return {
        ...state,
        loading: false,
      };


      case SET_LOADING_GRAPH:
        return {
          ...state,
          loading_graph: true,
        };
  
      case SET_LOADING_GRAPH_FALSE:
        return {
          ...state,
          loading_graph: false,
        };

    case StatsConstant.SET_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case StatsConstant.GET_RT_STATS_BY_EVENTID_NO_REG:
      return {
        ...state,
        rtActiveUsers: payload,
        rtTotalActiveUsers: payload.length,
        rtLoading: false,
        rtTimeStamp: moment().format("LTS"),
      };

      case StatsConstant.GET_RT_STATS_BY_EVENTID:
        return {
          ...state,
          rtActiveUsers: payload,
          rtTotalActiveUsers: payload.length,
          rtLoading: false,
          rtTimeStamp: moment().format("LTS"),
        };
  
    case StatsConstant.RESET_FILTER:
      return {
        ...state,
        statsByID: {},
        geoData: [],
        deviceInformation:[],
        graphData:[],
        brightcoveView:0,
        brightcove_stats_referrer_domain:[],
        userActivityByEvent:[],
        regCount:{},
        averageTimeOnPage:0,
        timeStamp_event_started:"",
        timeStamp_event_ended:"",
        rtActiveUsers: 0,
        event_ID: "",
        company_id: "",
        companyName: "",
        companyId: ""
      };

    case StatsConstant.SET_GEO_DATA:
      return {
        ...state,
        loading: false,
        geoData: payload,
      };

    case StatsConstant.GET_COMPANY_KEYSTROKE:
      return {
        ...state,
        autoCompleteLoading: false,
        companies: payload.items,
      };

    case StatsConstant.GET_EVENT_KEYSTROKE:


      return {
        ...state,
        autoCompleteLoading: false,
        events: payload.data.items,
        company_id: payload.id,
      };

    case StatsConstant.GET_EVENT_BASED_ON_COMPANY_ID:
      return {
        ...state,
        autoCompleteLoading: false,
        events: payload.data.items,
        company_id: payload.id,
        loading: false,
        error: null,
      };

    case StatsConstant.GET_REGISTRANT_DETAILS:
      let dec15 = 900000 * -1;
      let livesUsers = []
      let onDemandUsers = []
      let allRegistrants = payload.data;
      let event_start = new Date(payload.eventStart).getTime() + dec15
      let event_end = new Date(payload.eventEnd).getTime()

      allRegistrants.map((eachRegistrant) => {
        let parsed = JSON.parse(eachRegistrant.fields);
        if (eachRegistrant.loggedInTime !== '0') {
          if ((getValidDate(eachRegistrant.loggedInTime) > event_start && getValidDate(eachRegistrant.loggedInTime) <= event_end)) {
            livesUsers.push(parsed)
          } else if (getValidDate(eachRegistrant.loggedInTime) > event_end) {
            onDemandUsers.push(parsed)
          }
        }


      })

      return {
        ...state,
        registrant: payload.data,
        totalLiveUsers: livesUsers.length,
        totalOnDemandUsers: onDemandUsers.length

      };

    case StatsConstant.SET_REGISTRANT:
      return {
        ...state,
        registrant: [],
        totalLiveUsers: 'N/A',
        totalOnDemandUsers: 'N/A'
      }

    default:
      return state;
  }
}

export default statsReducer;
