import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { connect, useSelector } from "react-redux";

import {
  Box,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Delete, Info } from "@mui/icons-material";

const GeoChart = (props) => {
  const data = useSelector((state) => state.stats.geoData);

  useEffect(() => {
    console.log("geo data", data);
  }, [data]);

  const Gdata = [["Country", "Count"], ...data];
  return (
    <Card
      sx={{
        height: "420px",
      }}
    >
      {/* <Typography
        sx={{ fontSize: "150%", textAlign: "center", background: "#2d3436" }}
        color="white"
      >
        Geo Data Map{" "}
        <Tooltip title="The geolocation data only shows the location of users who are sharing their public IP address">
          <IconButton>
            <Info  style={{color:'white'}}/>
          </IconButton>
        </Tooltip>
      </Typography> */}

      <Chart
        chartEvents={[
          {
            eventName: "select",
            callback: ({ chartWrapper }) => {
              const chart = chartWrapper.getChart();
              const selection = chart.getSelection();
              if (selection.length === 0) return;
              const region = data[selection[0].row + 1];
              console.log("Selected : " + region);
            },
          },
        ]}
        chartType="GeoChart"
        width="auto"
        height="420px"
        data={Gdata}
      />
    </Card>
  );
};

export default connect()(GeoChart);
