import { combineReducers } from 'redux';
import auth from './auth';


//stats portal
import stats from './stats'
import  alert from './alert'

export default combineReducers({
  auth,
  stats,
  alert
});
