import React from "react";

//MUI
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  Fade,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell"
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";
import { useState } from "react";

import {
  useGridApiRef,
} from "@mui/x-data-grid";
import { v4 as uuidv4 } from "uuid";
import { styled } from "@mui/material/styles";
import { ExpandMore } from "@mui/icons-material";

//REDUX
import { connect, useSelector } from "react-redux";
import { secondsToHour } from "../../utils/secondsToHr";
import moment from "moment/moment";
import UserActivity from "../../pages/UserActivity";

const LiveButton = styled(Button)(({ theme,bgColor, fontColor }) => ({
  background:bgColor,
  color:fontColor,
  "&:hover": {
          background:bgColor
  },

}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#34495E",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  // height: "90vh",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  // p: 2,
};
const useStyles = makeStyles((theme) => ({
  hoverCard: {
    transition: "background-Color 0.5s ease",
    "&:hover": {
      backgroundColor: "#576574",
      cursor: "default",
    },
  },
}));

const CardLayoutInteraction = ({
  title,
  numbers,
  color1,
  color2,
  timeFormat,
  spanTag,
  ExtraOptions,
  children,
  registrants,
  eventStartDate,
  eventEndDate,
  icon,
  cpLayout,
  socketWc
}) => {

  //Redux store
  const stats = useSelector((state) => state.stats);
  const { globalFilter, searchText, loading } = stats;


  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [totalLiveViews, setTotalLiveViews] = useState(0)
  const [totalOnDemandView, setTotalOnDemandViews] = useState(0)

  const apiRef = useGridApiRef();
  const [activeTab, setActiveTab] = useState('live')
  const [allActivity, setAllActivity] = useState([])





       const handleTabClick = (value)=>{
           setActiveTab(value)
       }
  useEffect(()=>{
    let _totalLiveViews = 0;
    let _totalOnDemandViews = 0;
    registrants.length > 0 && registrants.map((eachReq)=>{
       _totalLiveViews = _totalLiveViews + eachReq.liveVisits.length
       _totalOnDemandViews = _totalOnDemandViews + eachReq.onDemandVisits.length

    })

    setTotalLiveViews(_totalLiveViews)
    setTotalOnDemandViews(_totalOnDemandViews)

    console.log('Total live and onDeman Views', _totalLiveViews, _totalOnDemandViews)
  },[registrants])

  return (
    <>
      <Card
      elevation={6}
        className={classes.hoverCard}
        sx={{
          width: cpLayout ? '200px' : 'auto',
          height: "150px",
          // backgroundColor: `${color}`,
          background: `linear-gradient(to right bottom, ${color1}, ${color2})`,
          display: "flex",
          alignItems: "center",
          borderRadius:'20px',
          justifyContent: "center",
        }}
      >
        <Typography sx={{ fontSize: "1rem" }} color="white">
          {/* {title} */}
        </Typography>
        <CardContent>
        <Box sx={{ justifyContent: "center" }}>
            <Typography sx={{ fontSize: "1rem" }} color="white">
              {title}
            </Typography>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {icon}
</div>
            <div style={{ display: "flex", justifyContent: "center" }}>
           
           <Button variant={"contained"} onClick={handleOpen} style={{background:'#BBB8B2'}}>
             View
           </Button>
              <Modal
                keepMounted
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                {/* <Fade in={open}> */}
                <Box sx={style}>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={12}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Paper
                          sx={{
                            height: "90vh",
                            width: "100%",
                            overflowY: "scroll",
                          }}
                        >
                <UserActivity socketWc={socketWc} handleClose={handleClose}/>
                </Paper>
                </Grid>
                </Grid>
                </Box>
                {/* </Fade> */}
              </Modal>

           
         </div>

            {/* Modal start  */}
          </Box>
   
        </CardContent>
      </Card>
    </>
  );
};

export default connect()(CardLayoutInteraction);
