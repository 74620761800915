import React from "react";
import { useState } from "react";
import { useEffect } from "react";

//MUI
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  Fade,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DataGrid, GridToolbar, GridColDef, useGridApiRef } from '@mui/x-data-grid';
import { v4 as uuidv4 } from 'uuid';

//Redux
import { getLiveUsersByEventID } from "../../redux/action/stats";
import { connect, useSelector } from "react-redux";

//Utils
import { secondsToHour } from "../../utils/secondsToHr";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  // height: "90vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const useStyles = makeStyles((theme) => ({
  hoverCard: {
    transition: "background-Color 0.5s ease",
    "&:hover": {
      backgroundColor: "#576574",
      cursor: "default",
    },
  },

}));

const CardLayoutLiveUsers = (props) => {
  let { socketWc } = props
  const stats = useSelector((state) => state.stats);

  const { globalFilter, searchText, loading, event_ID, rtActiveUsers } = stats;
  const d = new Date();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [foundRtUsers, setFoundRtUsers] = useState(false)



  //Participant   
  const [participants, setParticipants] = useState({});
  const [_participants, _setParticipants] = useState([]);

  const [pageInfo, setPageInfo] = useState({ page: 1, pageSize: 5 }); // Initial page and pageSize
  const { page, pageSize } = pageInfo;
  const [visitedPage, setVisitedPage] = useState([])
  const [loadingU, setLoading] = useState(true);


  useEffect(() => {
    console.log('jishan/improvement participantsObject ------>>>',_participants)
  }, [_participants])

  useEffect(() => {
    console.log('degub121 event_IDevent_IDevent_IDevent_ID->>')
    // Fetch participants for the initial page when component mounts
    fetchParticipants(page, pageSize);
    // Subscribe to socket event for subsequent pages of participants
    socketWc.on('liveParticipantsByEvent', handleParticipantsUpdate);
    socketWc.on('participantJoined', handleParticipantsAdd);
    socketWc.on('participantLeft', handleParticipantLeft);

    
    return () => {
      socketWc.off('liveParticipantsByEvent', handleParticipantsUpdate);
      socketWc.off('participantJoined');
      socketWc.off('participantLeft', handleParticipantLeft);

    };
  }, [page, event_ID]);

  const fetchParticipants = (page) => {
    setLoading(true); // Set loading to true when fetching data
    if (page && pageSize) {
      socketWc.emit('getLiveParticipants', { eventID: 'EA_1715591206332_2', page, pageSize });
    }
  };


  const handleParticipantsAdd = (participant)=>{
    console.log('jishan/improvement NEW PARTICIPANT', participant)
    _setParticipants((prevParticipants) => [...prevParticipants, participant]);

  }

  const handleParticipantLeft = (participant) => {
    _setParticipants((prevParticipants) =>
        prevParticipants.filter((_participant) => _participant.userID !== participant)
    );
};


  const handleParticipantsUpdate = (updatedParticipants, newPage, newPageSize) => {
    // Update participants state with new data if the received page matches the current page
    if (newPage === page && !visitedPage.includes(newPage)) {
      console.log('jishan/improvement setVisitedPage', visitedPage, updatedParticipants)
      
      setVisitedPage((preVisitedPage) => [...preVisitedPage, newPage])
      if(updatedParticipants) {

        _setParticipants((prevParticipants) => [...prevParticipants, ...updatedParticipants]);
      }


      setParticipants((preParticipant) => {
        const mergedParticipant = { ...preParticipant }
        updatedParticipants?.forEach((participant) => {
          mergedParticipant[participant.userID] = participant
        });
        return mergedParticipant
      })


    }

    setPageInfo({ page: newPage, pageSize: newPageSize });
  };

  const handlePageChange = (newPage) => {
    // Update page state and fetch participants for the new page
    const nextPage = Math.max(1, newPage);
    console.log('nextPage',nextPage)
    setPageInfo({ ...pageInfo, page: nextPage });

  };


  const handleOpen = () => {
    setOpen(true);
    // props.dispatch(getLiveUsersByEventID(event_ID))
    console.log('rtActiveUsersrtActiveUsersrtActiveUsersrtActiveUsers', rtActiveUsers)
    setFoundRtUsers(true)
  }



  const [parsedRegistrant, setParsedRegistrant] = useState([]);
  const [registrantColumn, setRegistrantColumn] = useState([]);
  const [liveUsers, setLiveUsers] = useState([])




  //   useEffect(() => {
  // if(participants?.length >0) {
  //       let p = participants.map((eachReg) => {
  //         setLiveUsers(prevArray => [...prevArray, eachReg])
  //         return {
  //           id: uuidv4(),
  //           ...eachReg
  //         };

  //       });
  //       console.log('jishan/improvement/socketio debug parsed reg', p)
  //       setParsedRegistrant(p);
  //       setRegistrantColumn(Object.keys(p[0]))

  //       let parsedColumn = Object.keys(p[0]).map((eachCo) => {
  //         if(eachCo !== 'SOCKETID') {
  //         return {

  //           field: eachCo,
  //           headerName: eachCo,
  //           align: "left",
  //           width: 240,

  //         }
  //       }
  //       })
  //       parsedColumn.shift()
  //       setRegistrantColumn(parsedColumn)

  //     }
  //   }, [participants]);


  const apiRef = useGridApiRef();


  return (


    <>
      <Card
        className={classes.hoverCard}
        sx={{
          width: props.cpLayout ? '200px' : 'auto',
          height: "150px",
          // backgroundColor: `${color}`,
          background: `linear-gradient(to right bottom, ${props.color1}, ${props.color2})`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: '20px',
        }}
      >
        <CardContent>
          <Box sx={{ justifyContent: "center" }}>
            {/* Active Users have chlid component for RT  */}

            <Typography sx={{ fontSize: "1rem" }} color="white">
              {props.title}
            </Typography>

            {!props.live && <Typography sx={{ textAlign: 'center', fontSize: '0.8rem' }} color="white">
              (On Demand)
            </Typography>}
            <div style={{ display: "flex", justifyContent: "center" }}>
              {props.icon}
            </div>
            <Typography
              sx={{ color: "white", fontSize: "1ren", textAlign: "center" }}
            >
              {props.timeFormat ? secondsToHour(props.numbers) : props.numbers}
            </Typography>
            {props.children}

            {/* Modal start  */}
            <div style={{ display: "flex", justifyContent: "center" }}>

              <Button variant={"contained"} onClick={handleOpen} style={{ background: '#BBB8B2' }}>
                View
              </Button>
              <Modal
                keepMounted
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >


                <Fade in={open}>
                  <Box sx={style}>
                    {

                      (Object.values(participants)?.length > 0) &&

                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={12}
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Paper sx={{ height: '70vh', width: '100%' }}>


                            {loading ? (
                              <p>Loading...</p> // Display loading indicator
                            ) : (
                              <DataGrid
                                // components={{ Toolbar: GridToolbar }}

                                // apiRef={apiRef}
                                rowCount={900}
                                rows={_participants}
                                getRowId={(row) => row.userID}
                                // columns={registrantColumn}
                                columns={[
                                  { field: 'userID', headerName: 'ID', width: 240 },
                                  { field: 'name', headerName: 'name', width: 240 },
                                  { field: 'email', headeremail: 'name', width: 240 },
                                  { field: 'joinTime', headerjoinTime: 'name', width: 240 },



                                ]} // Define your columns
                                pageSize={pageInfo.pageSize}
                                page={pageInfo.page}
                                onPageChange={handlePageChange}
                                sx={{
                                  '& .MuiDataGrid-columnHeader': {
                                    fontSize: '20px',
                                    textTransform: 'uppercase',
                                  }
                                }}
                              />
                            )}


                            {/* <DataGrid
                                apiRef={apiRef}
                                components={{ Toolbar: GridToolbar }}
  
                                rows={participants}
                                columns={registrantColumn}
                                // pageSize={15}
                                // rowsPerPageOptions={[5]}
                                pageSize={pageSize}
                             page={page}
                            onPageChange={handlePageChange}

                                sx={{
                                  '& .MuiDataGrid-columnHeader': {
                                    fontSize: '20px',
                                    textTransform: 'uppercase',
                                  }
                                }}
  
                              /> */}



                          </Paper>
                        </Grid>
                      </Grid>
                    }

                  </Box>
                </Fade>
              </Modal>
            </div>

            {/* Modal start  */}
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default connect()(CardLayoutLiveUsers);
