import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const PrivateRoute = ({
  component: Component,
  auth: { isAuth, loading },
  route,socketWc
}) => {

  console.log('routerouterouteroute', route)

  if (localStorage.getItem('statsToken')) {
    // console.log('<<<<<<<< private route 1>>>>>>>>');
    return <Component route={route} socketWc={socketWc}/>;
  } else {
    // console.log('<<<<<<<< private route 2>>>>>>>>');
    return <Navigate to='/' />;
  }
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
