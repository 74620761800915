import { Box, Card, CardContent } from '@mui/material';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

import React, { useEffect, useState } from 'react'
import { Pie } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
ChartJS.register(ArcElement, Tooltip, Legend);

const OverallpieChart = ({registrants}) => {
    const totalLiveUsers = useSelector((state) => state.stats.totalLiveUsers);
    const totalOnDemandUsers = useSelector(
      (state) => state.stats.totalOnDemandUsers
    );

    const Totalregistrants = useSelector((state) => state.stats.registrant);

    const [aggregatedData, setAggregatedData] = useState({})
    
    useEffect(()=>{
            if(registrants){
                const result = registrants.reduce(
                    (acc, current) => {
                      if (current.watchedLive && !current.watchedOnDemand) {
                        // Count for those who only watched live
                        // acc.watchedLiveCount++;
                      } else if (!current.watchedLive && current.watchedOnDemand) {
                        // Count for those who only watched on demand
                        // acc.watchedOnDemandCount++;
                      } else if (current.watchedLive && current.watchedOnDemand) {
                        // Count for those who watched both
                        acc.watchedBothCount++;
                      } else {
                        // Count for those who didn't watch both
                        acc.didNotWatchCount++;
                      }
                      return acc;
                    },
                    {
                
                      watchedBothCount: 0,
                      didNotWatchCount: 0,
                    }
                  );

                  setAggregatedData({
                      totalLiveUsers,totalOnDemandUsers, ...result, total:Totalregistrants.length
                    })
                    console.log('OVERALLPIECHART', aggregatedData)

            }
    },[registrants])


    
    const data = {
        labels: ['Watched Live', 'Watched On Demand', 'Watched Live and On Demand', 'Did Not Watch'],
        datasets: [
          {
            data: [aggregatedData.totalLiveUsers, aggregatedData.totalOnDemandUsers, aggregatedData.watchedBothCount, aggregatedData.didNotWatchCount],
            backgroundColor: ['#4CAF50', '#006e87', '#00a0bd', 'gray'],
            hoverBackgroundColor: ['#4CAF50', '#006e87', '#00a0bd', 'gray'],
          },
        ],
      };
      const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Views distribution',
          },
        },
      };
    

  return (


      <Box sx={{ justifyContent: "center" }}>

      <Pie options={options} height='400px' width={'100%'}  data={data} />

      </Box>
      


  )
}

export default OverallpieChart