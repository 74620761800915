import axios from 'axios'
import { AuthConstant } from '../constants/authConstant';


import { setAlert } from './alert'


export const getAuthToken = (tokenP, redirect)=> async(dispatch)=>{

    try {
            //check if token present in localstorage
         let token = localStorage.getItem('statsToken');
        if(token === process.env.REACT_APP_TOKEN){ 
            dispatch({
                type:AuthConstant.GET_TOKEN,
                payload:token
            })

            redirect();
        }  else {
                // console.log('NO token found',tokenP)
            if(tokenP === process.env.REACT_APP_TOKEN) {
                dispatch({
                    type:AuthConstant.GET_TOKEN,
                    payload:tokenP
                })
                redirect();
            }  else {
                dispatch(setAlert('Incorrect token' , 'error', 3000))
                dispatch({
                    type:AuthConstant.AUTH_ERROR,
                    payload:'Incorrect token'
                })
            }

     

        }
    } catch (error) {
        console.log(error)
            dispatch({
                type:AuthConstant.AUTH_ERROR,
                payload:error
            })
    }


    
    
}


// loadtoken from localstorage

