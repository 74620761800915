import React from "react";

//MUI
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  Fade,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell"
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";
import { useState } from "react";

import {
  useGridApiRef,
} from "@mui/x-data-grid";
import { v4 as uuidv4 } from "uuid";
import { styled } from "@mui/material/styles";
import { ExpandMore } from "@mui/icons-material";

//REDUX
import { connect, useSelector } from "react-redux";
import { secondsToHour } from "../../utils/secondsToHr";



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#34495E",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  // height: "90vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const useStyles = makeStyles((theme) => ({
  hoverCard: {
    transition: "background-Color 0.5s ease",
    "&:hover": {
      backgroundColor: "#576574",
      cursor: "default",
    },
  },
}));

const CardLayoutInteraction = ({
  title,
  numbers,
  color1,
  color2,
  timeFormat,
  spanTag,
  ExtraOptions,
  children,
  registrants,
  eventStartDate,
  eventEndDate,
  icon,
  cpLayout
}) => {

  //Redux store
  const stats = useSelector((state) => state.stats);
  const { globalFilter, searchText, loading } = stats;


  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);



  const apiRef = useGridApiRef();

  return (
    <>
      <Card
        className={classes.hoverCard}
        sx={{
          width:cpLayout ? '200px' : 'auto',
          height: "200px",
          // backgroundColor: `${color}`,
          background: `linear-gradient(to right bottom, ${color1}, ${color2})`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography sx={{ fontSize: "200%" }} color="white">
          {/* {title} */}
        </Typography>
        <CardContent>
          <Box sx={{ justifyContent: "center" }}>
            {/* Active Users have chlid component for RT  */}

            {/* <Typography
              sx={{ color: "white", fontSize: "350%", textAlign: "center" }}
            >
              {timeFormat ? secondsToHour(numbers) : numbers}
            </Typography>
              {children} */}

            {/* Modal start  */}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant={"contained"}
                onClick={handleOpen}
                sx={{ padding: "20rem" }}
                style={{background:'#cfbc9f'}}
              >
                {icon}
                <Typography sx={{ fontSize: "200%" }} color="white" >
                  Interactions
                </Typography>
              </Button>
              <Modal
                keepMounted
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open}>
                  <Box sx={style}>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={12}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Paper
                          sx={{
                            height: "80vh",
                            width: "90%",
                            overflowY: "scroll",
                          }}
                        >
                          <Typography
                            sx={{ textAlign: "center", fontSize: "2rem" }}
                          >
                            Visits
                          </Typography>
                          { registrants &&  registrants.map((eachReq) => {
                            return (
                              <Accordion sx={{ m: 2, background: "#ecf0f1" }}>
                                <AccordionSummary
                                  expandIcon={<ExpandMore />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography
                                    sx={{ fontSize: "1rem" }}
                                    color="black"
                                  >
                                    UID - {eachReq.uid}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <TableContainer
                                    component={Paper}
                                    sx={{
                                      p: 2,
                                      marginBottom: "2rem",
                                      background: "#f5f6fa",
                                      border: "1px solid",
                                    }}
                                  >
                                    <Table aria-label="customized table">
                                      <TableHead>
                                        <TableRow>
                                          <StyledTableCell
                                            align="center"
                                            colSpan={3}
                                          >
                                            Live Visits
                                          </StyledTableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                           {  eachReq.liveVisits.length > 0 ? (
                                          <StyledTableRow>
                                            <StyledTableCell align="center">
                                              Start Time
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                              End Time
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                              Duration
                                            </StyledTableCell>
                                          </StyledTableRow>
                                        ) : (
                                          <Typography textAlign={"center"}>
                                            No Live visits
                                          </Typography>
                                        )}
                
                                        {  eachReq.liveVisits.length > 0 && eachReq.liveVisits.map(
                                          (eachVisits) => (
                                            <StyledTableRow>
                                              <StyledTableCell align="center">
                                                {eachVisits.startTime}
                                              </StyledTableCell>
                                              <StyledTableCell align="center">
                                                {eachVisits.endTime}
                                              </StyledTableCell>
                                              <StyledTableCell align="center">
                                                {secondsToHour(
                                                  new Date(
                                                    eachVisits.endTime
                                                  ).getTime() -
                                                  new Date(
                                                    eachVisits.startTime
                                                  ).getTime()
                                                )}
                                              </StyledTableCell>
                                            </StyledTableRow>
                                          )
                                        )}

                                        {/* ))} */}
                                      </TableBody>
                                    </Table>

                                    <br />
                                    <Table aria-label="customized table">
                                      <TableHead>
                                        <TableRow>
                                          <StyledTableCell
                                            align="center"
                                            colSpan={3}
                                          >
                                            On Demand Visits
                                          </StyledTableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {eachReq.onDemandVisits.length > 0 ? (
                                          <StyledTableRow>
                                            <StyledTableCell align="center">
                                              Start Time
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                              End Time
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                              Duration
                                            </StyledTableCell>
                                          </StyledTableRow>
                                        ) : (
                                          <Typography textAlign={"center"}>
                                            No On demand visits
                                          </Typography>
                                        )}
                                        {/* {rows.map((row) => ( */}

                                        {/* Live visits  */}
                                        {eachReq.onDemandVisits.length > 0 && eachReq.onDemandVisits.map(
                                          (eachVisits) => (
                                            <StyledTableRow>
                                              <StyledTableCell align="center">
                                                {eachVisits.startTime}
                                              </StyledTableCell>
                                              <StyledTableCell align="center">
                                                {eachVisits.endTime}
                                              </StyledTableCell>
                                              <StyledTableCell align="center">
                                                {secondsToHour(
                                                  new Date(
                                                    eachVisits.endTime
                                                  ).getTime() -
                                                  new Date(
                                                    eachVisits.startTime
                                                  ).getTime()
                                                )}
                                              </StyledTableCell>
                                            </StyledTableRow>
                                          )
                                        )}

                                        {/* ))} */}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </AccordionDetails>
                              </Accordion>
                            );
                          })}
                        </Paper>
                      </Grid>
                    </Grid>
                  </Box>
                </Fade>
              </Modal>
            </div>

            {/* Modal start  */}
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default connect()(CardLayoutInteraction);
