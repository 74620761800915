import React from "react";

import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";


import { secondsToHour } from "../../utils/secondsToHr";
const useStyles = makeStyles((theme) => ({
  hoverCard: {
    transition: "background-Color 0.5s ease",
    "&:hover": {
      opacity:0.8,
      cursor: "default",
    },
  },

}));

const CardLayout = ({
  title,
  numbers,
  color1,
  color2,
  timeFormat,
  icon,
  children,
  details,
  cpLayout,fontColor,additional_title_component,height
}) => {
  const classes = useStyles();
  console.log('  <additional_title_component/>')
  return (
    <>
      <Card
        className={classes.hoverCard}
        sx={{
          width:cpLayout ? '200px' : 'auto',
          height: height ? height :"150px",
          backgroundColor: `${color2}`,
        

        }}
      >
        <Box sx={{display:'flex', justifyContent:'center',backgroundColor: `${color1}`, alignItems:'center'}}>
                <Typography sx={{ fontSize: "1rem", textAlign:'center',backgroundColor: `${color1}`, padding:'5px' }} color="white">
         {title}
        </Typography>
        {additional_title_component}
        </Box>
      
        <CardContent>
          <Box sx={{ justifyContent: "center" }}>

            {/* <Typography sx={{ fontSize: "200%", textAlign:'center' }} color="white">
              {title}
            </Typography>
         */}
    
            <div style={{ display: "flex", justifyContent: "center" }}>
              {icon}
              
</div>
<Typography  color={fontColor ? fontColor : 'white'} textAlign={'center'} sx={{fontSize:cpLayout? '0.8rem' :'1rem'}}>
              {details}
            </Typography>

            <Typography  color={fontColor ? fontColor : 'white'} 
              sx={{fontSize: "1.5rem", textAlign: "center" }}
            >
                {timeFormat ? secondsToHour(numbers) : numbers} 
            </Typography>
            <Box sx={{display:'flex', justifyContent:'center', background:'', height:'fit-content'}}>
            {children}
            </Box>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default CardLayout;
