import React, { useEffect, useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';import { connect, useSelector } from "react-redux";

import {
  Box,
  Card,
  CardContent,
  IconButton,
  Tooltip as TooltipMUI,
  Typography,
} from "@mui/material";
import { Delete, Info } from "@mui/icons-material";
import { Bar } from "react-chartjs-2";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
const GeoChartByCity = ({registrants}) => {
//   const data = useSelector((state) => state.stats.geoData);
//   const registrants = useSelector((state) => state.stats.registrant);

    const data = []
  registrants.map((each)=>{

            if(each.geo_location) {
                data.push(each.geo_location )
            } else {
                    data.push(each )
                
            }
            
  })


  console.log('datadatadatadata', registrants)

  const cityCounts = data.reduce((countMap, user) => {
    const city = user.city;
    if(city) {

        countMap[city] = (countMap[city] || 0) + 1;
    }
    return countMap;
  }, {});




  const labels = Object.keys(cityCounts);
  const dataPoints = Object.values(cityCounts);

  const chartData = {
    responsive: true,
    labels,
    datasets: [
      {
        label: 'User Count by City',
        data: dataPoints,
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
    ],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Views distribution',
      },
    },
  };

  return (
    <Card
      sx={{
        height: "420px",
      }}
    >
      {/* <Typography
        sx={{ fontSize: "150%", textAlign: "center", background: "#2d3436" }}
        color="white"
      >
        User Distribution by City
        <TooltipMUI title="The geolocation data only shows the location of users who are sharing their public IP address">
          <IconButton>
            <Info  style={{color:'white'}}/>
          </IconButton>
        </TooltipMUI>
      </Typography> */}

      <Box  sx={{height:'100%', background:'', display:'flex',alignItems:'center'}}>
      <Bar data={chartData} options={options} />
      </Box>

    </Card>
  );
};

export default connect()(GeoChartByCity);
