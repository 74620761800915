import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  Stack,
  Tooltip,
  IconButton,
  Paper,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import GroupIcon from "@mui/icons-material/Group";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import PersonIcon from "@mui/icons-material/Person";
import TourIcon from "@mui/icons-material/Tour";
// REDUX

import CardLayout from "./patials/CardLayout";
import GraphLayout from "./patials/LineGraphLayout";
import GeoChart from "./patials/GeoChart";
import { BsSliders2Vertical, BsBookHalf, BsBugFill, BsFillInfoCircleFill, BsPinMapFill } from "react-icons/bs";
import { MdOutlineDevices } from "react-icons/md";
import { VscGraph } from "react-icons/vsc";
import {
  getAllUserLocationNoReg,
  getLiveUsersByEventID,
  getLiveUsersByEventIDNoReq,
  getRegistrantDetails,
  getStatByID,
  setUserActivity,
} from "../redux/action/stats";
import Spinner from "./ui/Spinner";
import CardLayoutRegistrantModal from "./patials/CardLayoutRegistrantModal";
import CardLayoutInteraction from "./patials/CardLayoutInteraction";
import { setAlert } from "../redux/action/alert";
import CardLayoutLiveUsers from "./patials/CardLayoutLiveUsers";
import CardLayoutLive from "./patials/CardLayoutLive";
import DeviceChartLayout from "./patials/DeviceChart";
import CardLayoutInteractionNoReg from "./patials/CardLayoutInteractionNoReg";
import CardLayoutRegistrantModalNoReg from "./patials/CardLayoutRegistrantModalNoReg";
import CardLayoutLiveUsersNoReg from "./patials/CardLayoutLiveUsersNoReg";
import GraphContainer from "./patials/GraphContainer";
import Brightcove_Referrer from "./patials/brightcoveStats/CardReferrer";
import { Link } from "react-router-dom";
import CardLayoutOverview from "./patials/CardLayoutOverview";
import { Groups3, Info, QueryStats } from "@mui/icons-material";
import GeoChartByCity from "./patials/GeoChartByCity";
import CardComponent from "./layouts/card/CardComponent";
import RegModal from "./layouts/RegModal";
import BarChartLayout from "./patials/BarChartLayout";
import PrePostReg from "./layouts/graph/PrePostReg";
import OnDemandGraphWatched from "./layouts/graph/OnDemandGraphWatched";
import OverallpieChart from "./layouts/pieChart/OverallpieChart";
import CardLayoutLiveUsersScoketio from "./patials/CardLayoutLiveUsersScoketio";
import CardLayoutLiveUsersScoketioCustomTable from "./patials/LiveUsers/CardLayoutLiveUsersScoketioCustomTable";
import NoRegCardLayoutLiveUsersScoketioCustomTable from "./patials/LiveUsers/NoRegCardLayoutLiveUsersScoketioCustomTable";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    flexGrow: 1,
  },
}));

const Stats = (props) => {
  let { socketWc } = props
  const [eventFound, setEventFound] = useState(false);

  const stats_by_id = useSelector((state) => state.stats.statsByID);
  const loading = useSelector((state) => state.stats.loading);

  // RT
  const rt_Users = useSelector((state) => state.stats.rtActiveUsers);
  const rtTotalActiveUsers = useSelector(
    (state) => state.stats.rtTotalActiveUsers
  );
  const rtLoading = useSelector((state) => state.stats.rtLoading);
  const rtTimeStamp = useSelector((state) => state.stats.rtTimeStamp);

  // Registrants
  const registrants = useSelector((state) => state.stats.registrant);
  const totalLiveUsers = useSelector((state) => state.stats.totalLiveUsers);
  const totalOnDemandUsers = useSelector(
    (state) => state.stats.totalOnDemandUsers
  );
  const avgTimeOnPage = useSelector((state) => state.stats.averageTimeOnPage);
  //Event
  const event_id = useSelector((state) => state.stats.event_ID);
  const eventType = useSelector((state) => state.stats.eventType);
  const brightcove_stats_referrer_domain = useSelector((state) => state.stats.brightcove_stats_referrer_domain);


  const eventStartTime = useSelector((state) => state.stats.eventStartTime);
  const eventEndTime = useSelector((state) => state.stats.eventEndTime);

  const eventReleaseTime = useSelector((state) => state.stats.eventReleaseTime);

  
  const geoData = useSelector((state) => state.stats.geoData);
  const deviceInformation = useSelector(
    (state) => state.stats.deviceInformation
  );
  const isLive = useSelector((state) => state.stats.isLive);
  const eventStatus = useSelector((state) => state.stats.eventStatus);


  const eventWithNoReq = useSelector((state) => state.stats.eventNoReg);
  const graphData = useSelector((state) => state.stats.graphData)
  const brightcoveView = useSelector((state) => state.stats.brightcoveView)
  const storesUserActivity = useSelector((state) => state.stats.userActivityByEvent)
  const locationNoReg = useSelector((state) => state.stats.locationNoReg)


  const timeStamp_event_started = useSelector((state) => state.stats.eventReleaseTime)
  const timeStamp_event_ended = useSelector((state) => state.stats.timeStamp_event_ended)



  const regCount = useSelector((state) => state.stats.regCount);


  const dispatch = useDispatch()

  useEffect(() => {
    if (eventWithNoReq) {
      console.log('eventNoRegeventNoRegeventNoReg', eventWithNoReq)
      dispatch(setUserActivity(event_id))
      dispatch(getAllUserLocationNoReg(event_id))
    }
  }, [eventWithNoReq])

  // const [geoData, setGeoData] = useState(null);

  useEffect(() => {
    // props.dispatch(getRegistrantDetails(stats_by_id.eventID));
  }, [eventFound]);

  const pollingRT = () => {
    if (eventWithNoReq) {
      // props.dispatch(getLiveUsersByEventIDNoReq(event_id));
    } else {
      // props.dispatch(getLiveUsersByEventID(event_id));
    }
  };

  useEffect(() => {
    let reg = registrants.filter((each) => new Date(each.date).getTime() < new Date(eventReleaseTime).getTime()).filter((each) =>(each.watchedLive === true && each.watchedOnDemand === false))
    console.log('beforeEvent', reg, 'event', (eventReleaseTime))
  }, [registrants])

  useEffect(() => {
    if (eventType !== 'bc') {
      const timer = setInterval(pollingRT, 30000);
      return () => clearInterval(timer);
    }
  }, []);


  const handle_RT_loading = () => {
    props.dispatch(getLiveUsersByEventID(props.eventID));
  };

  const classes = useStyles();

  return (
    <div className={classes.rootContainer}>
      {loading ? (
        <Box sx={{ display: "flex", alignItems: "center", height: "30vh" }}>
          <Spinner />
        </Box>
      ) : (
        <>
          <Grid container justifyContent={"center"} spacing={4} p={2}>


            {

              eventType === 'bc' ?
                <>
                  <Grid item xs={12} md={6} lg={3}>
                    <CardLayout
                      title={"Views"}
                      details={"Total views"}
                      icon={
                        <GroupIcon fontSize="large" sx={{ color: "white" }} />
                      }
                      numbers={brightcoveView}
                      color1={"rgba(18, 115, 117,1)"}
                      color2={"rgba(18, 115, 117,0.8)"}
                    />
                  </Grid>

                </>
                :

                registrants?.length > 0 ? (
                  <>
                    {isLive && eventStatus !== 'stopped' ? (  
                      <>

                        {eventWithNoReq ? (
                          <Grid item xs={12} md={6} lg={eventWithNoReq ? 3 : 4} xl={2}>
                            <NoRegCardLayoutLiveUsersScoketioCustomTable
                               icon={
                                <LiveTvIcon
                                  fontSize="large"
                                  sx={{ color: "white" }}
                                />
                              }
                              socketWc={socketWc}
                              registrants={rt_Users}
                              eventStartDate={eventStartTime}
                              eventEndDate={eventEndTime}
                              title={"Users now"}
                              live={isLive}
                              numbers={loading ? <Spinner /> : rtTotalActiveUsers}
                              color1={"rgba(242, 84, 45, 1)"}
                              color2={"rgba(242, 84, 45, 0.8)"}
                              height={eventWithNoReq && '170px'}
                            />




                          </Grid>
                        ) : (
                          <Grid item xs={12} md={6} lg={eventWithNoReq ? 3 : 4}>

                            {/* <CardLayoutLiveUsers
                              icon={
                                <LiveTvIcon
                                  fontSize="large"
                                  sx={{ color: "white" }}
                                />
                              }
                              registrants={rt_Users}
                              eventStartDate={eventStartTime}
                              eventEndDate={eventEndTime}
                              title={"Users now"}
                              live={isLive}
                              numbers={loading ? <Spinner /> : rtTotalActiveUsers}
                              color1={"rgba(242, 84, 45, 1)"}
                              color2={"rgba(242, 84, 45, 0.8)"}
                              height={eventWithNoReq && '170px'}
                            /> */}
                                   <CardLayoutLiveUsersScoketioCustomTable
                              icon={
                                <LiveTvIcon
                                  fontSize="large"
                                  sx={{ color: "white" }}
                                />
                              }
                              socketWc={socketWc}
                              registrants={rt_Users}
                              eventStartDate={eventStartTime}
                              eventEndDate={eventEndTime}
                              title={"Users now"}
                              live={isLive}
                              numbers={loading ? <Spinner /> : rtTotalActiveUsers}
                              color1={"rgba(242, 84, 45, 1)"}
                              color2={"rgba(242, 84, 45, 0.8)"}
                              height={eventWithNoReq && '170px'}
                            />

                          </Grid>
                        )}
                      </>







                    ) : (
                      <Grid item xs={12} md={6} lg={eventWithNoReq ? 3 : 4}>
                        {/* <CardComponent
                          title={"Users now"}
                          details={"Live user count"}
                          icon={
                            <LiveTvIcon fontSize="inherit" sx={{ color: "rgba(242, 84, 45, 1)" }} />
                          }
                          numbers={'-'}
                          color1={"white"}
                          color2={"rgba(242, 84, 45, 1)"}
                          fontColor={'white'}
                          fontColorTitle={'white'}
                          fontColorDetails={'white'}
                          height={eventWithNoReq && '170px'}
                        /> */}
                                  <NoRegCardLayoutLiveUsersScoketioCustomTable
                              icon={
                                <LiveTvIcon
                                  fontSize="large"
                                  sx={{ color: "white" }}
                                />
                              }
                              socketWc={socketWc}
                              registrants={rt_Users}
                              eventStartDate={eventStartTime}
                              eventEndDate={eventEndTime}
                              title={"Users now"}
                              live={isLive}
                              eventStatus={eventStatus}
                              numbers={loading ? <Spinner /> : rtTotalActiveUsers}
                              color1={"rgba(242, 84, 45, 1)"}
                              color2={"rgba(242, 84, 45, 0.8)"}
                              height={eventWithNoReq && '170px'}
                            />

                      </Grid>
                    )}






                    {
                      registrants?.length && (
                        <>
                          {!eventWithNoReq &&
                            <Grid item xs={12} md={6} lg={4}>
                              <CardLayoutInteraction
                                icon={
                                  <TourIcon fontSize="large" sx={{ color: "white" }} />
                                }
                                socketWc={socketWc}
                                registrants={registrants}
                                title={"User Interactions"}
                                color1={"#cfbc9f"}
                                color2={"rgb(207 188 159 / 80%)"}
                              />
                            </Grid>
                          }
                        </>
                      )
                    }



                    {/* {
                      registrants?.length>0 && (
                        <>
                          {!eventWithNoReq &&
                            <Grid item xs={12} md={6} lg={3}>
                              <CardLayoutOverview
                                icon={
                                  <QueryStats fontSize="large" sx={{ color: "white" }} />
                                }
                                socketWc={socketWc}
                                registrants={registrants}
                                totalLiveUsers={loading ? 0 : totalLiveUsers}
                                totalOnDemandUsers={loading ? 0 : totalOnDemandUsers}
                                totalusers={totalLiveUsers + totalOnDemandUsers}
                                title={"Overview"}
                                color1={"#273c75"}
                                color2={"#40739e"}
                              />
                            </Grid>
                          }
                        </>
                      )
                    } */}








                    {
                      eventWithNoReq &&
                      <Grid item xs={12} md={6} lg={3} xl={2}>
                        <CardComponent
                          title={"Total Views"}
                          details={"Total unique views"}
                          icon={
                            <Groups3 fontSize="inherit" sx={{ color: "white" }} />
                          }
                          numbers={loading ? <Spinner /> : registrants?.length}
                          color1={"#2d3436"}
                          color2={"white"}
                          fontColor={'black'}
                          fontColorTitle={'gray'}
                          fontColorDetails={'gray'}
                          height={'170px'}
                        />
                      </Grid>
                    }




                    {
                      !eventWithNoReq && registrants?.length>0 &&
                      <Grid item xs={12} md={6} lg={4} xl={4}>
                        <CardLayoutRegistrantModal
                          registrants={registrants}
                          eventStartDate={eventStartTime}
                          eventEndDate={eventEndTime}
                          title={"Total Registrants"}
                          numbers={loading ? <Spinner /> : registrants?.length}
                          color1={"#2d3436"}
                          color2={"#636e72"}
                        />
                      </Grid>
                    }





                    <Grid item xs={12} md={6} lg={3} xl={3}>
                    
                    {
                      registrants?.length && (
                        <CardComponent
                        title={"Watched Live"}
                        details={"Users who have watched live"}
                        icon={
                          <PersonIcon fontSize="inherit" sx={{ color: "white" }} />
                        }
                        numbers={loading ? <Spinner /> : totalLiveUsers}
                        color1={"rgb(14, 149, 148, 1)"}
                        color2={"white"}
                        fontColor={'black'}
                        fontColorTitle={'gray'}
                        fontColorDetails={'gray'}
                        height={'170px'}
                      >
                        {
                          !eventWithNoReq && <RegModal data={loading ? <Spinner /> : registrants.filter((each) => each.watchedLive === true)} />

                        }

                      </CardComponent>
                      )
                    }

                    
                    </Grid>



{

              !eventWithNoReq &&      <Grid item xs={12} md={6} lg={3} xl={3}>

                    {registrants?.length  &&(
                        <CardComponent
                        title={"Pre Reg + Watched Live"}
                        details={"Users who had registered before event and watched  Live"}
                        icon={
                          <PersonIcon fontSize="inherit" sx={{ color: "white" }} />
                        }
                        numbers={loading ? <Spinner /> : registrants.filter((each) => new Date(each.date) < new Date(timeStamp_event_started)).filter((each) =>(each.watchedLive === true && each.watchedOnDemand === false)).length}
                        color1={"rgb(14, 149, 148, 1)"}
                        color2={"white"}
                        fontColor={'black'}
                        fontColorTitle={'gray'}
                        fontColorDetails={'gray'}
                        height={'170px'}
                      >
                        {
                          !eventWithNoReq && <RegModal data={loading ? 'loading' : registrants.filter((each) => new Date(each.date).getTime() < new Date(timeStamp_event_started).getTime()).filter((each) => (each.watchedLive === true && each.watchedOnDemand === false))} />

                        }

                      </CardComponent>
                    )}
                    
                    </Grid>
}





                    <Grid item xs={12} md={6} lg={3} xl={3}>
                    {registrants?.length && (
                                 <CardComponent
                                 title={"Watched On Demand"}
                                 details={"Users who have watched on demand"}
                                 icon={
                                   <PersonIcon fontSize="inherit" sx={{ color: "white" }} />
                                 }
                                 numbers={loading ? <Spinner /> : totalOnDemandUsers}
                                 color1={"rgb(14, 149, 148, 1)"}
                                 color2={"white"}
                                 fontColor={'black'}
                                 fontColorTitle={'gray'}
                                 fontColorDetails={'gray'}
                                 height={'170px'}
                               >
                                 {
                                   !eventWithNoReq && <RegModal data={loading ? 'loading' : registrants.filter((each) => each.watchedOnDemand === true)} />
         
                                 }
         
                               </CardComponent>
                    )}
           

                    </Grid>


                  {

                !eventWithNoReq &&    <Grid item xs={12} md={6} lg={3} xl={3}>

                    {registrants?.length &&  (   <CardComponent
                        title={"Pre Reg + On Demand"}
                        details={"Users who had registered before event and watched On Demand"}
                        icon={
                          <PersonIcon fontSize="inherit" sx={{ color: "white" }} />
                        }
                        numbers={loading ? <Spinner /> : registrants.filter((each) => new Date(each.date).getTime() <= new Date(timeStamp_event_started).getTime()).filter((each) => (each.watchedOnDemand === true && each.watchedLive === false )).length}
                        color1={"rgb(14, 149, 148, 1)"}
                        color2={"white"}
                        fontColor={'black'}
                        fontColorTitle={'gray'}
                        fontColorDetails={'gray'}
                        height={'170px'}
                      >
                        {
                          !eventWithNoReq && <RegModal data={loading ? 'loading' : registrants.filter((each) => new Date(each.date).getTime() <= new Date(timeStamp_event_started).getTime()).filter((each) => (each.watchedOnDemand === true && each.watchedLive === false ) )} />

                        }

                      </CardComponent>)}
                   
                    </Grid>
                  }

                {

    !eventWithNoReq &&     <Grid item xs={12} md={6} lg={3} xl={3}>

                    {registrants?.length && ( <CardComponent
                        title={"Not watched"}
                        details={"Users who had registered but did not watched Live or On Demand"}
                        icon={
                          <PersonIcon fontSize="inherit" sx={{ color: "white" }} />
                        }
                        numbers={loading ? <Spinner /> : registrants.filter((each) => !each.watchedOnDemand && !each.watchedLive).length}
                        color1={"rgb(14, 149, 148, 1)"}
                        color2={"white"}
                        fontColor={'black'}
                        fontColorTitle={'gray'}
                        fontColorDetails={'gray'}
                        height={'170px'}
                      >
                        {

                          !eventWithNoReq && <RegModal data={loading ? 'loading' : registrants.filter((each) => !each.watchedOnDemand && !each.watchedLive)} />
                        }


                      </CardComponent>)}
                  
                    </Grid>
                }



                    {
                      
                      !eventWithNoReq &&     <Grid item xs={12} md={6} lg={3} xl={3}>
                    {registrants?.length && (    <CardComponent
                        title={"Total users"}
                        details={"(Live or On demand)"}
                        icon={
                          <GroupIcon fontSize="inherit" sx={{ color: "white" }} />
                        }
                        //  numbers={loading ? <Spinner /> : totalLiveUsers + totalOnDemandUsers}
                        numbers={loading ? <Spinner /> : registrants.filter((each) => (each.watchedOnDemand === true || each.watchedLive === true) || (each.watchedOnDemand === true && each.watchedLive === true)).length}
                        color1={"rgba(18, 115, 117,1)"}
                        color2={"white"}
                        fontColor={'black'}
                        fontColorTitle={'gray'}
                        fontColorDetails={'gray'}
                        height={'170px'}

                      >
                        {
                          !eventWithNoReq && <RegModal data={loading ? 'loading' : registrants.filter((each) => (each.watchedOnDemand === true || each.watchedLive === true) || (each.watchedOnDemand === true && each.watchedLive === true))} />

                        }

                      </CardComponent>)}
                   

                    </Grid>
                    }


                  </>
                ) : (
                  <Grid item xs={12} md={6} lg={4}>
                    <CardLayout
                      title={"No data found"}
                      color1={"#fa983a"}
                      color2={"#ffeaa7"}
                    />
                  </Grid>
                )




            }






            {avgTimeOnPage && (
              <Grid item xs={12} md={6} lg={3} xl={3}>

                <CardComponent
                  title={"Average Time"}
                  details={"Average time spent on event"}
                  icon={
                    <AccessTimeFilledIcon fontSize="inherit" sx={{ color: "white" }} />
                  }
                  timeFormat={true}
                  numbers={avgTimeOnPage}
                  color1={"rgba(242, 84, 45, 1)"}
                  color2={"white"}
                  fontColor={'black'}
                  fontColorTitle={'gray'}
                  fontColorDetails={'gray'}
                  height={'170px'}

                />
              </Grid>
            )}
          </Grid>




          {/*Website event - Brightcove Stats */}

          <Grid item container justifyContent={"center"} spacing={4} p={2}>
            {eventType === 'bc' && (
              <Grid item xs={12} md={6} lg={10}>

                <Brightcove_Referrer brightcove_stats_referrer_domain={brightcove_stats_referrer_domain} />
              </Grid>
            )}
          </Grid>
          {/*Website event - Brightcove Stats */}





          {/* event with No Reg (Stats Activity Information) */}
          {eventWithNoReq &&

            <Box elevation={2} sx={{ background: '', m: 1, mt: 4, borderRadius: '10px' }}>

              <Typography sx={{ fontSize: '1.4rem', marginTop: '2rem', textAlign: 'center' }}>
                Activity Overview
              </Typography>
              <Grid item container justifyContent={"center"} spacing={4} p={2}>



                <Grid item xs={12} md={6} lg={3} xl={2}>


                  <CardComponent
                    title={"Slider Count"}
                    details={"Total number of time Slider was clicked"}
                    icon={
                      <BsSliders2Vertical fontSize="inherit" color="white" sx={{ color: "white" }} />
                    }
                    numbers={loading ? <Spinner /> : storesUserActivity?.filter((each) => each.type === 'SLIDER').length}
                    color1={"#273c75"}
                    color2={"white"}
                    fontColor={'black'}
                    fontColorTitle={'gray'}
                    fontColorDetails={'gray'}
                  />
 
                </Grid>

                <Grid item xs={12} md={6} lg={3} xl={2}>
                  <CardComponent
                    title={"Notes  accessed"}
                    details={"Total number of time Notes was used"}
                    icon={
                      <BsBookHalf fontSize="inherit" color="white" sx={{ color: "white" }} />
                    }
                    numbers={loading ? <Spinner /> : storesUserActivity?.filter((each) => each.type === 'NOTE').length}
                    color1={"#273c75"}
                    color2={"white"}
                    fontColor={'black'}
                    fontColorTitle={'gray'}
                    fontColorDetails={'gray'}
                  />
                  {/* <CardLayout
                                title={"Notes feature accessed"}
                                additional_title_component={
                                    (<Tooltip title={'Slide count'}>
                                    <IconButton>
                                      <Info  style={{color:'white'}}/>
                                    </IconButton>
                                  </Tooltip>)
                                  }
                                  height={'160px'}
                                fontColor='black'
                                color1={"#273c75"}
                                color2={"#273c75)"}
                            >

                                 <Box sx={{ height: '90px', width: '90px', borderRadius: '50%', background: 'linear-gradient(#273c75, #192a56)', display: 'flex', placeContent: 'center', placeItems: 'center' }}>
                            <Typography sx={{ fontSize: '2.5rem', color: 'white' }}>
                                {storesUserActivity?.filter((each)=> each.type==='NOTE').length}
                            </Typography>
                        </Box>
                                 </CardLayout> */}
                </Grid>

                <Grid item xs={12} md={6} lg={3} xl={2}>
                  <CardComponent
                    title={"No. of issues"}
                    details={"Total number of time issues were occured"}
                    icon={
                      <BsBugFill fontSize="inherit" color="white" sx={{ color: "white" }} />
                    }
                    numbers={loading ? <Spinner /> : storesUserActivity?.filter((each) => each.type === 'HELP').length}
                    color1={"#273c75"}
                    color2={"white"}
                    fontColor={'black'}
                    fontColorTitle={'gray'}
                    fontColorDetails={'gray'}
                  />
                  {/* <CardLayout
                                title={"No. of issues"}
                                additional_title_component={
                                    (<Tooltip title={'Slide count'}>
                                    <IconButton>
                                      <Info  style={{color:'white'}}/>
                                    </IconButton>
                                  </Tooltip>)
                                  }
                                  height={'160px'}
                                fontColor='black'
                                color1={"#273c75"}
                                color2={"#273c75)"}
                            >

                                 <Box sx={{ height: '90px', width: '90px', borderRadius: '50%', background: 'linear-gradient(#273c75, #192a56)', display: 'flex', placeContent: 'center', placeItems: 'center' }}>
                            <Typography sx={{ fontSize: '2.5rem', color: 'white' }}>
                            {storesUserActivity?.filter((each)=> each.type==='HELP').length}

                            </Typography>
                        </Box>
                                 </CardLayout> */}
                </Grid>

                <Grid item xs={12} md={6} lg={3} xl={2}>
                  <CardComponent
                    title={"Info Tab clicked"}
                    details={"Total number of time info tab was clicked"}
                    icon={
                      <BsFillInfoCircleFill fontSize="inherit" color="white" sx={{ color: "white" }} />
                    }
                    numbers={loading ? <Spinner /> : storesUserActivity?.filter((each) => each.type === 'INFO').length}
                    color1={"#273c75"}
                    color2={"white"}
                    fontColor={'black'}
                    fontColorTitle={'gray'}
                    fontColorDetails={'gray'}
                  />
                  {/* <CardLayout
                                title={"Info Tab clicked"}
                                additional_title_component={
                                    (<Tooltip title={'Slide count'}>
                                    <IconButton>
                                      <Info  style={{color:'white'}}/>
                                    </IconButton>
                                  </Tooltip>)
                                  }
                                  height={'160px'}
                                fontColor='black'
                                color1={"#273c75"}
                                color2={"#273c75)"}
                            >

                                 <Box sx={{ height: '90px', width: '90px', borderRadius: '50%', background: 'linear-gradient(#273c75, #192a56)', display: 'flex', placeContent: 'center', placeItems: 'center' }}>
                            <Typography sx={{ fontSize: '2.5rem', color: 'white' }}>
                            {storesUserActivity?.filter((each)=> each.type==='INFO').length}

                            </Typography>
                        </Box>
                                 </CardLayout> */}
                </Grid>


              </Grid>

            </Box>
          }


          {/* event with No Reg (Stats Activity Information) */}




          {

       <Grid item container justifyContent={"center"} spacing={4} p={2}>
            {geoData?.length > 0 &&
              geoData !== "Error" &&
              geoData !== "No data found" && (
                <>
                  <Grid
                    item
                    xs={12} md={6} lg={6}
                  >
                    <CardComponent
                      title={"Geo Data Map"}
                      details={"The geolocation data only shows the location of users who are sharing their public IP address"}
                      icon={
                        <BsPinMapFill fontSize="inherit" color="white" sx={{ color: "white" }} />
                      }
                      color1={"rgb(45, 52, 54)"}
                      height={'550px'}
                      color2={"white"}
                      fontColor={'black'}
                      fontColorTitle={'black'}
                      fontColorDetails={'gray'}
                    >

                      {loading ? <Spinner /> : <GeoChart geoData={geoData} />}

                    </CardComponent>
                  </Grid>


                  <Grid
                    item
                    xs={12} md={6} lg={6}
                  >
                    <CardComponent
                      title={"User Distribution by City"}
                      details={"The geolocation data only shows the location of users who are sharing their public IP address"}
                      icon={
                        <BsPinMapFill fontSize="inherit" color="white" sx={{ color: "white" }} />
                      }
                      color1={"rgb(45, 52, 54)"}
                      height={'550px'}
                      color2={"white"}
                      fontColor={'black'}
                      fontColorTitle={'black'}
                      fontColorDetails={'gray'}
                    >

                      {loading ? <Spinner /> : (eventWithNoReq ? <GeoChartByCity registrants={registrants} geoData={geoData} /> : <GeoChartByCity registrants={registrants} geoData={geoData} />)}

                    </CardComponent>
                  </Grid>
                </>



              )}

            {    deviceInformation?.length > 0 &&
              deviceInformation !== "Error" &&
              deviceInformation !== "No data found" && (
                <Grid
                  item
                  xs={12} md={6} lg={6}
                >
                  {loading ? (
                    <Spinner />
                  ) : (

                    <CardComponent
                      title={"Platform distribution"}
                      icon={
                        <MdOutlineDevices fontSize="inherit" color="white" sx={{ color: "white" }} />
                      }
                      color1={"rgb(45, 52, 54)"}
                      height={'550px'}
                      color2={"white"}
                      fontColor={'black'}
                      fontColorTitle={'black'}
                      fontColorDetails={'gray'}
                    >

                      <DeviceChartLayout deviceData={deviceInformation} />

                    </CardComponent>
                  )}
                </Grid>
              )   
              
              }


            {
              registrants.length > 0 && <Grid
                item
                xs={12} md={6} lg={6}
              >
                <CardComponent
                  title={"Overall views distribution"}
                  icon={
                    <MdOutlineDevices fontSize="inherit" color="white" sx={{ color: "white" }} />
                  }
                  color1={"rgb(45, 52, 54)"}
                  height={'550px'}
                  color2={"white"}
                  fontColor={'black'}
                  fontColorTitle={'black'}
                  fontColorDetails={'gray'}
                >

                  <OverallpieChart registrants={registrants} />

                </CardComponent>
              </Grid>

            }


          </Grid>
          }




          <Grid item container justifyContent={"center"} spacing={4} p={2}>
            {graphData?.length > 0 &&
              graphData !== "Error" &&
              graphData !== "No data found" && (
                <Grid
                  item
                  xs={12} md={6} lg={12}
                >
                  {loading ? (
                    <Spinner />
                  ) : (
                    <CardComponent
                      title={"Graph"}
                      icon={
                        <VscGraph fontSize="inherit" color="white" sx={{ color: "white" }} />
                      }
                      color1={"rgb(45, 52, 54)"}
                      height={'550px'}
                      color2={"white"}
                      fontColor={'black'}
                      fontColorTitle={'black'}
                      fontColorDetails={'gray'}
                    >

                      <GraphContainer graphData={graphData} />

                    </CardComponent>
                  )}
                </Grid>
              )}
          </Grid>


          <Grid item container justifyContent={"center"} spacing={4} p={2}>

            {regCount && !eventWithNoReq && (

              <Grid
                item
                xs={12} md={6} lg={12}
              >
                {loading ? (
                  <Spinner />
                ) : (

                  <CardComponent
                    title={"Graph (Number of Registrations Pre and Post event)"}

                    icon={
                      <VscGraph fontSize="inherit" color="white" sx={{ color: "white" }} />
                    }
                    color1={"rgb(45, 52, 54)"}
                    height={'500px'}
                    color2={"white"}
                    fontColor={'black'}
                    fontColorTitle={'black'}
                    fontColorDetails={'gray'}
                  >

                    <PrePostReg regCount={regCount} />
                  </CardComponent>
                )}
              </Grid>
            )}



          </Grid>

          <Grid item container justifyContent={"center"} spacing={4} p={2}>

            {graphData?.length > 0 &&
              graphData !== "Error" &&
              graphData !== "No data found" && (


                <Grid
                  item
                  xs={12} md={6} lg={12}
                >
                  {loading ? (
                    <Spinner />
                  ) : (

                    <CardComponent
                      title={"Graph (Number of users watched On Demand)"}

                      icon={
                        <VscGraph fontSize="inherit" color="white" sx={{ color: "white" }} />
                      }
                      color1={"rgb(45, 52, 54)"}
                      height={'auto'}
                      color2={"white"}
                      fontColor={'black'}
                      fontColorTitle={'black'}
                      fontColorDetails={'gray'}
                    >

                      <OnDemandGraphWatched graphData={graphData} />
                    </CardComponent>
                  )}
                </Grid>




              )}



          </Grid>

        </>
      )}
    </div>
  );
};

export default connect()(Stats);
