import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Box, Grid, IconButton, Paper, Typography, Tooltip as TooltipMUI } from '@mui/material'
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import CardLayout from '../CardLayout';
import { Info } from '@mui/icons-material';
import CardComponent from '../../layouts/card/CardComponent';
import { TbMessageCircle2Filled } from "react-icons/tb";
import { BsBugFill } from "react-icons/bs";


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
const Timespent = ({ registrants, totalusers }) => {

    const storesUserActivity = useSelector((state)=> state.stats.userActivityByEvent)

    const usersWithTimeInHours = registrants
    .map((user) => ({
      name: user.name,
      timeSpentInHours:   user.timeSpent / (1000 * 60 * 60 * 60), // Convert milliseconds to hours
      details: user.fields, // User details to display on hover
    }))
    .sort((a, b) => b.timeSpentInHours - a.timeSpentInHours)
    .slice(0, 5);

  // Extract usernames, time spent in hours, and user details for the chart data
  const usernames = usersWithTimeInHours.map((user) => JSON.parse(user.details).email.toLowerCase());
  const timeSpentInHours = usersWithTimeInHours.map((user) => user.timeSpentInHours);
  const userDetails = usersWithTimeInHours.map((user) => JSON.parse(user.details).name);
  console.log('timeSpentInHours',storesUserActivity)

  // Create the chart data
  const chartData = {
    labels: usernames,
    datasets: [
      {
        label: 'Time Spent (in hours)',
        data: timeSpentInHours,
        backgroundColor: '#7f8fa6', // You can customize the color
      },
    ],
  };

  // Customize tooltips to show user details on hover
  const chartOptions = {
    tooltips: {
      callbacks: {
        title: function (tooltipItem) {
          return 'User: ' + usernames[tooltipItem[0].index];
        },
        label: function (tooltipItem) {
          return 'Time Spent: ' + timeSpentInHours[tooltipItem.index] + ' hours';
        },
        afterLabel: function (tooltipItem) {
          return 'Details: ' +  'ss';
        },
      },
    },
  };


    return (
        <Box sx={{height: '400px', background: '', m: 2}}>
            {/* <Typography sx={{ fontSize: '2rem' }}>Overview</Typography> */}
          
                <Grid container display={'flex'} justifyContent={'center'} sx={{ background: '' }} rowGap={1}>


                    <Grid
                        item
                        xs={12}
                        md={5}
                        lg={5}
                        sx={{ justifyContent: "center", alignItems: 'center', background: '', height: '400px', m:1 }}
                    >
                        {/* <Typography sx={{background:'linear-gradient(#273c75, #192a56)', color:'white',textAlign:'center', p:1 }}>
                                Time spent (The 5 users who have spent the most time)
                        </Typography> */}
                        <Box sx={{ height: '100%', width: '100%',background: '', display: 'flex', placeContent: 'center', placeItems: 'center' }}>
                    <CardComponent
     title={" Time spent (The 5 users who have spent the most time)"}
    //  details={`${onDemandUsers.length} users watched the OnDemand event out of ${registrants.length} registered users`}
     icon={
       <TbMessageCircle2Filled fontSize="inherit" color='white' sx={{ color: "white" }} />
     }
    //  numbers={storesUserActivity.filter((each=> each.type ==='QUESTION')).length}
     height={'400px'}
     color1={"rgb(14, 149, 148, 1)"}
                      color2={"white"}
                      fontColor={'black'}
                      fontColorTitle={'gray'}
                      fontColorDetails={'gray'}
   >
                            <Bar data={chartData} options={chartOptions} />


   </CardComponent>
                        {/* <Bar data={chartData} /> */}
                        {/* <Bar data={chartData} options={chartOptions} /> */}

                        </Box>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        md={5}
                        lg={5}
                        sx={{ justifyContent: "center", alignItems: 'center', background: '', height: '400px', m:1 }}
                    >   
                    <Box sx={{ display:'flex',flexDirection:'column', gap:1}}>
                    <CardComponent
     title={" No. of questions asked in the event"}
    //  details={`${onDemandUsers.length} users watched the OnDemand event out of ${registrants.length} registered users`}
     icon={
       <TbMessageCircle2Filled fontSize="inherit" color='white' sx={{ color: "white" }} />
     }
     numbers={storesUserActivity.filter((each=> each.type ==='QUESTION')).length}
     color1={"rgb(14, 149, 148, 1)"}
                      color2={"white"}
                      fontColor={'black'}
                      fontColorTitle={'gray'}
                      fontColorDetails={'gray'}
   />
                  
         

                        <Box sx={{height:'200px', background:'', }}>
        
                                    
                                    {/* <Box>

                         
<Typography sx={{background:'linear-gradient(#273c75, #192a56)', p:1, color:'white', textAlign:'center'}}>
No.of issues (Help)
</Typography>
<Box sx={{background:'', height:'150px', display:'flex', justifyContent:'center', alignItems:'center'}}>
<Typography sx={{fontSize:'2.8rem', p:1, textAlign:'center', color:'white'}}>
  {storesUserActivity.filter((each=> each.type ==='HELP')).length}
</Typography>
</Box>
</Box>  */}


<CardComponent
     title={"No.of issues (Help)"}
    //  details={`${onDemandUsers.length} users watched the OnDemand event out of ${registrants.length} registered users`}
     icon={
       <BsBugFill fontSize="inherit" color='white' sx={{ color: "white" }} />
     }
     numbers= {storesUserActivity.filter((each=> each.type ==='HELP')).length}

     color1={"rgb(14, 149, 148, 1)"}
                      color2={"white"}
                      fontColor={'black'}
                      fontColorTitle={'gray'}
                      fontColorDetails={'gray'}
   />




                        </Box>
                        </Box>
                    </Grid>

         

                </Grid>
         
        </Box>




    )
}

export default Timespent