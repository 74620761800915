import {
  AppBar,
  Box,
  Button,
  CardMedia,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import { connect, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import { Link } from "react-router-dom";
import ClientLink from "../ClientLink";
import DatesInput from "../searchInputs/DatesInput";
import Spinner from "./Spinner";
import { getEventByCompanyID, setGlobalFilter } from "../../redux/action/stats";
import logo from "../../assets/logo.png";

const Header = (props) => {
  let navigate = useNavigate();

  const stats = useSelector((state) => state.stats);

  const { globalFilter, searchText, loading, companyId, companyName } = stats;
  const handleLogout = () => {
    //clear local storage
    localStorage.clear("statsToken");
    // redirect to login page
    navigate("/");
  };

  const handleClickCompanyName = () => {
    props.dispatch(getEventByCompanyID(companyId));
    props.dispatch(setGlobalFilter("company", companyName));
  };

  return (
    <>
      <AppBar
        position="sticky"
        elevation={4}
        style={{
          // position: "fixed",
          top: 0,
          left: 0,
          margin: 0,
          background: "#2d3436",
          // background:
          //   "radial-gradient(circle, rgba(34,193,195,1) 0%, rgba(97,45,253,1) 100%)",
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>

          <div style={{ display: 'flex', alignItems: 'center' }}>

            <CardMedia
              component="img"
              height="40"
              image={logo}
              alt="BRR Logo"
              sx={{ objectFit: "contain", width: "auto", textAlign: "center", m:'0.5rem' }}
            />



            {companyName && (
              <Box component="span">
                <Button variant="contained" onClick={handleClickCompanyName} style={{background:'#BBB8B2', margin:'0.5rem'}}>
                  <Typography
                    sx={{
                      flexGrow: 1,
                      fontSize: {
                        xs: "0.5rem",
                        sm: "0.8rem",
                        md: "0.9rem",
                        lg: "1.2rem",
                      }
                    }}
                  >
                    {companyName}
                  </Typography>
                </Button>
              </Box>
            )}
          </div>





          {/* 
          <CardMedia
            component="img"
            height="40"
            image={logo}
            alt="BRR Logo"
            sx={{ objectFit: "contain", width: "auto", textAlign: "center" }}
          /> */}
          <div style={{ display: 'flex' }}>
            <ClientLink />
            {localStorage.getItem("statsToken") && (
              <Button
                onClick={handleLogout}
                sx={{
                  m:'0.5rem',
                  color: "white",
                  fontSize: {
                    xs: "0.7rem",
                    sm: "0.8rem",
                    md: "1rem",
                    lg: "1rem",
                  },
                  // margin: 1,
                }}
                color="error"
                variant="contained"
              >
                <LogoutIcon />
              </Button>
            )}
          </div>
        </Toolbar>
      </AppBar>

      {/* <Toolbar /> */}
    </>
  );
};

export default connect()(Header);
