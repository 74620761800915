import axios from "axios";
import moment from "moment/moment";

import { SET_LOADING, SET_LOADING_FALSE, SET_LOADING_GRAPH } from "../constants/utilsConstants";
import { StatsConstant } from "../constants/statsConstant";
import { setAlert } from "./alert";
import { getValidDate } from "../../utils/formateDate";
import { each } from "@react-spring/shared";

//Set Global filter

export const setGlobalFilter = (eventOrCompany, _searchText) => {
  return async (dispatch) => {
    if (eventOrCompany === null) {
      // make the stats by id to null
      dispatch({
        type: StatsConstant.RESET_FILTER,
      });
    }

    dispatch({
      type: StatsConstant.SET_GLOBAL_FILTER,
      payload: { eventOrCompany: eventOrCompany, searchText: _searchText },
    });
  };
};

export const setCompanyNameInTitle = (id) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_API}/event/${id}/?token=${process.env.REACT_APP_TOKEN_BASE_API}`
      );
      let companyId = res.data.items[0].companies[0]._id;
      let companyName = res.data.items[0].companies[0].attributes.fullname;

      dispatch({
        type: StatsConstant.SET_COMPANY_ID_NAME,
        payload: { Cid: companyId, Cname: companyName },
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: StatsConstant.SET_ERROR,
        payload: error,
      });
    }
  };
};


export const setUserActivity = (id) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_STATS_API_URL}/event/userActivity/${id}/?token=${process.env.REACT_APP_TOKEN_BASE_API}`
      );

      console.log('USERACTIVITYUSERACTIVITY', res.data)
      if(res.data) {
        dispatch({
          type: StatsConstant.SET_USER_ACTIVITY,
          payload: res.data.userActivity,
        });
    
      }
  
    } catch (error) {
      console.error(error);
      dispatch({
        type: StatsConstant.SET_ERROR,
        payload: error,
      });
    }
  };
};


//Reg count per day (pre reg - post reg)

export const getRegCount = (id) => {
  return async (dispatach) => {

    dispatach({
      type: StatsConstant.SET_LOADING,
      payload: true,
    });
    try {

      const res = await axios.get(
        `${process.env.REACT_APP_STATS_API_URL}/users/getRegistrantsCount/${id}/?token=${process.env.REACT_APP_TOKEN_BASE_API}`
      );

      dispatach({
        type: StatsConstant.GET_REG_COUNT_BY_EVENT,
        payload: { data: res.data },
      });
    } catch (error) {
      console.log(error);
      dispatach({
        type: StatsConstant.SET_ERROR,
        payload: error,
      });
    }
  };
};


export const getAllUserLocationNoReg = (id) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_STATS_API_URL}/event/getUserLocationNoReg/${id}/?token=${process.env.REACT_APP_TOKEN_BASE_API}`
      );

      console.log('getAllUserLocationNoReg', res.data?.location)
      if(res.data) {
        dispatch({
          type: StatsConstant.GET_USER_LOCATION_NO_REG,
          payload:res.data?.location,
        });
    
      }
  
    } catch (error) {
      console.error(error);
      dispatch({
        type: StatsConstant.SET_ERROR,
        payload: error,
      });
    }
  };
};

export const resetStats = () => {
  return async (dispatch) => {
    dispatch({
      type: StatsConstant.RESET_FILTER,
    });
  };
};

export const getEventByCompanyID = (id) => {
  return async (dispatach) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_API}/company/events/${id}/1/100/?token=587cf0af60c7bd46e301b2fc`
      );

      if (res.data.success === false) {
        dispatach(setAlert("No Companies Found", "error", 3000, "7rem"));

        dispatach({
          type: StatsConstant.SET_ERROR,
          payload: "No companies Found",
        });
      }

      if (res.data.success === true) {
        dispatach({
          type: StatsConstant.GET_EVENT_BASED_ON_COMPANY_ID,
          payload: { data: res.data, id: id },
        });
      }
    } catch (error) {
      console.error(error);

      dispatach({
        type: StatsConstant.SET_ERROR,
        payload: error,
      });
    }
  };
};

export const getLiveUsersByEventID = (id) => {
  return async (dispatach) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_STATS_API_URL}/event/fetchLiveUsers/${id}/?token=${process.env.REACT_APP_TOKEN}`
      );

      //Main API - For event details
      const res2 = await axios.get(
        `${process.env.REACT_APP_BASE_API}/event/${id}/?token=${process.env.REACT_APP_TOKEN_BASE_API}`
      );

      if (!res2.data.items[0].registrationRequired) {
        console.log("Event with no Req", id);
        dispatach(getLiveUsersByEventIDNoReq(id));
      } else {
        dispatach({
          type: StatsConstant.GET_RT_STATS_BY_EVENTID,
          payload: res.data,
        });
      }
    } catch (error) {
      console.error(error);
      dispatach({
        type: StatsConstant.SET_ERROR,
        payload: error,
      });
    }
  };
};


export const getLiveUsersByEventID_SocketIO = (users) => {
  return async (dispatach) => {


    const newArray = users?.map((obj) => {
      const { socketID, userID, eventID, ...rest } = obj;
      return rest;
    });
    
    console.log('newArray', newArray);

    dispatach({
      type: StatsConstant.GET_RT_STATS_BY_EVENTID,
      payload:newArray
    });
  };
};





//Search Functionality

// Get all Events
// /event/search/1/10/?orderBy=-releaseTime&token=${process.env.REACT_APP_TOKEN_BASE_API}&idOr=tesco
export const getEventByKeyStroke = (name) => {
  return async (dispatach) => {
    try {
      // https://api.brrmedia.co.uk/company/search/1/5/?name_or_code=tesco&orderBy=name&token=5726ffec6071cb6ec47ac8f9

      const res = await axios.get(
        `${process.env.REACT_APP_BASE_API}/event/search/1/10/?orderBy=-releaseTime&token=${process.env.REACT_APP_TOKEN_BASE_API}&idOr=${name}`
      );

      dispatach({
        type: StatsConstant.GET_EVENT_KEYSTROKE,
        payload: { data: res.data },
      });
    } catch (error) {
      console.log(error);
      dispatach({
        type: StatsConstant.SET_ERROR,
        payload: error,
      });
    }
  };
};

// Get all company details
//company/search/1/5/?name_or_code=tesco&orderBy=name&token=5726ffec6071cb6ec47ac8f9
export const getCompaniesByKeyStroke = (name) => {
  return async (dispatach) => {
    try {
      // https://api.brrmedia.co.uk/company/search/1/5/?name_or_code=tesco&orderBy=name&token=5726ffec6071cb6ec47ac8f9

      const res = await axios.get(
        `${process.env.REACT_APP_BASE_API}/company/search/1/5/?name_or_code=${name}&orderBy=name&token=65ea36d69b9684e6821e7584`
      );

      dispatach({
        type: StatsConstant.GET_COMPANY_KEYSTROKE,
        payload: res.data,
      });
    } catch (error) {
      console.error(error);

      dispatach({
        type: StatsConstant.SET_ERROR,
        payload: error,
      });
    }
  };
};

// Get events based on company
// https://api.brrmedia.co.uk/company/events/531d51203665390db7000c73/1/10/?token=587cf0af60c7bd46e301b2fc

// export const getStatByID = (id) => {

//     return async (dispatach) => {

//         dispatach({
//             type: StatsConstant.SET_LOADING,
//             payload: true
//         })

//         try {

//             const res2 = await axios.get(`${process.env.REACT_APP_BASE_API}/event/${id}/?token=${process.env.REACT_APP_TOKEN_BASE_API}`);

//             let d = new Date(res2.data.items[0].releaseTime).getTime()

//             let currentDate = new Date().getTime()
//             console.log('resres', 'current:', currentDate, 'event date', d)

//                 if (d > currentDate) {
//                     dispatach(setAlert('Stats Not available as event start date is ' + new Date(res2.data.items[0].releaseTime), 'error', 3000))
//                     dispatach({ type: StatsConstant.SET_LOADING_FALSE, payload: false })
//                 }
//             else if (d < currentDate) {

//                 let cD = moment(res2.data.items[0].releaseTime).format('L');
//                 console.log('start', cD, currentDate)
//                 let year = cD.substring(6, 10);
//                 let month = cD.substring(0, 2);
//                 let d1 = cD.substring(3, 5);
//                 let start_date = year + '-' + month + '-' + d1
//                 console.log('start start', start_date)
//                 const res = await axios.get(`${process.env.REACT_APP_STATS_API_URL}/event/${id}/?token=${process.env.REACT_APP_TOKEN}&scope=all&dateFrom=${start_date}&dateTo=today`);

//                 console.log('resres', cD)
//                 let TempGeoData = [];
//                 let countries = res.data.arrGeographCountries
//                 let counts = res.data.arrGeographPerc
//                 var totalLength = countries.length;
//                 var i = 0;

//                 while (totalLength > 0) {
//                     TempGeoData.push([countries[i], counts[i]]);
//                     totalLength--;
//                     i++;
//                 }

//                 dispatach({
//                     type: StatsConstant.SET_GLOBAL_FILTER,
//                     payload: { eventOrCompany: 'event', searchText: res2.data.items[0].title }
//                 })

//                 // Store all stats by ID
//                 dispatach({
//                     type: StatsConstant.GET_ALL_STATS_BY_EVENTID,
//                     payload: { data: res.data, eventStart: res2.data.items[0].releaseTime, eventEnd: res2.data.items[0].endTime, eventId: res2.data.items[0]._id }
//                 })
//                 // Store all GEO DATA for easy access
//                 dispatach({
//                     type: StatsConstant.SET_GEO_DATA,
//                     payload: TempGeoData
//                 })
//             }

//         } catch (error) {
//             console.error(error)
//             dispatach({
//                 type: StatsConstant.SET_ERROR,
//                 payload: error
//             })

//         }

//     }
// }

// get stats by id and date range
// export const getStatByIDAndDates = (id, start, end) => {

//     return async (dispatach) => {

//         dispatach({
//             type: StatsConstant.SET_LOADING,
//             payload: true
//         })

//         try {

//             const res = await axios.get(`${process.env.REACT_APP_STATS_API_URL}/event/${id}/?token=${process.env.REACT_APP_TOKEN}&sort=-uniquePageviews&scope=all&dateFrom=${start}&dateTo=${end}`);
//             const res2 = await axios.get(`${process.env.REACT_APP_BASE_API}/event/${id}/?token=${process.env.REACT_APP_TOKEN_BASE_API}`);

//             dispatach({
//                 type: StatsConstant.SET_GLOBAL_FILTER,
//                 payload: { eventOrCompany: 'event', searchText: res2.data.items[0].title }
//             })
//             let TempGeoData = [];
//             let countries = res.data.arrGeographCountries
//             let counts = res.data.arrGeographPerc
//             var totalLength = countries.length;
//             var i = 0;

//             while (totalLength > 0) {
//                 TempGeoData.push([countries[i], counts[i]]);
//                 totalLength--;
//                 i++;
//             }

//             // Store all stats by ID
//             dispatach({
//                 type: StatsConstant.GET_ALL_STATS_BY_EVENTID,
//                 payload: { data: res.data, eventStart: res2.data.items[0].releaseTime, eventEnd: res2.data.items[0].endTime, eventId: res2.data.items[0]._id }

//             })

//             // Store all GEO DATA for easy access
//             dispatach({
//                 type: StatsConstant.SET_GEO_DATA,
//                 payload: TempGeoData
//             })

//             dispatach(getRegistrantDetailsByDates(id, start, end))

//         } catch (error) {
//             console.error(error)
//             dispatach({
//                 type: StatsConstant.SET_ERROR,
//                 payload: error
//             })

//         }

//     }
// }

// Stats by ID
export const getStatByID = (id) => {
  return async (dispatach) => {
    dispatach({
      type: StatsConstant.SET_LOADING,
      payload: true,
    });


 

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_API}/event/${id}/?token=${process.env.REACT_APP_TOKEN_BASE_API}`
      );
      if(res.data.items[0].source === 'bc') {

        dispatach(resetStats())
        dispatach(setGlobalFilter('event',res.data?.items[0]?.title))
        console.log('res.data.items[0]', res.data.items[0].media.bcVideoId)
          dispatach({
            type:StatsConstant.SET_EVENT_TYPE,
            payload:'bc'
          })

          const res2 = await axios.get(
            `${process.env.REACT_APP_STATS_API_URL}/event/getStatsByPlayerID/${res.data.items[0].media.bcVideoId}/?token=${process.env.REACT_APP_TOKEN_BASE_API}`
          );

            
          dispatach({
            type:StatsConstant.GET_ALL_STATS_BY_EVENTID_BRIGHTCOVE,
            payload:{
              eventID:res.data.items[0]._id,
              geo: res2.data.country,
              device:res2.data.stats_device,
              views:res2.data.views,
              stats_referrer_domain:res2.data.stats_referrer_domain

            }
          })

          console.log('res2.data', res2.data)




      } else {
        try {
          //Stats API - For analytics
          const res = await axios.get(
            `${process.env.REACT_APP_STATS_API_URL}/users/${id}/?token=${process.env.REACT_APP_TOKEN}`
          );
    
          //Main API - For event details
          const res2 = await axios.get(
            `${process.env.REACT_APP_BASE_API}/event/${id}/?token=${process.env.REACT_APP_TOKEN_BASE_API}`
          );
    
          //Stats API - For Geo data
          const res3 = await axios.get(
            `${process.env.REACT_APP_STATS_API_URL}/event/allGeoData/${id}/?token=${process.env.REACT_APP_TOKEN}`
          );
    
          //Stats API - For Device information
          const res4 = await axios.get(
            `${process.env.REACT_APP_STATS_API_URL}/event/allDeviceInformation/${id}/?token=${process.env.REACT_APP_TOKEN}`
          );
    
    
          dispatach({ type: SET_LOADING_GRAPH, payload: true });
          dispatach(getRegCount(id))
    
          //Stats API - For Graph data
          const res5 = await axios.get(
            `${process.env.REACT_APP_STATS_API_URL}/users/dateRange/${id}/?token=${process.env.REACT_APP_TOKEN}`
          );
    
          console.log("Graph data", res5.data);
          if (!res2.data.items[0].registrationRequired) {
            console.log("Event with no Req", id);
            dispatach(getStatByIDNoReq(id));
          } 
          else {
            //  console.log('Geo data', res3.data)
            let d = new Date(res2.data.items[0].releaseTime).getTime();
    
            //Check event was live and stopped
            // liveEnded -0
            // liveStarted - 1
            let liveEnded = res2.data.items[0].liveEnded;
            let liveStarted = res2.data.items[0].liveStarted;
            let event;
            if (liveStarted === "1" && liveEnded === "0") {
              event = "live";
            } else if (liveStarted === "0" && liveEnded === "1") {
              event = "stopped";
            } else if (!res.data.isLive) {
              event = "ondemand";
            }
    
            let currentDate = new Date().getTime();
    
            if (d > currentDate) {
              dispatach(
                setAlert(
                  "Stats Not available as event start date is " +
                    new Date(res2.data.items[0].releaseTime),
                  "error",
                  3000
                )
              );
              dispatach({ type: StatsConstant.SET_LOADING_FALSE, payload: false });
    
              dispatach({
                type: StatsConstant.SET_GLOBAL_FILTER,
                payload: {
                  eventOrCompany: "event",
                  searchText: res.data.eventTitle,
                },
              });
    
              // Store all stats by ID
              dispatach({
                type: StatsConstant.GET_ALL_STATS_BY_EVENTID,
                payload: {
                  data: res.data,
                  eventReleaseTime:res2.data.items[0].releaseTime,
                  eventType:res2.data.items[0].source,
                  eventStartDate: new Date(res2.data.items[0].releaseTime),
                  eventEndDate: new Date(),
                  geo: res3.data,
                  isLive: res.data.isLive,
                  liveStartTime: res.data.EventStartedAt,
                  event: event,
                  graph: res5.data,
                  eventData:res2.data.items[0]
                },
              });
            } else {
              dispatach({
                type: StatsConstant.SET_GLOBAL_FILTER,
                payload: {
                  eventOrCompany: "event",
                  searchText: res.data.eventTitle,
                },
              });
    
              // Store all stats by ID
              dispatach({
                type: StatsConstant.GET_ALL_STATS_BY_EVENTID,
                payload: {
                  data: res.data,
                  eventReleaseTime:res2.data.items[0].releaseTime,

                  eventType:res2.data.items[0].source,
                  eventStartDate: new Date(res2.data.items[0].releaseTime),
                  eventEndDate: new Date(),
                  geo: res3.data,
                  device: res4.data,
                  isLive: res.data.isLive,
                  liveStartTime: res.data.EventStartedAt,
                  event: event,
                  graph: res5.data,
                  eventData:res2.data.items[0]
    
                },
              });
            }
          }
    
        } catch (error) {
          console.error(error);
          dispatach({
            type: StatsConstant.SET_ERROR,
            payload: error,
          });
        }
      }
      
    } catch (error) {
        console.log('Error', error)
    }

  };
};

// Stats by ID
export const getStatByIDAndDates = (id, start, end) => {
  return async (dispatach) => {
    dispatach({
      type: StatsConstant.SET_LOADING,
      payload: true,
    });

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_STATS_API_URL}/users/${id}/?token=${process.env.REACT_APP_TOKEN}&sDate=${start}&eDate=${end}`
      );

      const res2 = await axios.get(
        `${process.env.REACT_APP_BASE_API}/event/${id}/?token=${process.env.REACT_APP_TOKEN_BASE_API}`
      );

          //Stats API - For Geo data
    const res3 = await axios.get(
        `${process.env.REACT_APP_STATS_API_URL}/event/allGeoData/${id}/?token=${process.env.REACT_APP_TOKEN}`
      );

      //Stats API - For Device information
      const res4 = await axios.get(
        `${process.env.REACT_APP_STATS_API_URL}/event/allDeviceInformation/${id}/?token=${process.env.REACT_APP_TOKEN}`
      );


      dispatach({ type: SET_LOADING_GRAPH, payload: true });


      //Stats API - For Graph data
      const res5 = await axios.get(
        `${process.env.REACT_APP_STATS_API_URL}/users/dateRange/${id}/?token=${process.env.REACT_APP_TOKEN}&sDate=${start}&eDate=${end}`
      );


      let d = new Date(res2.data.items[0].releaseTime).getTime();

      let currentDate = new Date().getTime();

      if (d > currentDate) {
        dispatach(
          setAlert(
            "Stats Not available as event start date is " +
              new Date(res2.data.items[0].releaseTime),
            "error",
            3000
          )
        );
        dispatach({ type: StatsConstant.SET_LOADING_FALSE, payload: false });
      } else {

      console.log("Graph data", res5.data);
      if (!res2.data.items[0].registrationRequired) {
        console.log("Event with no Req", id);
        dispatach(getStatByIDNoReq(id));
      } else {
        //  console.log('Geo data', res3.data)
        let d = new Date(res2.data.items[0].releaseTime).getTime();

        //Check event was live and stopped
        // liveEnded -0
        // liveStarted - 1
        let liveEnded = res2.data.items[0].liveEnded;
        let liveStarted = res2.data.items[0].liveStarted;
        let event;
        if (liveStarted === "1" && liveEnded === "0") {
          event = "live";
        } else if (liveStarted === "0" && liveEnded === "1") {
          event = "stopped";
        } else if (!res.data.isLive) {
          event = "ondemand";
        }

        let currentDate = new Date().getTime();

        if (d > currentDate) {
          dispatach(
            setAlert(
              "Stats Not available as event start date is " +
                new Date(res2.data.items[0].releaseTime),
              "error",
              3000
            )
          );
          dispatach({ type: StatsConstant.SET_LOADING_FALSE, payload: false });

          dispatach({
            type: StatsConstant.SET_GLOBAL_FILTER,
            payload: {
              eventOrCompany: "event",
              searchText: res.data.eventTitle,
            },
          });

          // Store all stats by ID
          dispatach({
            type: StatsConstant.GET_ALL_STATS_BY_EVENTID,
            payload: {
              data: res.data,
              eventReleaseTime:res2.data.items[0].releaseTime,

              eventType:res2.data.items[0].source,
              eventStartDate: new Date(res2.data.items[0].releaseTime),
              eventEndDate: new Date(),
              geo: res3.data,
              isLive: res.data.isLive,
              liveStartTime: res.data.EventStartedAt,
              event: event,
              graph: res5.data,
              dateRangeStart:start,
              dateRangeEnd:end
            },
          });
        } else {
          dispatach({
            type: StatsConstant.SET_GLOBAL_FILTER,
            payload: {
              eventOrCompany: "event",
              searchText: res.data.eventTitle,
            },
          });

          // Store all stats by ID
          dispatach({
            type: StatsConstant.GET_ALL_STATS_BY_EVENTID,
            payload: {
              data: res.data,
              eventReleaseTime:res2.data.items[0].releaseTime,

              eventType:res2.data.items[0].source,
              eventStartDate: new Date(res2.data.items[0].releaseTime),
              eventEndDate: new Date(),
              geo: res3.data,
              device: res4.data,
              isLive: res.data.isLive,
              liveStartTime: res.data.EventStartedAt,
              event: event,
              graph: res5.data,
              dateRangeStart:start,
              dateRangeEnd:end

            },
          });
        }
      }
      }

      // dispatach({
      //     type: StatsConstant.SET_GLOBAL_FILTER,
      //     payload: { eventOrCompany: 'event', searchText: res.data.eventTitle }
      // })

      // // Store all stats by ID
      // dispatach({
      //     type: StatsConstant.GET_ALL_STATS_BY_EVENTID,
      //     payload: res.data
      // })
    } catch (error) {
      console.error(error);
      dispatach({
        type: StatsConstant.SET_ERROR,
        payload: error,
      });
    }
  };
};

// Stats for Event with NoReq

//NoReq Live user
export const getLiveUsersByEventIDNoReq = (id) => {
  return async (dispatach) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_STATS_API_URL}/event/fetchLiveUsersNoReq/${id}/?token=${process.env.REACT_APP_TOKEN}`
      );
      dispatach({
        type: StatsConstant.GET_RT_STATS_BY_EVENTID_NO_REG,
        payload: res.data,
      });
    } catch (error) {
      console.error(error);
      dispatach({
        type: StatsConstant.SET_ERROR,
        payload: error,
      });
    }
  };
};

//NoReq StatsById
export const getStatByIDNoReq = (id) => {
  return async (dispatach) => {
    dispatach({
      type: StatsConstant.SET_LOADING,
      payload: true,
    });

    try {
      //Stats API - For analytics
      const res = await axios.get(
        `${process.env.REACT_APP_STATS_API_URL}/users/noReq/${id}/?token=${process.env.REACT_APP_TOKEN}`
      );

      //Main API - For event details
      const res2 = await axios.get(
        `${process.env.REACT_APP_BASE_API}/event/${id}/?token=${process.env.REACT_APP_TOKEN_BASE_API}`
      );

      //Stats API - For Geo data
      const res3 = await axios.get(
        `${process.env.REACT_APP_STATS_API_URL}/event/allGeoDataNoReq/${id}/?token=${process.env.REACT_APP_TOKEN}`
      );

      //Stats API - For Device information
      const res4 = await axios.get(
        `${process.env.REACT_APP_STATS_API_URL}/event/allDeviceInformationNoReq/${id}/?token=${process.env.REACT_APP_TOKEN}`
      );

      //  console.log('Geo data', res3.data)
      let d = new Date(res2.data.items[0].releaseTime).getTime();

      //Check event was live and stopped
      // liveEnded -0
      // liveStarted - 1
      let liveEnded = res2.data.items[0].liveEnded;
      let liveStarted = res2.data.items[0].liveStarted;
      let event;
      if (liveStarted === "1" && liveEnded === "0") {
        event = "live";
      } else if (liveStarted === "0" && liveEnded === "1") {
        event = "stopped";
      } else if (!res.data.isLive) {
        event = "ondemand";
      }

      let currentDate = new Date().getTime();

      if (d > currentDate) {
        dispatach(
          setAlert(
            "Stats Not available as event start date is " +
              new Date(res2.data.items[0].releaseTime),
            "error",
            3000
          )
        );
        dispatach({ type: StatsConstant.SET_LOADING_FALSE, payload: false });

        dispatach({
          type: StatsConstant.SET_GLOBAL_FILTER,
          payload: { eventOrCompany: "event", searchText: res.data.eventTitle },
        });

        // Store all stats by ID
        dispatach({
          type: StatsConstant.GET_ALL_STATS_BY_EVENTID_NO_REG,
          payload: {
            data: res.data,
            eventStartDate: new Date(res2.data.items[0].releaseTime),
            eventEndDate: new Date(),
            geo: res3.data,
            isLive: res.data.isLive,
            liveStartTime: res.data.EventStartedAt,
            event: event,
            eventData:res2.data.items[0]
          },
        });
      } else {
        dispatach({
          type: StatsConstant.SET_GLOBAL_FILTER,
          payload: { eventOrCompany: "event", searchText: res.data.eventTitle },
        });

        // Store all stats by ID
        dispatach({
          type: StatsConstant.GET_ALL_STATS_BY_EVENTID_NO_REG,
          payload: {
            data: res.data,
            eventStartDate: new Date(res2.data.items[0].releaseTime),
            eventEndDate: new Date(),
            geo: res3.data,
            device: res4.data,
            isLive: res.data.isLive,
            liveStartTime: res.data.EventStartedAt,
            event: event,
            eventData:res2.data.items[0]
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatach({
        type: StatsConstant.SET_ERROR,
        payload: error,
      });
    }
  };
};

//NoReq StatsByIDandDates

export const setLoading = () => (dispatch) =>
  dispatch({
    type: SET_LOADING,
    payload: true,
  });



export const setLoadingForGraph = () => (dispatch) =>
  dispatch({
    type: SET_LOADING_GRAPH,
    payload: true,
  });
