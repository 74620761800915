import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { connect, useSelector } from "react-redux";
import { getLiveUsersByEventID, getRegistrantDetails, getStatByID, setCompanyNameInTitle, setGlobalFilter } from "../redux/action/stats";
import Spinner from "./ui/Spinner";
import moment from "moment/moment";

import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';



function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}


const EventByCompany = (props) => {
  const stats = useSelector((state) => state.stats);

  const { loading, companies, globalFilter, events, autoCompleteLoading } =
    stats;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - events.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (id) => {
    //event ID
    // Get analytics baed on event ID
    // Set global filter to event to display events
    props.dispatch(getStatByID(id))
    props.dispatch(setCompanyNameInTitle(id))
    // props.dispatch(getLiveUsersByEventID(id))
    // props.dispatch(setGlobalFilter('event',id))
    // props.dispatch(getRegistrantDetails(id))
  }

  return (
    <>  {
      loading ? (<Spinner />) : (


        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }} m={1}>

          <Grid container>

            <Grid item xs={12} md={12} lg={12} sx={{ display: 'flex', justifyContent: 'center' }}>


              <TableContainer component={Paper} sx={{ width: '80%' }}>
                <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                  <TableHead>

                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell></TableCell>

                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {
                      // events.map

                      (rowsPerPage > 0
                        ? events.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : events
                      ).map((eachEvent => (
                        <TableRow>
                          <TableCell>

                            {moment(eachEvent.releaseTime).format("L")}
                          </TableCell>
                          <TableCell>
                            {eachEvent.title}
                          </TableCell>
                          <TableCell>
                            <Button onClick={() => handleClick(eachEvent._id)}>
                              View Analytics
                            </Button>
                          </TableCell>
                        </TableRow>
                      )))
                    }
                  </TableBody>

                  <TablePagination
                    rowsPerPageOptions={[15, 25, 35, { label: 'All', value: -1 }]}
                    colSpan={3}
                    count={events.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'rows per page',
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                  <TableFooter>
                    <TableRow>

                    </TableRow>

                  </TableFooter>

                </Table>



              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      )
    }


      {/* {loading ? (
        <Spinner />
      ) : (
        events.map((eachEvent) => (
          <>
            <li>{eachEvent.title}</li>
            <li>{eachEvent.description}</li>
            <li>{eachEvent.releaseTime}</li>
            <li>live {eachEvent.isLive}</li>
          </>
        ))
      )} */}
    </>
  );
};

export default connect()(EventByCompany);
