import React from "react";
import { useEffect } from "react";
import { connect, useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useState } from "react";
import { 
  getLiveUsersByEventID,
  getRegistrantDetails,
  getStatByID,
} from "../redux/action/stats";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import Spinner from "./ui/Spinner";
import CardLayout from "./patials/CardLayout";
import { Accordion, AccordionSummary, AccordionDetails,AppBar, CardMedia, Grid, Stack, Toolbar, Typography, Card, styled, Button } from "@mui/material";
import CardLayoutRegistrantModal from "./patials/CardLayoutRegistrantModal";
import GeoChart from "./patials/GeoChart";
import CardLayoutInteraction from "./patials/CardLayoutInteraction";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import GroupIcon from "@mui/icons-material/Group";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import PersonIcon from "@mui/icons-material/Person";
import TourIcon from "@mui/icons-material/Tour";
import CardLayoutLiveUsers from "./patials/CardLayoutLiveUsers";
import DeviceChartLayout from "./patials/DeviceChart";
import logo from "../assets/logo.png";
import CardLayoutLiveUsersNoReg from "./patials/CardLayoutLiveUsersNoReg";
import CardLayoutInteractionNoReg from "./patials/CardLayoutInteractionNoReg";
import CardLayoutRegistrantModalNoReg from "./patials/CardLayoutRegistrantModalNoReg";
import CardLayoutLive from "./patials/CardLayoutLive";
import GraphContainer from "./patials/GraphContainer";
import { Expand } from "@mui/icons-material";
import NoDataCardLayout from "./patials/NoDataCardLayout";


const useStyles = makeStyles((theme) => ({
  rootContainer: {
    flexGrow: 1,
  },
}));




const CustomButtom = styled(Button)(({ theme }) => ({
  
    "&.active": {
    backgroundColor:'black',
  }

}))


const StatsForCP = (props) => {
  let { clientToken } = useParams();
  let clientJWT = jwt_decode(clientToken);

  const [eventFound, setEventFound] = useState(false);

  const stats_by_id = useSelector((state) => state.stats.statsByID);
  const loading = useSelector((state) => state.stats.loading);

  // RT
  const rt_Users = useSelector((state) => state.stats.rtActiveUsers);
  const rtTotalActiveUsers = useSelector(
    (state) => state.stats.rtTotalActiveUsers
  );
  const rtLoading = useSelector((state) => state.stats.rtLoading);
  const rtTimeStamp = useSelector((state) => state.stats.rtTimeStamp);

  // Registrants
  const registrants = useSelector((state) => state.stats.registrant);
  const totalLiveUsers = useSelector((state) => state.stats.totalLiveUsers);
  const totalOnDemandUsers = useSelector(
    (state) => state.stats.totalOnDemandUsers
  );
  const avgTimeOnPage = useSelector((state) => state.stats.averageTimeOnPage);
  //Event
  const event_id = useSelector((state) => state.stats.event_ID);
  const eventStartTime = useSelector((state) => state.stats.eventStartTime);
  const eventEndTime = useSelector((state) => state.stats.eventEndTime);
  const geoData = useSelector((state) => state.stats.geoData);
  const deviceInformation = useSelector(
    (state) => state.stats.deviceInformation
  );
  const isLive = useSelector((state) => state.stats.isLive);
  const eventWithNoReq = useSelector((state) => state.stats.eventNoReg);
  const graphData = useSelector((state) => state.stats.graphData);


  const [activeLayout, setActiveLayout] = useState('graph')




  useEffect(() => {
    console.log("JWT WITH DATA", clientJWT);
    props.dispatch(getStatByID(clientJWT.eventID));
    // props.dispatch(getRegistrantDetails(clientJWT.eventID));
  }, []);

  // const [geoData, setGeoData] = useState(null);
  const pollingRT = () => {
    props.dispatch(getLiveUsersByEventID(clientJWT.eventID));
  };

  useEffect(() => {
    const timer = setInterval(pollingRT, 30000);
    return () => clearInterval(timer);
  }, []);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // useEffect(() => {
  //   if (!loading) {
  //     let TempGeoData = [];
  //     let countries = stats_by_id.arrGeographCountries;
  //     let counts = stats_by_id.arrGeographCount;

  //     var totalLength = countries.length;
  //     var i = 0;
  //     while (totalLength > 0) {
  //       TempGeoData.push([countries[i], counts[i]]);
  //       totalLength--;
  //       i++;
  //     }
  //     setGeoData(TempGeoData);
  //   }
  // }, []);

  useEffect(() => {
    // fetch event by id
    if (clientJWT) {
      // console.log('token')
    }
  }, []);
  const classes = useStyles();


  const setLayout = (data)=>{
      setActiveLayout(data)
  }

  return (
    <div className={classes.rootContainer}>
      {loading ? (
        <Box sx={{ display: "flex", alignItems: "center", height: "30vh" }}>
          <Spinner />
        </Box>
      ) : (
        <>


          <Grid  justifyContent={"center"} spacing={2} p={1} sx={{display:'flex', justifyContent:'center', height:'calc(100vh - 100px)', alignContent:'center', alignItems:'center'}}>


          {registrants?.length > 0 ? (
           <>
                     <Grid  item xs={6} md={6} lg={6} sx={{width:'40%', alignContent:'center'}}>

  
<>
<Grid item xs={6} md={6} lg={6} spacing={2} m={1} sx={{display:'flex', justifyContent:'center'}}>
  <Stack direction={'row'} spacing={2}>

  {rt_Users?.length > 0 && isLive ? (
    <>
      {/* Registrant Modal  */}

      {eventWithNoReq ? (
        <CardLayoutLiveUsersNoReg
        cpLayout={true}
          icon={
            <LiveTvIcon
              fontSize="large"
              sx={{ color: "white" }}
            />
          }
          registrants={rt_Users}
          eventStartDate={eventStartTime}
          eventEndDate={eventEndTime}
          title={"Users now"}
          live={isLive}
          numbers={loading ? <Spinner /> : rtTotalActiveUsers}
          color1={"rgba(242, 84, 45, 1)"}
          color2={"rgba(242, 84, 45, 0.8)"}
        />
      ) : (
        <CardLayoutLiveUsers
        cpLayout={true}
          icon={
            <LiveTvIcon
              fontSize="large"
              sx={{ color: "white" }}
            />
          }
          registrants={rt_Users}
          eventStartDate={eventStartTime}
          eventEndDate={eventEndTime}
          title={"Users now"}
          live={isLive}
          numbers={loading ? <Spinner /> : rtTotalActiveUsers}
          color1={"rgba(242, 84, 45, 1)"}
          color2={"rgba(242, 84, 45, 0.8)"}
        />
      )}
      {/* Registrant Modal  */}
    </>
  ) : (
    <>
      <CardLayoutLive
      cpLayout={true}
        title={"Users now"}
        numbers={"-"}
        color1={"rgba(242, 84, 45, 1)"}
        color2={"rgba(242, 84, 45, 0.8)"}
      >
        <p style={{ color: "#fbc531", textAlign: "center" }}>
          {/* {rtTimeStamp} */}
        </p>
      </CardLayoutLive>


  
    </>
  )}

{eventWithNoReq ? (
    <CardLayoutRegistrantModalNoReg
    cpLayout={true}
      registrants={registrants}
      eventStartDate={eventStartTime}
      eventEndDate={eventEndTime}
      title={"Total Views"}
      numbers={loading ? <Spinner /> : registrants?.length}
      color1={"#2d3436"}
      color2={"#636e72"}
    />
  ) : (
    <CardLayoutRegistrantModal
    cpLayout={true}
      registrants={registrants}
      eventStartDate={eventStartTime}
      eventEndDate={eventEndTime}
      title={"Total Registrants"}
      numbers={loading ? <Spinner /> : registrants?.length}
      color1={"#2d3436"}
      color2={"#636e72"}
    />
  )}
  </Stack>

</Grid>
</>




{clientJWT.liveUsers === true ? (
<Grid item xs={6} md={6} lg={6} spacing={2} m={1} sx={{display:'flex', justifyContent:'center'}}>
  <Stack direction={'row'} spacing={2}>
    
  <CardLayout
   cpLayout={true}
    title={"On Demand users"}
    details={"Users who have watched on demand"}
    icon={
      <PersonIcon fontSize="large" sx={{ color: "white" }} />
    }
    numbers={loading ? <Spinner /> : totalOnDemandUsers}
    color1={"rgb(14, 149, 148, 1)"}
    color2={"rgb(14, 149, 148, 0.8)"}
  />


<CardLayout
cpLayout={true}
    title={"Live Users"}
    icon={
      <PersonIcon fontSize="large" sx={{ color: "white" }} />
    }
    details={"Users who have watched live"}
    numbers={loading ? <Spinner /> : totalLiveUsers}
    color1={"rgb(14, 149, 148, 1)"}
    color2={"rgb(14, 149, 148, 0.8)"}
    />
    </Stack>
  
</Grid>
) : (
<></>
)}

{clientJWT.liveUsers === true ? (
<Grid item xs={6} md={6} lg={6} spacing={2} m={1} sx={{display:'flex', justifyContent:'center'}}>
  <Stack direction={'row'} spacing={2}>
  <CardLayout
  cpLayout={true}
    title={"Total users"}
    details={"(Live + On demand)"}
    icon={
      <GroupIcon fontSize="large" sx={{ color: "white" }} />
    }
    numbers={totalLiveUsers + totalOnDemandUsers}
    color1={"rgba(18, 115, 117,1)"}
    color2={"rgba(18, 115, 117,0.8)"}
  />


 
      <CardLayout
       cpLayout={true}
        title={"Average Time"}
        details={"Average time spent on event"}
        timeFormat={true}
        numbers={avgTimeOnPage}
        icon={
          <AccessTimeFilledIcon
            fontSize="large"
            sx={{ color: "white" }}
          />
        }
        color1={"rgba(242, 84, 45, 1)"}
        color2={"rgba(242, 84, 45, 0.8)"}
      />
   

    </Stack>
  
</Grid>
) : (
<></>
)}
</Grid>



<Grid item xs={6} md={6} lg={6} sx={{width:'60%', alignContent:'center'}} >
<Stack direction={'row'} justifyContent='center' spacing={1}>
<Button variant='contaied' onClick={()=>setLayout('graph')} style={{backgroundColor:activeLayout === 'graph' && '#2d3436', color:activeLayout === 'graph' && 'white'}}>
Graph
</Button>
<Button variant='contaied' onClick={()=>setLayout('platform')} style={{backgroundColor:activeLayout === 'platform' && '#2d3436', color:activeLayout === 'platform' && 'white'}}>
Platform
</Button>
<Button variant='contaied' onClick={()=>setLayout('geo')} style={{backgroundColor:activeLayout === 'geo' && '#2d3436', color:activeLayout === 'geo' && 'white'}}>
Geo
</Button>
</Stack>
{clientJWT.graph === true ? (
  <>
  
    <Grid
      item
      container
      justifyContent={"center"}
      spacing={2}
      p={2}
    >



{
activeLayout === 'graph' &&
graphData?.length > 0 &&
        graphData !== "Error" &&
        graphData !== "No data found" && (
          <Grid
            item
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
               
              },
            }}
          >
            {loading ? (
              <Spinner />
            ) : (
              // <GraphLayout graphData={graphData} />
              <GraphContainer graphData={graphData} />
            )}
          </Grid>
        )}



    </Grid>
  </>
) : (
  <></>
)}



{activeLayout === 'geo' && clientJWT.geoData === true ? (
  <>
                 <Grid
      item
      container
      justifyContent={"center"}
      spacing={2}
      p={2}
    >
    {geoData?.length > 0 &&
      geoData !== "Error" &&
      geoData !== "No data found" && (
        <Grid
          item
          sx={{
            width: {
              xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
                
            },
          }}
        >
          {loading ? (
            <Spinner />
          ) : (
            <GeoChart geoData={geoData} />
          )}
        </Grid>
      )}
      </Grid>
  </>
) : (
  <></>
)}




{activeLayout === 'platform' && clientJWT.platform === true ? (
  <>
            <Grid
      item
      container
      justifyContent={"center"}
      spacing={2}
      p={2}
    >
    {deviceInformation?.length > 0 &&
      deviceInformation !== "Error" &&
      deviceInformation !== "No data found" && (
        <Grid
          item
          sx={{
            width: {
              xs: "90%",
              sm: "90%",
              md: "100%",
              lg: "100%",
            },
          }}
        >
          {loading ? (
            <Spinner />
          ) : (
            <DeviceChartLayout
              deviceData={deviceInformation}
            />
          )}
        </Grid>
      )}
      </Grid>
  </>
) : (
  <></>
)}

</Grid>

           </>
          ) : (

              <Grid item xs={12} md={6} lg={3}>
                <NoDataCardLayout
                cpLayout={true}
                  title={"Stats data is not available"}
                  color1={"#fa983a"}
                  color2={"#ffeaa7"}
                />
              </Grid>
            
              )}










</Grid>


        
        </>
      )}
    </div>
  );
};
export default connect()(StatsForCP);
