import React, { useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

const BarGraph = (regCount) => {
    const eventSDate = useSelector((state) => state.stats.eventStartTime);

    
    const preRegData = {

        "2023-08-15": 1,
        "2023-09-07": 1,
        "2023-09-13": 1,
        "2023-09-15": 1,
        "2023-09-18": 56,
        "2023-09-19": 11,
        "2023-09-20": 12,
        "2023-09-21": 96
      };
    
      const postRegData = {
        "2023-09-21": 60,
        "2023-09-22": 5,
        "2023-09-25": 3,
        "2023-09-26": 5,
        "2023-09-27": 1,
        "2023-09-28": 5,
        "2023-09-29": 6,
        "2023-09-30": 1,
        "2023-10-02": 2,
        "2023-10-03": 1,
        "2023-10-04": 1,
        "2023-10-05": 3,
        "2023-10-06": 1,
        "2023-10-07": 2,
        "2023-10-10": 2,
        "2023-10-11": 1,
        "2023-10-12": 1,
        "2023-10-15": 1,
        "2023-10-16": 3,
        "2023-10-17": 1,
        "2023-10-18": 1,
        "2023-10-19": 1,
        "2023-10-20": 4,
        "2023-10-24": 4,
        "2023-10-25": 1,
        "2023-10-28": 1,
        "2023-10-30": 2,
        "2023-11-02": 2,
        "2023-11-06": 2,
        "2023-11-07": 2,
        "2023-11-09": 3,
        "2023-11-10": 1,
        "2023-11-11": 1,
        "2023-11-12": 1,
        "2023-11-15": 2,
        "2023-11-16": 1,
        "2023-11-18": 1
    }
    console.log('regCount',regCount.regCount, new Date(eventSDate).toLocaleDateString()
    .split('/')
    .reverse()
    .join('-'))
    
    const preRegKeys =regCount.regCount?.preRegCountByDay
    ? Object.keys(regCount.regCount.preRegCountByDay)
    : [];
    const postRegKeys = regCount.regCount?.postRegCountByDay
    ? Object.keys(regCount.regCount.postRegCountByDay)
    : [];
    const allDates =   Array.from(new Set([...preRegKeys, ...postRegKeys]));

  
    const hasPreRegData = preRegKeys.length > 0;
    const hasPostRegData = postRegKeys.length > 0;
    const chartRef = useRef(null);
    const datasets = [];
  
    if (hasPreRegData) {
      datasets.push({
        label: 'Pre-Reg Users',
        backgroundColor: 'rgba(75,192,192,0.5)',
        data: allDates.map(date => regCount.regCount.preRegCountByDay[date] || 0),
      });
    }
  
    // datasets.push({
    //     label: 'Event Date',
    //     backgroundColor: 'red', // Color for the event date
    //     data: Array(allDates.length).fill(0), // Placeholder value for event date
    //     // type: 'line',
    // });
  
    if (hasPostRegData) {
      datasets.push({
        label: 'Post-Reg Users',
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
        data: allDates.map(date => regCount.regCount.postRegCountByDay[date] || 0),
      });
    }
  
    const data = {
        labels: allDates.map(date => date === new Date(eventSDate).toLocaleDateString()
        .split('/')
        .reverse()
        .join('-') ? new Date(date).toUTCString().slice(0,-12)    + ' (Event)' : new Date(date).toUTCString().slice(0,-12)),
        datasets: datasets,
    };

 
  
    const options = {
        responsive:true,
        maintainAspectRatio: false,
      scales: {
        x: { stacked: true },
        y: { stacked: true },
      },
    };
  return (
    <div>
      {/* <h2>User Counts by Day</h2> */}
      <Bar   height="400px" data={data} options={options} />
    </div>
  );
};

export default BarGraph;
