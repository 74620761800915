import React from "react";
import { useSelector } from "react-redux";
import useUserLists from "./hook/useUserlist";
import { DataGrid } from "@mui/x-data-grid";
import useDynamicColumns from "./hook/useDynamicColumns";
import MyDialog from "./LiveUserDetails";


const Liveusers = ({activity})=>{

    const registrants = useSelector((state=> state.stats.registrant))
    const { liveUsers, onDemandUsers } = useUserLists(registrants);

    // const columns = [
    //     { field: 'fields.name', headerName: 'Name', flex: 1 },
    //     { field: 'fields.email', headerName: 'Email', flex: 1 },
    //     { field: 'loggedInTime', headerName: 'Logged In Time', flex: 1 },
    //     { field: 'timeSpent', headerName: 'Time Spent (ms)', flex: 1 },
    //     { field: 'isVerified', headerName: 'Verified', flex: 1 },
    //     { field: 'watchedLive', headerName: 'Watched Live', flex: 1 },
    //     { field: 'watchedOnDemand', headerName: 'Watched On Demand', flex: 1 },
    //     { field: 'liveVisits', headerName: 'Live Visits', flex: 2, renderCell: (params) => (
    //       <div style={{ whiteSpace: 'nowrap' }}>
    //         {params.row.liveVisits.map((visit, index) => (
    //           <div key={index}>
    //             <strong>Start Time:</strong> {visit.startTime}
    //             <br />
    //             <strong>End Time:</strong> {visit.endTime}
    //           </div>
    //         ))}
    //       </div>
    //     )},
    //   ];


    //   const filteredData = liveUsers.map((item) => {
    //     const {
    //       session,
    //       force_disclaimer,
    //       loggedOutTime,
    //       ...filteredItem
    //     } = item;
    //     return {
    //         id: item._id, // Set the id to a unique identifier
    //         ...filteredItem,
    //       };
    //   });


    const { columns, dialog } = useDynamicColumns(liveUsers);


    console.log('LiveusersLiveusers', dialog)

    return (
        <div style={{ height: '70vh', width: '100%' }}>
   {

liveUsers &&  
    <>
<DataGrid
        rows={liveUsers}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[5, 10, 20]}
        getRowId={(Liveusers) => Liveusers._id} // Specify the _id as the unique identifier
        disableRowSelectionOnClick={true}
        disableSelectionOnClick={true}
      />
            <MyDialog open={dialog.open} handleClose={dialog.handleClose} content={dialog.content} activity={activity} userID={dialog.userID} email={dialog.email}/>

    </>
   }
   
    </div>
    )
}

export default Liveusers;