import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';
  import { Box, Card, CardContent, Typography } from "@mui/material";


  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );




  const GraphLayout = ({graphData})=>{

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: '',
        },
      },
    };

    
  const data = {
      labels:graphData.map((each) =>  new Date(each.date).toUTCString().slice(0,-12) ),
      datasets: [
        {
          label: 'Live Users',
          data: graphData.map((each) => each.liveUsers),
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
          label: 'OnDemand Views',
          data: graphData.map((each) => each.onDemandUsers),
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
        {
          label: 'Total Views',
          data: graphData.map((each) => each.totalViews),
          borderColor: '#2ecc71',
          backgroundColor: '#2ecc71',
        },
        {
          label: 'Registration',
          data: graphData.map((each) => each.totalRegistration),
          borderColor: '#34495e',
          backgroundColor: '#34495e',
        }
      ],
    };

    return (
  

          <Line options={options} data={data} height='300px' width={'100%'} />

   
    )   
  }

  export default GraphLayout;