import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Chart } from "react-google-charts";
  import { Box, Card, CardContent, Typography } from "@mui/material";


  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );




  const OnDemandGraphWatched = ({graphData})=>{
    
    let data = [
        [
            "Date", "On Demand Users"
        ]
]





graphData.map((each)=>{
    data.push([  new Date(each.date).toUTCString().slice(0,-12), each.onDemandUsers])
})

console.log('-------------bar', data)


const options = {
      responsive: true,
      maintainAspectRatio: false,
      colors: ["#006e87", "#00a0bd", "#00bcd4", "#87e6ed"],
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: '',
        },
      },
      
    };

    


    return (
  

        <Chart
        chartType="Bar"
        width="100%"
        height="300px"
        data={data}
        options={options}
      />

   
    )   
  }

  export default OnDemandGraphWatched;