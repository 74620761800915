import {
  Accordion,
  Box,
  Grid,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Paper,
} from "@mui/material";

import { v4 as uuidv4 } from "uuid";
import moment from "moment/moment";
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { Backdrop, Card, CardContent, Fade, Modal } from "@mui/material";
import { CSVLink, CSVDownload } from "react-csv";

import { CopyToClipboard } from "react-copy-to-clipboard";

import React, { useEffect, useRef } from "react";
import { connect, useSelector } from "react-redux";
// import SearchByCompany from "./searchInputs/SearchByCompany";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import SearchByCompanyID from "./searchInputs/SearchByCompanyID";
import AutoCompleteCompanyAndEvent from "./searchInputs/AutocompleteSearch";
import DatesInput from "./searchInputs/DatesInput";
import SearchButton from "./searchInputs/SearchButton";
import Spinner from "./ui/Spinner";
import { useState } from "react";
import { CheckBox } from "@mui/icons-material";
import ReplayIcon from "@mui/icons-material/Replay";
import {
  getEventByCompanyID,
  getEventByKeyStroke,
  getLiveUsersByEventID,
  getRegistrantDetails,
  getStatByID,
  resetStats,
} from "../redux/action/stats";
import * as XLSX from 'xlsx';
import { getValidDate } from "../utils/formateDate";
import axios from "axios";
import { setAlert } from "../redux/action/alert";
const sign = require("jwt-encode");
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "calc(100% - 200px)",
  // height: "calc(100vh - 50vh)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  // overflowY: "scroll",
  p: 4,
};

const SearchFunction = (props) => {
  const stats = useSelector((state) => state.stats);
  const registrants_all = useSelector((state) => state.stats.registrant);

  const tableRef = useRef(null);
  const eventType = useSelector((state) => state.stats.eventType);
  const [loadingExportToCSV, setLoadingExportToCSV] = useState(false)
  const [loadingExportToCSV_ERROR, setLoadingExportToCSV_ERROR] = useState(false)

  const {
    globalFilter,
    searchText,
    loading,
    company_id,
    event_ID,
    statsByID,
    registrant,
    companyName,
    companyId,
    totalLiveUsers,
    totalOnDemandUsers,
    eventStartTime,
    eventEndTime,
    isLive, 
    liveStartTime,
    rtActiveUsers,
    eventNoReg,
    eventStatus,
    dateRange,
    event
  } = stats;  
  //Event release time -> Manager
  const timeStamp_event_started = useSelector((state) => state.stats.eventReleaseTime)
  const timeStamp_event_ended = useSelector((state) => state.stats.timeStamp_event_ended)

  const [CSVdata, setCSVdata] = useState("");

  const [parsedRegistrant, setParsedRegistrant] = useState([]);
  const [registrantColumn, setRegistrantColumn] = useState([]);
  const [liveUsersId, setLiveUsersId] = useState([])

  //Total live user for EXPORT TO CSV - LIVE SHEET
  const [_totalLiveUsers, setTotalLiveUsers ] = useState(0)

  useEffect(() => {
    if (registrant && Object.entries(registrant).length > 0 && !eventNoReg) {
    //   let liveUsersId = rtActiveUsers?.map((eachLiveUser)=>{
    //     return eachLiveUser._id;
    // })
    //   setLiveUsersId(liveUsersId)

   
      if(isLive &&  eventStatus !== 'stopped' ) {
        console.log('islive-true', '_totalLiveUsers', _totalLiveUsers)
        console.log('islive-true', 'totalLiveUsers', totalLiveUsers)
    
        let p = registrant.map((eachReg) => {
          let parsed = JSON.parse(eachReg.fields);
          return {
            ...parsed,
            registration: moment(eachReg.date).format("LLL"),
            accessed: moment(eachReg.loggedInTime).format("LLL"),
            // _id:eachReg._id,
            Watched: 
              eachReg.loggedInTime === "0"
                ? "N/A"
                : (getValidDate(eachReg.loggedInTime) >= new Date(liveStartTime).getTime() ? "Yes(Live)": "No")

            // watching: liveUsersId.includes(eachReg._id) ? 'Yes' : 'No'
            
          };
        });
        
        let totalCount = 0;
        p.map((each)=>{
            if(each.Watched === 'Yes(Live)'){
              totalCount++
            }
        })

        setTotalLiveUsers(totalCount)
        setParsedRegistrant(p);
        setRegistrantColumn(Object.keys(p[0]));
        


        let parsedColumn = Object.keys(p[0]).map((eachCo) => {
          return {
            field: eachCo,
            headerName: eachCo,
            align: "left",
            width: 240,
          };
        });
  
        parsedColumn.shift();
  
        setRegistrantColumn(parsedColumn);
  
        // setRegistrantColumn(Object.keys(parsedRegistrant[0]));
      }  else {
        console.log('islive-fase', '_totalLiveUsers', _totalLiveUsers)
        console.log('islive-fase', 'totalLiveUsers', totalLiveUsers)
        let p = registrant.map((eachReg) => {
          let parsed = JSON.parse(eachReg.fields);
          // console.log('accessedaccessedaccessedaccessedaccessedaccessed',  eachReg.loggedInTime, new Date(eachReg.loggedInTime))

          return {
            ...parsed,
            registration: moment(eachReg.date).format("LLL"),
            // date of registration
            accessed: eachReg.loggedInTime,
            // last accessed date
            Live:eachReg.watchedLive ? 'Yes' : 'No',
            // watched live - ondemand 
            OnDemand:eachReg.watchedOnDemand ? 'Yes' : 'No',
            time:eachReg.timeSpent/60000,
            // _id:eachReg._id
          };
        });
  
        setParsedRegistrant(p);
        setRegistrantColumn(Object.keys(p[0]));
  
        let parsedColumn = Object.keys(p[0]).map((eachCo) => {
          return {
            field: eachCo,
            headerName: eachCo,
            align: "left",
            width: 240,
          };
        });
  
        parsedColumn.shift();
  
        setRegistrantColumn(parsedColumn);
  
        // setRegistrantColumn(Object.keys(parsedRegistrant[0]));
      }




    }
  }, [registrant, stats, loading]);



  const getStatsLink = async(eventID, registrants, eventName, companyName,
    fromDate,toDate, totalRegistrants,totalLiveUsers, totalOnDemandUsers
    )=>{
      console.log('registrants_all',registrants_all)
    let data = {

      // data:registrants,
      eventName:eventName,
      companyName:companyName,
      fromDate:fromDate,
      toDate:toDate,
      totalRegistrants:totalRegistrants,
      totalLiveUsers:totalLiveUsers,
      totalOnDemandUsers:totalOnDemandUsers,
      event,
      preRegWatchedLive:registrants_all.filter((each) => new Date(each.date).getTime() <= new Date(timeStamp_event_started).getTime()).filter((each) =>(each.watchedLive === true && each.watchedOnDemand === false)).length,
      preRegWatchedOnDemand:registrants_all.filter((each) => new Date(each.date).getTime() <= new Date(timeStamp_event_started).getTime()).filter((each) => (each.watchedOnDemand === true && each.watchedLive === false )).length,
      didNotWatched:registrants_all.filter((each) => !each.watchedOnDemand && !each.watchedLive).length,
      eventStatus:eventStatus




    }
    
    return axios.post( `${process.env.REACT_APP_BASE_API}/event/statsToExcel/${eventID}/?token=${process.env.REACT_APP_TOKEN_BASE_API}`, data)

      //stats endpoint
      // return axios.post( `${process.env.REACT_APP_STATS_API_URL}/event/statsDataToCSV/${eventID}/?token=${process.env.REACT_APP_TOKEN}`, data)

  }

  //Modal
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => {
    setOpen(true);
    SetClientLink("");
    setCopied(false);
    setState({
      usersNow: false,
      totalRegistrant: false,
      visitors: false,
      totalViews: false,
      uniqueViews: false,
      averageTime: false,
      userGraph: false,
      geoData: false,
    });
  };

  const handleClose = () => setOpen(false);
  const [clientLink, SetClientLink] = useState("");
  //Modal

  // static data link generation individual states

  const [state, setState] = useState({
    usersNow: false,
    totalRegistrant: false,
    visitors: false,
    totalViews: false,
    uniqueViews: false,
    averageTime: false,
    userGraph: false,
    geoData: false,
  });

  // static data link generation individual states

  //copy state
  const [copied, setCopied] = useState(false);
  const handleStaticLinkCreation = () => {
    const staticData = {
      tokenValid: true,
      eventID: stats.event_ID,
      eventName: stats.searchText,
      usersNow: usersNow,
      totalRegistrant: totalRegistrant,
      visitors: visitors,
      totalViews: totalViews,
      uniqueViews: uniqueViews,
      averageTime: averageTime,
      userGraph: userGraph,
      geoData: geoData,
    };

    const secret = "secret";
    const jwt = sign(staticData, secret);
    SetClientLink(jwt);
  };

  const handleReload = () => {
    if (globalFilter === "company") {
      props.dispatch(getEventByCompanyID(company_id));
    } else if (globalFilter === "event") {
      // props.dispatch(resetStats());
      props.dispatch(getEventByKeyStroke(event_ID));
      props.dispatch(getStatByID(event_ID));
      // props.dispatch(getLiveUsersByEventID(event_ID));
      // props.dispatch(getRegistrantDetails(event_ID));
    }
  };

  const exportToExcel = () => {
    const table = document.getElementById('tblData');
    const ws = XLSX.utils.table_to_sheet(table);
  
    // Truncate cell content that exceeds the limit
    XLSX.utils.sheet_to_json(ws).forEach((row) => {
      for (const key in row) {
        if (row[key].length > 32767) {
          row[key] = row[key].substring(0, 32764) + '...'; // Truncate and add ellipsis
        }
      }
    });
  
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
    // Generate a blob containing the Excel file
    const blob = XLSX.write(wb, { bookType: 'xlsx', type: 'blob' });
  
    // Create a URL for the blob
    const url = URL.createObjectURL(blob);
  
    // Create a link and trigger a click event to download the Excel file
    const a = document.createElement('a');
    a.href = url;
    a.download = 'table.xlsx'; // File name
    a.click();
    URL.revokeObjectURL(url);
  };
  
  const handleCSVClick =  async() => {
    setLoadingExportToCSV(true)
    
    try {
      
      let response =  await getStatsLink(event_ID, parsedRegistrant, searchText, companyName,
         dateRange.selected ?  new Date(dateRange.start).toUTCString()  :new Date(eventStartTime).toUTCString(),
         dateRange.selected ?  new Date(dateRange.end).toUTCString()  : new Date(eventEndTime).toUTCString(),
         registrant.length,
         isLive &&  eventStatus !== 'stopped' ? _totalLiveUsers: totalLiveUsers,
         totalOnDemandUsers
         )

         console.log('response Export to CSV', response)
   
        if(response.data.success) {
          // props.dispatch(setAlert('Downloaded successfully', 'success', 2000))

          setLoadingExportToCSV(false)
          setLoadingExportToCSV_ERROR(false)
          console.log('handleCSVhandleCSVhandleCSVhandleCSV', response.data.downloadLink.Location)
          var downloadLink;
              downloadLink = document.createElement("a");
  
      document.body.appendChild(downloadLink);
      let filename = "Analytics report for "+searchText  + ".xls"
  
       downloadLink.href =  response.data.downloadLink.Location;
  
        downloadLink.download = filename
  
        downloadLink.click();
        }
         

    } catch (error) {
  console.log('Export to CSV',error)
  props.dispatch(setAlert('Error downloading CSV file, Please try again', 'error', 5000))
  // setAlert('Error downloading CSV file, Please try again',)
  setLoadingExportToCSV_ERROR(true)
  setLoadingExportToCSV(false)
    }

  };

  const {
    usersNow,
    totalRegistrant,
    visitors,
    totalViews,
    uniqueViews,
    averageTime,
    userGraph,
    geoData,
  } = state;
  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };
  return (
    <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }} m={1}>
      <Accordion sx={{ width: "95%", background: "white" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "space-between",
            }}
            m={2}
          >
            <Typography>Search Filters</Typography>
            {globalFilter === "event" &&
              Object.entries(statsByID).length > 0 ? (
              <>
                <Typography
                  sx={{ display: { xs: "none", md: "block", lg: "block" } }}
                >
                  {`${searchText}` + " "}

                  <span style={{ color: "blue" }}>
                    ( {statsByID.dateFrom} - {statsByID.dateTo} )
                  </span>
                </Typography>
              </>
            ) : (
              ""
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container justifyContent={"space-evenly"} spacing={2} p={0}>
            {/* Search by company ID  */}
            {/* <DatesInput /> */}

            <SearchByCompanyID />
            {/* Autocomplete company and event */}
            <AutoCompleteCompanyAndEvent />
            {/* <SearchByEvent/> */}

            {globalFilter === "event"  && eventType !=='bc' && (
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >

                <DatesInput />
              </Grid>
            )}
          </Grid>

          <Grid container justifyContent={"center"} spacing={2} p={1}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={2}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button variant="contained" onClick={handleReload} style={{background:'#BBB8B2'}}>
                Reload
                <ReplayIcon />
              </Button>
            </Grid>

            {registrant?.length > 0 && globalFilter === 'event' && <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={2}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              {
                loadingExportToCSV ? <Spinner/> :
              <Button variant="contained" sx={{background:'rgba(92, 133, 37, 0.9)'}} onClick={handleCSVClick}>
                { isLive &&  eventStatus !== 'stopped' ? 'Export to CSV (Live data)': 'Export to CSV (Post event data)'  } 
                <ImportExportIcon />
              </Button>
              }




            </Grid>}

           

            {
              //  Object.entries(stats).length > 0 && <CSVLink  asyncOnClick={true}  data={CSVdata} onClick={handleCSV}>Download me</CSVLink>

              registrant?.length > 0 && globalFilter === 'event' && (
                <table id="tblData" style={{ display: 'none' }} ref={tableRef}>
                  <tr>
                    <td
                      colspan="2"
                      style={{ fontSize: "45px", textAlign: "left" }}
                    >
                    <h1>BRR Media Analytics</h1>  
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">&nbsp;</td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style={{ fontSize: "30px", textAlign: "left" }}
                    >
                      General
                    </td>
                  </tr>

                  <tr>
                    <td colspan="2">
                      <table border="1" width="100%">
                        <tr>
                          <td width="20%"> <b>Event Name</b></td>
                          <td colspan="14">{searchText}</td>
                        </tr>
                        {/* <tr>
                          <td>Event ID</td>
                          <td colspan="14">{event_ID}</td>
                        </tr> */}
                        <tr>
                          <td> <b>Company Name</b> </td>
                          <td colspan="14">{companyName}</td>
                        </tr>
                        {/* <tr>
                          <td>Company ID</td>
                          <td colspan="14">{companyId}</td>
                        </tr> */}
                        <tr>
                          <td> <b>Date From</b> </td>
                          <td  align="left" colspan="14">

                          { dateRange.selected ?  new Date(dateRange.start).toUTCString()  :new Date(eventStartTime).toUTCString()}
                          </td>
                        </tr>
                        <tr>
                          <td> <b>Date To</b> </td>
                          <td colspan="14">
                            {dateRange.selected ?  new Date(dateRange.end).toUTCString()  : new Date(eventEndTime).toUTCString()}
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>

                  <tr>
                    <td colspan="2">
                      <table border="1" width="100%">
                        <tr>
                          <th align="left">Total Registrant</th>
                          <th align="left">Total live users</th>
                          <>
                          {
                            isLive &&  eventStatus !== 'stopped' ? '' : <th align="left">Total on demand users</th>

                          }
                          
                          </>
                        </tr>
                        <tr>
                          <td>{registrant.length}</td>
                          <td>{
                           
                           isLive &&  eventStatus !== 'stopped' ? _totalLiveUsers: totalLiveUsers
                          
                          }</td>
                          <>
                          
                          {
                            isLive &&  eventStatus !== 'stopped' ? '' :   <td>{totalOnDemandUsers}</td>

                          }
                          </>
                        

                        </tr>
                      </table>
                    </td>
                  </tr>

                  <tr>
                    <td colspan="2">&nbsp;</td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style={{ fontSize: "45px", textAlign: "left" }}
                    >
                     <h1>All Registrants</h1> 
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <table border="1" width="100%">
                        <tr>
                          {Object.entries(parsedRegistrant).length > 0 &&
                            Object.keys(parsedRegistrant[0]).map((each) => {

                                if(each === 'registration') {
                                  return (
                                    <th align="left">Date of registration</th>
                                  )
                                } else if(each === 'accessed'){
                                  return (
                                    <th align="left">Last accessed date</th>

                                  )
                                } else if(each === 'name'){
                                  return (
                                    <th align="left">Name</th>

                                  )
                                }
                                else if(each === 'email'){
                                  return (
                                    <th align="left">Email</th>

                                  )
                                }
                                else if(each === 'organisation'){
                                  return (
                                    <th align="left">Organisation</th>

                                  )
                                }
                                else if(each === 'time'){
                                  return (
                                    <th align="left">Time (in mins)</th>

                                  )
                                }
                                else if(each === 'Live'){
                                  return (
                                    <th align="left">Watched Live</th>

                                  )
                                }   else if(each === 'OnDemand'){
                                  return (
                                    <th align="left">Watched OnDemand</th>

                                  )
                                }                            
                                else {
                                  return (
                                    <th align="left">{each}</th>

                                  )
                                }

                              // <th align="left">{each}</th>
                              
                              
                            }
                            )}
                        </tr>
{/* 
                        {Object.entries(parsedRegistrant).length > 0 &&
                          parsedRegistrant.map((eachRegistrant) => {
                            

                            {
                              if(liveUsersId.includes(eachRegistrant._id)) {
                                  return  (
                                    <tr>
                                    {Object.keys(eachRegistrant).map((eachCol) => (
                                      <td> {eachRegistrant[eachCol]} </td>
                                    ))}
                                  </tr>
                                  )
                              }
                            }
                               
                            
                          
                                }
                          
                          )} */}

        {Object.entries(parsedRegistrant).length > 0 &&
                          parsedRegistrant.map((eachRegistrant) => (
                            <>
                              <tr>
                                {Object.keys(eachRegistrant).map((eachCol) => (
                                  <td> {eachRegistrant[eachCol]} </td>
                                ))}
                              </tr>
                            </>
                          ))}

                      </table>
                    </td>
                  </tr>
                </table>
              )
            }
          </Grid>




        </AccordionDetails>
      </Accordion>

    </Box>
  );
};

export default connect()(SearchFunction);
