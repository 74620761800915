import React from "react";
import { useState } from "react";
import { useEffect } from "react";

//MUI
import {
    Backdrop,
    Box,
    Button,
    Card,
    CardContent,
    Fade,
    Grid,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
  } from "@mui/material";
  import { makeStyles } from "@mui/styles";
  import { DataGrid, GridToolbar, GridColDef, useGridApiRef } from '@mui/x-data-grid';
  import { v4 as uuidv4 } from 'uuid';
  
  //Redux
  import { getLiveUsersByEventID } from "../../redux/action/stats";
  import { connect, useSelector } from "react-redux";
  
  //Utils
  import { secondsToHour } from "../../utils/secondsToHr";


  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    // height: "90vh",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const useStyles = makeStyles((theme) => ({
    hoverCard: {
      transition: "background-Color 0.5s ease",
      "&:hover": {
        backgroundColor: "#576574",
        cursor: "default",
      },
    },

  }));
  
  const CardLayoutLiveUsers = (props) => {
    const stats = useSelector((state) => state.stats);
  
    const { globalFilter, searchText, loading, event_ID, rtActiveUsers } = stats;
    const d = new Date();
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    // const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [foundRtUsers, setFoundRtUsers] = useState(false)

    const handleOpen = () => {
        setOpen(true);
        props.dispatch(getLiveUsersByEventID(event_ID))
        setFoundRtUsers(true)
    }



    const [parsedRegistrant, setParsedRegistrant] = useState([]);
    const [registrantColumn, setRegistrantColumn] = useState([]);
    const [liveUsers, setLiveUsers] = useState([])
  

  
  
    useEffect(() => {
      if (props.registrants) {
        let p = rtActiveUsers.map((eachReg) => {
          return {
            id: uuidv4(),
            uid:eachReg.uid
          };
  
        });
        setParsedRegistrant(p);
        setRegistrantColumn(Object.keys(p[0]))
  
        let parsedColumn = Object.keys(p[0]).map((eachCo) => {
          return {
  
            field: eachCo,
            headerName: eachCo,
            align: "left",
            width: 240,
  
          }
        })
        parsedColumn.shift()
        setRegistrantColumn(parsedColumn)
      }

    }, [foundRtUsers]);
  
  
    const apiRef = useGridApiRef();
  
  
    return (
  
  
      <>
        <Card
          className={classes.hoverCard}
          sx={{
            // width:'250px',
            height: "150px",
            // backgroundColor: `${color}`,
            background: `linear-gradient(to right bottom, ${props.color1}, ${props.color2})`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CardContent>
            <Box sx={{ justifyContent: "center" }}>
              {/* Active Users have chlid component for RT  */}
  
              <Typography sx={{ fontSize: "rem" }} color="white">
                {props.title}
              </Typography>

              {!props.live &&  <Typography  sx={{textAlign:'center', fontSize:'0.8rem'}} color="white">
                (On Demand)
              </Typography>}
              <div style={{ display: "flex", justifyContent: "center" }}>
                {props.icon}
  </div>
              <Typography
                sx={{ color: "white", fontSize: "250%", textAlign: "center" }}
              >
                {props.timeFormat ? secondsToHour(props.numbers) : props.numbers}
              </Typography>
              {props.children}
  
              {/* Modal start  */}
              <div style={{ display: "flex", justifyContent: "center" }}>
             
                {/* <Button variant={"contained"} onClick={handleOpen}>
                  View
                </Button> */}
                <Modal
                  keepMounted
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={open}
                  onClose={handleClose}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
  
  
                  <Fade in={open}>
                    <Box sx={style}>
                      {
  
                        (parsedRegistrant && registrantColumn) &&
  
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            lg={12}
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <Paper sx={{ height: '70vh', width: '100%' }}>
                              <DataGrid
                                apiRef={apiRef}
                                components={{ Toolbar: GridToolbar }}
  
                                rows={parsedRegistrant}
                                columns={registrantColumn}
                                pageSize={15}
                                rowsPerPageOptions={[5]}
                                sx={{
                                  '& .MuiDataGrid-columnHeader': {
                                    fontSize: '20px',
                                    textTransform: 'uppercase',
                                  }
                                }}
  
                              />
                                {/* {
                                    registrants && (
                                        registrants.map((eachReq)=>(
                                            <p>
                                                    {
                                                         JSON.parse(eachReq.fields)

                                                    }
                                            </p>
                                        ))
                                    )
                                } */}


                            </Paper>
                          </Grid>
                        </Grid>
                      }

                    </Box>
                  </Fade>
                </Modal>
              </div>
  
              {/* Modal start  */}
            </Box>
          </CardContent>
        </Card>
      </>
    );
  };
  
  export default connect()(CardLayoutLiveUsers);
  