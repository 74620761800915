import React from 'react';
import { Dialog, DialogTitle, DialogContent, Button, Box, Grid, ListItem, ListItemText, Paper, Typography, keyframes } from '@mui/material';
import { secondsToHour } from '../../utils/secondsToHr';
import { DataGrid } from '@mui/x-data-grid';
import styled from '@emotion/styled';
import { makeStyles } from '@mui/styles';
import './style.css';
const blink = keyframes`
from { opacity: 0; }
to { opacity: 1; }
`;
const BoxText = styled(Typography)(({ theme}) => ({
   p:1, textAlign:'center',color:'black',
    fontSize:'1.8rem'

}))
const useStyles = makeStyles((theme) => ({
    container: {
      display: 'flex',
      alignItems: 'center',
    },
    circle: {
      width: '12px',
      height: '12px',
      borderRadius: '50%',
      backgroundColor: 'red',
      marginRight: 1, // Add space between the circle and text
      animation: '$pulse 1s infinite alternate', // Create the pulsing animation
    },
    '@keyframes pulse': {
      from: {
        backgroundColor: 'red',
      },
      to: {
        backgroundColor: 'white',
      },
    },
  }));

  const BlinkedBox = styled("Button")({
    backgroundColor: "red",
    animation: `${blink} 1s linear infinite`,
    color: 'white'
  });
  
function MyDialog({ open, handleClose, content,activity, userID, email }) {
    const classes = useStyles();

  const onDemandVisits = content && JSON.parse(content);
  console.log('activityactivityactivity', activity.rows)
  return (
    <Dialog open={open} maxWidth={false} onClose={handleClose}>
      <Box sx={{ width: '90vw', height: '100vh', display: 'flex', flexDirection: 'column' }}>
        <DialogTitle>Detailed user activity : {email}</DialogTitle>
        <DialogContent sx={{ flex: 1, display: 'flex' }}>
          <Grid container spacing={0} sx={{ width: '100%', background:'' }} justifyContent={'center'}>
            <Grid item xs={4}>
              <Box
                sx={{
                  height: 'auto',
                  background: '',
                }}
              >
                <Paper sx={{width:'auto', border:'', m:2, height:'70vh',  overflowY:'scroll'}}>
                  <Typography sx={{fontSize:'1rem', textAlign:'center', background:'rgb(14, 149, 148, 1)',color:'white', p:1}}>On Demand Visits</Typography>


                  {onDemandVisits.length>0 && onDemandVisits.map((visit, index) => (
                          <Box sx={{p:1, display:'flex', justifyContent:'center', justifySelf:'center' ,flexDirection:'column', background:'rgb(14, 149, 148, 0.2)', m:1, borderRadius:'10px'}}>
                            <Typography><span style={{fontWeight:'bold'}}>Start time - </span> {visit.startTime}</Typography>
                            <Typography><span style={{fontWeight:'bold'}}>End time - </span> {visit.endTime}</Typography>
                            <Typography><span style={{fontWeight:'bold'}}>Duration - </span>  {secondsToHour(
                                                  new Date(
                                                    visit.endTime
                                                  ).getTime() -
                                                  new Date(
                                                    visit.startTime
                                                  ).getTime()
                                                )}</Typography>

                          </Box>

))}

{/* {onDemandVisits.length>0 && onDemandVisits.map((visit, index) => (

            <ListItem key={index}>
              <ListItemText primary={`Start Time: ${visit.startTime}`} />
              <ListItemText primary={`End Time: ${visit.endTime}`} />

            </ListItem>

          ))} */}
                </Paper>


              </Box>
            </Grid>
            <Grid item xs={8}>
              <Box
                sx={{
                  height: '70vh',
                  background: '',
                }}
              >
                  <Paper sx={{width:'auto', border:'', m:2, height:'35vh',  overflowY:'scroll'}}>
                  <Box sx={{display:'flex', justifyContent:'center', background:'linear-gradient(to right bottom, rgba(242, 84, 45, 1), rgba(242, 84, 45, 0.8))', alignItems:'center'}}>
                  <Typography sx={{fontSize:'1rem', textAlign:'center',color:'white', p:1}}>
                   
                    Live Activity 
                    </Typography>
                    <div className={classes.circle}></div>
                    </Box>


                  <DataGrid
        rows={activity.rows.filter((each)=> each.userID === userID)}
        columns={activity.columns}
        
        pageSize={5}
        rowsPerPageOptions={[5, 10, 20]}
      />
                </Paper>
                <Paper sx={{width:'auto', border:'', m:2, height:'33vh',  overflowY:'scroll', p:2}}>
                <Grid container spacing={4}>
        <Grid item xs={4}>
          <div style={{ border: '1px solid #ccc', height: '100px' }}>
          <Box sx={{display:'flex', justifyContent:'center', background:'linear-gradient(to right bottom, rgba(242, 84, 45, 1), rgba(242, 84, 45, 0.8))', alignItems:'center'}}>
                  <Typography sx={{fontSize:'1rem', textAlign:'center', background:'',color:'white', p:1}}>

                    No. of Question asked
                    </Typography>
                    <div className={classes.circle}></div>
                    </Box>
            <BoxText>
{activity.rows.filter((each)=> each.userID === userID).filter((each=> each.type === 'QUESTION')).length}
</BoxText>
          </div>
        </Grid>
        <Grid item xs={4}>

        <div style={{ border: '1px solid #ccc', height: '100px' }}>
          <Box sx={{display:'flex', justifyContent:'center', background:'linear-gradient(to right bottom, rgba(242, 84, 45, 1), rgba(242, 84, 45, 0.8))', alignItems:'center'}}>
                  <Typography sx={{fontSize:'1rem', textAlign:'center', background:'',color:'white', p:1}}>
                  Slide Change count
                    </Typography>
                    <div className={classes.circle}></div>
                    </Box>
            <BoxText>
            {activity.rows.filter((each)=> each.userID === userID).filter((each=> each.type === 'SLIDE')).length}
</BoxText>
          </div>

        </Grid>
        <Grid item xs={4}>
        <div style={{ border: '1px solid #ccc', height: '100px' }}>
          <Box sx={{display:'flex', justifyContent:'center', background:'linear-gradient(to right bottom, rgba(242, 84, 45, 1), rgba(242, 84, 45, 0.8))', alignItems:'center'}}>
                  <Typography sx={{fontSize:'1rem', textAlign:'center', background:'',color:'white', p:1}}>
                  Info tab clicked
                    </Typography>
                    <div className={classes.circle}></div>
                    </Box>
            <BoxText>
            {activity.rows.filter((each)=> each.userID === userID).filter((each=> each.type === 'INFO')).length}
</BoxText>
          </div>
        </Grid>
      </Grid> 


      <Grid container spacing={4} mt={2}>
        <Grid item xs={4}>
        <div style={{ border: '1px solid #ccc', height: '100px' }}>
          <Box sx={{display:'flex', justifyContent:'center', background:'linear-gradient(to right bottom, rgba(242, 84, 45, 1), rgba(242, 84, 45, 0.8))', alignItems:'center'}}>
                  <Typography sx={{fontSize:'1rem', textAlign:'center', background:'',color:'white', p:1}}>
                  Notes feature accessed
                    </Typography>
                    <div className={classes.circle}></div>
                    </Box>
            <BoxText>
            {activity.rows.filter((each)=> each.userID === userID).filter((each=> each.type === 'NOTE')).length}
</BoxText>
          </div>
        </Grid>
        <Grid item xs={4}>
        <div style={{ border: '1px solid #ccc', height: '100px' }}>
          <Box sx={{display:'flex', justifyContent:'center', background:'linear-gradient(to right bottom, rgba(242, 84, 45, 1), rgba(242, 84, 45, 0.8))', alignItems:'center'}}>
                  <Typography sx={{fontSize:'1rem', textAlign:'center', background:'',color:'white', p:1}}>
                  Layout Slider Changed
                    </Typography>
                    <div className={classes.circle}></div>
                    </Box>
            <BoxText>
            {activity.rows.filter((each)=> each.userID === userID).filter((each=> each.type === 'SLIDER')).length}
</BoxText>
          </div>
        </Grid>
        <Grid item xs={4}>
        <div style={{ border: '1px solid #ccc', height: '100px' }}>
          <Box sx={{display:'flex', justifyContent:'center', background:'linear-gradient(to right bottom, rgba(242, 84, 45, 1), rgba(242, 84, 45, 0.8))', alignItems:'center'}}>
                  <Typography sx={{fontSize:'1rem', textAlign:'center', background:'',color:'white', p:1}}>
                  Used help feature
                    </Typography>
                    <div className={classes.circle}></div>
                    </Box>
            <BoxText>
            {activity.rows.filter((each)=> each.userID === userID).filter((each=> each.type === 'HELP')).length}
</BoxText>
          </div>
        </Grid>
      </Grid> 
                </Paper>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Box>
      <Button onClick={handleClose} color="error" variant='contained'>
        Close
      </Button>
    </Dialog>
  );
}

export default MyDialog;
