import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { connect, useSelector } from "react-redux";
import {
  getCompaniesByKeyStroke,
  getEventByCompanyID,
  getEventByKeyStroke,
  getLiveUsersByEventID,
  getRegistrantDetails,
  getStatByID,
  resetStats,
  setCompanyNameInTitle,
  setGlobalFilter,
  setLoading,
} from "../../redux/action/stats";
import { Grid } from "@mui/material";

const SearchByCompany = (props) => {
  const stats = useSelector((state) => state.stats);

  const { autoCompleteLoading, companies, globalFilter, events } = stats;

  const [openCompany, setOpenCompany] = React.useState(false);
  const [optionsForCompany, setOptionsForCompany] = React.useState([]);
  const [valueForCompany, setValueForCompany] = React.useState(null);
  const [inputValueForCompany, setInputValueForCompany] = React.useState("");

  const [openEvent, setOpenEvent] = React.useState(false);
  const [optionsForEvent, setOptionsForEvent] = React.useState([]);
  const [valueForEvent, setValueForEvent] = React.useState(null);
  const [inputValueForEvent, setInputValueForEvent] = React.useState("");

  //   const loading = open && options.length === 0;

  useEffect(() => {
    if (globalFilter === "event") {
      setInputValueForCompany("");
    }
  }, [globalFilter]);

  React.useEffect(() => {
    let active = true;

    if (!autoCompleteLoading) {
      return undefined;
    }

    return () => {
      active = false;
    };
  }, [autoCompleteLoading]);

  //Company
  React.useEffect(() => {
    if (!openCompany) {
      setOptionsForCompany([]);
    }
  }, [openCompany]);

  useEffect(() => {
    props.dispatch(getCompaniesByKeyStroke(inputValueForCompany));
  }, [inputValueForCompany]);

  //Event
  React.useEffect(() => {
    if (!openEvent) {
      setOptionsForEvent([]);
    }
  }, [openCompany]);

  useEffect(() => {
    props.dispatch(getEventByKeyStroke(inputValueForEvent));
  }, [inputValueForEvent]);

  return (
    <>
      <Grid item xs={12} md={4} lg={4} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Autocomplete
  // multiple
          id="company"
          sx={{ width: 400 }}
          open={openCompany}
          autoHighlight={true}
          onOpen={() => {
            setOpenCompany(true);
          }}
          onClose={() => {
            setOpenCompany(false);
          }}
          filterOptions={(x) => x}

          isOptionEqualToValue={(option, value) => option.name === value.name}
          getOptionLabel={(option) => (option.name) ? option.name : ''}
          options={companies}
          loading={autoCompleteLoading}
          onChange={(event, newValue) => {
            setOptionsForCompany(
              newValue ? [newValue, ...optionsForCompany] : optionsForCompany
            );
            if (newValue) {

              // console.log(newValue.name)
              props.dispatch(setLoading())

              props.dispatch(getEventByCompanyID(newValue._id));

              props.dispatch(setGlobalFilter("company", newValue.name));

            }
            // setValueForCompany(newValue.name ? newValue.name :'name');
            // Company selected in the text box-> trigger and display events accordingly
            //set Filter to true in redux store and display the result

          }}
          onInputChange={(event, newInputValue) => {
            //Dispatch action
            //   console.log("On each key stroke", newInputValue);
            if (newInputValue.length <= 0) {
              props.dispatch(setGlobalFilter(null, null));
            }
            if (newInputValue.length > 0) {
              setInputValueForCompany(newInputValue);



            }

          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Company Name or Code"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {autoCompleteLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={4} lg={4} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Autocomplete
          id="event"
          sx={{ width: 400 }}
          open={openEvent}
          onOpen={() => {
            setOpenEvent(true);
          }}
          onClose={() => {
            setOpenEvent(false);
          }}
          filterOptions={(x) => x}

          isOptionEqualToValue={(option, value) => option.title === value.title}
          getOptionLabel={(option) => option?.title}
          options={events}
          loading={autoCompleteLoading}
          onChange={(event, newValue) => {
            setOptionsForEvent(
              newValue ? [newValue, ...optionsForEvent] : optionsForEvent
            );
            if (newValue) {

              // console.log(newValue.title)
              props.dispatch(setCompanyNameInTitle(newValue._id))
              props.dispatch(setGlobalFilter("event", newValue.title));
              props.dispatch(resetStats());

              
              props.dispatch(setLoading())
              props.dispatch(getStatByID(newValue._id))
              // props.dispatch(getLiveUsersByEventID(newValue._id));
              // props.dispatch(getRegistrantDetails(newValue._id));

            }

            // Event selected in the text box-> trigger and display events accordingly
            //set Filter to true in redux store and display the result
          }}
          onInputChange={(event, newInputValue) => {

            //Dispatch action
            //   console.log("On each key stroke", newInputValue);
            if (newInputValue.length <= 0) {
              props.dispatch(setGlobalFilter(null, null));
            }
            setInputValueForEvent(newInputValue);

          }}
          renderInput={(params) => (
            <TextField

              {...params}
              label="Event title"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {autoCompleteLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </Grid>

    </>
  );
};

export default connect()(SearchByCompany);
