import React from "react";

import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { secondsToHour } from "../../../utils/secondsToHr";
import { fontSize } from "@mui/system";


// import { secondsToHour } from "../../utils/secondsToHr";
const useStyles = makeStyles((theme) => ({
  hoverCard: {
    transition: "background-Color 0.5s ease",
    "&:hover": {
      opacity:0.8,
      cursor: "default",
    },
  },

}));

const CardComponent = ({
  title,
  numbers,
  color1,
  color2,
  timeFormat,
  icon,
  children,
  details,
  cpLayout,fontColor,additional_title_component,height, fontColorDetails, fontColorTitle,fontSize
}) => {
  const classes = useStyles();
  console.log('  <additional_title_component/>')
  
  return (
    <>
      <Card
      elevation={6}
        className={classes.hoverCard}
        sx={{
          width:cpLayout ? '200px' : 'auto',
          height: height ? height :"150px",
          backgroundColor: `${color2}`,
          borderRadius:'20px'
        

        }}
      >
    
    
      
        <CardContent>
            <Box sx={{background:'', display:'flex', justifyContent:'space-between'}}>
                <Box>
                <Typography sx={{textTransform:'uppercase', color:fontColorTitle, fontSize:fontSize}}>{title}</Typography>
                <Typography fontSize={'2rem'} fontWeight={'bold'} sx={{color:fontColor}}>
                {timeFormat ? secondsToHour(numbers) : numbers} 

                    
                    </Typography>
                </Box>

            {
             icon &&   <Box>

<Box sx={{background:color1, borderRadius:'50%',height:'50px', width:'50px', display:'flex', justifyContent:'center', alignItems:'center', fontSize:'1.6rem'}}>
  {icon}
  </Box>

</Box>
            }    
            </Box>       

              <Box sx={{display:'flex', justifyContent:'center', flexDirection:'column', background:'', height:'100%'}}>

            <Box sx={{display:'flex', justifyContent:'center', color:fontColor, height:'30px'}}>
            <Typography sx={{fontSize:'0.8rem', color:fontColorDetails}}>{details}</Typography>

                </Box>  

                <Box sx={{background:''}}>
                    {children}
                </Box>
              </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default CardComponent;
