import React from 'react';
  import { Box, Card, CardContent, Grid, IconButton, Tooltip, Typography } from "@mui/material";

import SplitButton from '../ui/SplitButton';
import LineGraphLayout from './LineGraphLayout';
import BarChartLayout from './BarChartLayout';
import { Delete } from '@mui/icons-material';

  const options = ['line graph', 'bar chart'];


  const GraphContainer = ({graphData})=>{

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
  
    const handleClick = () => {
      console.info(`You clicked ${options[selectedIndex]}`);
    };
  
    const handleMenuItemClick = (event, index) => {
      setSelectedIndex(index);
      setOpen(false);
      console.log('index', index)
    };
  
    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };
  
    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
  
      setOpen(false);
    };



    return (
  
        <Card
        sx={{
          height:'420px',   
        }}
      >
                {/* <Typography sx={{ fontSize: "200%", textAlign:'center',  background: "#2d3436" }} color="white">
              Graph
            </Typography> */}


            <Grid container justifyContent={"center"} spacing={2} p={1}>
            <Grid item>


            <SplitButton anchorRef={anchorRef} handleClick={handleClick} handleMenuItemClick={handleMenuItemClick} handleToggle={handleToggle} 
                handleClose={handleClose} open={open}
                selectedIndex={selectedIndex}
                options={options}
                />
                </Grid>
                </Grid>


        <CardContent>
          <Box sx={{ justifyContent: "center" }}>

     
                {
                    selectedIndex === 0 ? (
                       <LineGraphLayout graphData={graphData}/>
                    ) : (
                        <BarChartLayout graphData={graphData}/>

                    )
                }
          </Box>
          
        </CardContent>
      </Card>
    )   
  }

  export default GraphContainer;