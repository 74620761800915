import React, { useEffect, useState } from "react";
import { Grid, Stack, TextField, Typography } from "@mui/material";
import { connect, useSelector } from "react-redux";

import { Box } from "@mui/system";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.css";
import { getRegistrantDetails, getStatByID, getStatByIDAndDates } from "../../redux/action/stats";

const DatesInput = (props) => {


  const [value, setValue] = React.useState([]);




  //if the event is selected then make the date picker available
  const stats = useSelector((state) => state.stats);
  const stats_by_id = useSelector((state) => state.stats.statsByID);

  const { loading, companies, globalFilter, events, statsByID, event_ID,dateRange } = stats;


  const handleDateSelected = (dates) => {


    let startDate = new Date(dates[0]);
    let endDtate = new Date(dates[1]);


    // console.log('start',startDate.toISOString().substring(0,10) ,'end',endDtate.toISOString().substring(0,10) );

    //get event by date range
    console.log('event id', event_ID)
    props.dispatch(getStatByIDAndDates(event_ID, startDate.toISOString().substring(0, 10), endDtate.toISOString().substring(0, 10)));
    // props.dispatch(getRegistrantDetails(event_ID))
    setValue([])

  };




  return (
    <Grid
      item
      xs={12}
      md={12}
      lg={12}
      sx={{ display: "flex", justifyContent: "center" }}
    >

      <Stack sx={{ m: 1 }}>

        {/* <Typography sx={{textAlign:'center', fontSize:'0.8rem'}}>{dateRange.start} - {dateRange.end} </Typography> */}


        <DateRangePicker
          showOneCalendar
          placement="bottomEnd"
          value={value} onChange={setValue}
          disabled={globalFilter === 'event' ? false : true}
          appearance="default"
          size="lg"
          onOk={(dates) => handleDateSelected(dates)}

          cleanable={true}
          placeholder="Select date range"
          style={{ minwidth: "150px", width: "200px", display: 'block', height: '56px', }}
        />
      </Stack>
    </Grid>
  );
};

export default connect()(DatesInput);
