import React, { useCallback, useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import './style.css'
//MUI
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DataGrid, GridToolbar, GridColDef, useGridApiRef } from '@mui/x-data-grid';
import { v4 as uuidv4 } from 'uuid';
import { useDebounce } from 'use-debounce';

//Redux
import { getLiveUsersByEventID } from "../../../redux/action/stats";
import { connect, useDispatch, useSelector } from "react-redux";

//Utils
import { secondsToHour } from "../../../utils/secondsToHr";
import axios from "axios";
import { Clear, ClearAll } from "@mui/icons-material";
import { setAlert } from "../../../redux/action/alert";
import Spinner from "../../ui/Spinner";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "80vh",
  overflowX: 'scroll',
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  padding: "20px 20px 20px 20px",
};
const useStyles = makeStyles((theme) => ({
  hoverCard: {
    transition: "background-Color 0.5s ease",
    "&:hover": {
      backgroundColor: "#576574",
      cursor: "default",
    },
  },

}));

const CardLayoutLiveUsersScoketioCustomTable = (props) => {
  const dispatch = useDispatch()
  let { socketWc } = props
  const stats = useSelector((state) => state.stats);

  const { globalFilter, searchText, loading, event_ID, rtActiveUsers } = stats;
  const d = new Date();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [filteredParticipants, setFilteredParticipants] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
  const [participants, setParticipants] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);

  const pageRef = useRef(page);
  const pageSizeRef = useRef(pageSize);

  const [loadingU, setLoadingU] = useState(false);

  useEffect(() => {
    console.log('jishan/improvement participantsObject ------>>>', participants)
  }, [participants])


  const fetchParticipants = useCallback((page, pageSize) => {
    try {
      socketWc.emit('getLiveParticipants', { eventID: event_ID, page, pageSize });
    } catch (error) {
      console.log('ERROR getLiveParticipants', error)
    }
  }, [socketWc]);

  useEffect(() => {
    // Fetch participants for the initial page when component mounts
    fetchParticipants(page, pageSize);

    // Subscribe to socket event for participants
    socketWc.on('liveParticipantsByEvent', handleParticipantsUpdate);
    socketWc.on('participantLeft', handleParticipantLeft);
    socketWc.on('participantJoined', handleParticipantJoined);


    // Cleanup socket connection when component unmounts
    return () => {
      // Unsubscribe from socket events
      socketWc.off('liveParticipantsByEvent', handleParticipantsUpdate);
      socketWc.off('participantLeft', handleParticipantLeft);
      socketWc.off('participantJoined', handleParticipantJoined);


      // Add any additional cleanup operations here (e.g., disconnecting the socket)
    };
  }, []);

  // }, [socketWc, fetchParticipants]);

  useEffect(() => {
    if (searchTerm.length > 0) {

      searchParticipant(debouncedSearchTerm)
    }
  }, [debouncedSearchTerm]);


  useEffect(() => {
    if (searchTerm.length === 0) {

      setLoadingU(false)
    }
  }, [searchTerm]);

  const searchParticipant = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_STATS_API_URL}/users/getliveusers/${event_ID}/?token=${process.env.REACT_APP_TOKEN}`, {
        params: { searchTerm: searchTerm }
      });
      const { data } = response;
      if (data?.matchingParticipants.length === 0) {
        dispatch(setAlert('No user found', 'error', 5000))

      }
      setLoadingU(false)
      setFilteredParticipants(data.matchingParticipants)
      console.log('getliveusers', data)
    } catch (error) {
      console.error('Error fetching participants:', error);
    }


  }

  // const fetchParticipants = (page, pageSize) => {
  //     socketWc.emit('getLiveParticipants', { eventID: 'event2', page, pageSize });
  // };

  const handleParticipantsUpdate = (updatedParticipants, newPage, newPageSize, totalItems) => {
    if (updatedParticipants) {
      setParticipants(updatedParticipants);
      setPage(newPage);
      setPageSize(newPageSize);
      setTotalPages(Math.ceil(totalItems / newPageSize));
      setTotalItems(totalItems);
    }
  };

  const handleParticipantJoined = (newParticipant) => {
    if (page === totalPages) {
      setParticipants((prevParticipants) => [...prevParticipants, newParticipant]);
    }
    const newTotalPages = Math.ceil(newParticipant?.totalLive / pageSize);
    setTotalItems(newParticipant?.totalLive);
    setTotalPages(newTotalPages);
  };



  const handleParticipantLeft = (participant) => {
    console.log('jishan/handleParticipantLeft/', participant)
    setParticipants((prevParticipants) =>
      prevParticipants.filter((p) => p?.userID !== participant?.userID)
    );
    const newTotalPages = Math.ceil(participant.totalLive / pageSize);
    if (newTotalPages < page) {
      setPage(newTotalPages);
    }
    setTotalItems(participant.totalLive);
    setTotalPages(newTotalPages);
  };

  const handlePageChange = (newPage) => {
    // Update page state and fetch participants for the new page
    // setPage(newPage);
    if (newPage !== page) {
      pageRef.current = newPage;
      setPage(newPage);
      fetchParticipants(newPage, pageSize);
    }
  };

  const handlePageSizeChange = (newPageSize) => {
    // Update pageSize state and fetch participants for the first page
    setPageSize(newPageSize);
    setPage(1); // Reset to first page
  };



  const handleOpen = () => {
    setOpen(true);

    setPage(1);
    fetchParticipants(1, 10);
    // props.dispatch(getLiveUsersByEventID(event_ID))
    // setFoundRtUsers(true)
  }
  const handleSearchChange = (e) => {
    setLoadingU(true)
    setSearchTerm(e.target.value);

  };


  const handleClearSearch = () => {
    setSearchTerm('')
    setLoadingU(false)
    setFilteredParticipants([])
    fetchParticipants(1, pageSize)
  }
  const apiRef = useGridApiRef();


  return (


    <>
      <Card
        className={classes.hoverCard}
        sx={{
          width: props.cpLayout ? '200px' : 'auto',
          height: "150px",
          // backgroundColor: `${color}`,
          background: `linear-gradient(to right bottom, ${props.color1}, ${props.color2})`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: '20px',
        }}
      >
        <CardContent>
          <Box sx={{ justifyContent: "center" }}>
            {/* Active Users have chlid component for RT  */}

            <Typography sx={{ fontSize: "1rem" }} color="white">
              {props.title}
            </Typography>

            {!props.live && <Typography sx={{ textAlign: 'center', fontSize: '0.8rem' }} color="white">
              (On Demand)
            </Typography>}
            <div style={{ display: "flex", justifyContent: "center" }}>
              {props.icon}
            </div>
            <Typography
              sx={{ color: "white", fontSize: "1ren", textAlign: "center" }}
            >
              {/* {props.timeFormat ? secondsToHour(props.numbers) : props.numbers} */}
              {/* {props.timeFormat ? secondsToHour(totalItems) : totalItems} */}
              {props.timeFormat ? secondsToHour(totalItems) : (props.eventStatus !== 'stopped'? totalItems : '-')}


            </Typography>
            {props.children}

            {/* Modal start  */}
            <div style={{ display: "flex", justifyContent: "center" }}>

              <Button variant={"contained"} onClick={handleOpen} style={{ background: '#BBB8B2' }}>
                View
              </Button>
              <Modal
                keepMounted
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >


                <Fade in={open}>
                  <Box sx={style}>
                    <Box sx={{ height: 'auto', minHeight: 'auto', width: '100%', overflow: 'scroll' }}>

                      <div className="participants-grid-container">
                        <div className="search-container">


                          <TextField
                            label="Search by email"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            variant="outlined"
                            className="search-input"
                            InputProps={{
                              endAdornment: searchTerm && (
                                <IconButton onClick={handleClearSearch} size="small">
                                  <Clear />
                                </IconButton>
                              ),

                              startAdornment: loadingU && (
                                <CircularProgress />
                              )
                            }}
                          />

                          {/* {searchTerm && (
                    <button className="clear-search-button" onClick={handleClearSearch}>Clear Search</button>
                )} */}
                        </div>

                          {
                              participants.length>0 ? 
                              <table className="participants-table">
                              <thead>
                                <tr>
                                  <th>ID</th>
                                  <th>Name</th>
                                  <th>Email</th>
                                  <th>Join Time</th>
                                  {/* Add more table headers as needed */}
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  filteredParticipants.length > 0 && filteredParticipants.map((participant) => (
                                    <tr key={participant?.userID} className="participant-row">
                                      <td>{participant?.userID}</td>
                                      <td>{participant?.name}</td>
                                      <td>{participant?.email}</td>
                                      <td>{participant?.joinTime}</td>
                                      {/* Render other participant data here */}
                                    </tr>
                                  ))
    
                                }
    
    
                                {
                                  participants.length > 0 && filteredParticipants.length === 0 && participants.map((participant) => (
                                    <tr key={participant?.userID} className="participant-row">
                                      <td>{participant?.userID}</td>
                                      <td>{participant?.name}</td>
                                      <td>{participant?.email}</td>
                                      <td>{participant?.joinTime}</td>
                                      {/* Render other participant data here */}
                                    </tr>
                                  ))
    
                                }
                              </tbody>
                            </table>
                            :


                                <Box display={'flex'} justifyContent={'center'}>
                                  <Typography>No Live users found</Typography>
                                </Box>
                          }
                 

                        {

               participants.length>0 &&         <div className="pagination-controls">
                          <button className="pagination-button" disabled={page === 1} onClick={() => handlePageChange(page - 1)}>Previous</button>
                          <span className="page-info">Page {page} of {totalPages}</span>
                          <button className="pagination-button" disabled={page === totalPages} onClick={() => handlePageChange(page + 1)}>Next</button>

                          {/* <select className="page-size-select" value={pageSize} onChange={(e) => handlePageSizeChange(parseInt(e.target.value))}>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                </select> */}
                        </div>
                        }
                      </div>
                    </Box>

                  </Box>
                </Fade>
              </Modal>
            </div>

            {/* Modal start  */}
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default connect()(CardLayoutLiveUsersScoketioCustomTable);
