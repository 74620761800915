import { Analytics } from "@mui/icons-material";
import { connect, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { keyframes } from "@mui/system";

import { AppBar, Box, Button, Grid, Toolbar, Typography } from "@mui/material";
import React, { useState } from "react";
import SearchFunction from "../components/SearchFunction";
import Stats from "../components/Stats";
import Header from "../components/ui/Header";
import Spinner from "../components/ui/Spinner";
import EventByCompany from "../components/EventsByCompany";
import AlertCard from "../components/alert/AlertCard";
import { getEventByCompanyID, setGlobalFilter } from "../redux/action/stats";
import { useEffect } from "react";
import { setAlert } from "../redux/action/alert";
import io from 'socket.io-client';
import  SocketContainer  from "../components/socketIO/SocketIOContainer";

const StatsHome = (props) => {
  let {socketWc} = props
  const eventType = useSelector((state) => state.stats.eventType);
  const event_ID = useSelector((state) => state.stats.event_ID);

  
  const blink = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;
  const BlinkedBox = styled("Box")({
    backgroundColor: "red",
    borderRadius:'10px',
    padding:'0.5rem',
    animation: `${blink} 1s linear infinite`,
    color: 'white'
  });

  const handleClickCompanyName = () => {
    props.dispatch(getEventByCompanyID(companyId));
    props.dispatch(setGlobalFilter("company", companyName));
  };

  const stats = useSelector((state) => state.stats);

  



  useEffect(() => {
    props.dispatch(setAlert('Analytics data is accurate as of the 31st March 2023', 'error', 10000))

  }, [])

  const { loading, companies, globalFilter, events, searchText, autoCompleteLoading, error, companyName, companyId, eventStartTime, eventEndTime, isLive,
    liveEnded, liveStarted, eventStatus, dateRange } = stats;
  return (
    //Header
    <Box sx={{ flexGrow: 1 }}>
      <AlertCard />
      <Header title={"Analytics Panel"} />


      <SearchFunction />

      {

        globalFilter === 'event' && <Grid container sx={{ display: 'flex' }} spacing={4} p={2}>
          <Grid item xs={12} md={12} lg={12}>

            <Typography sx={{ textAlign: 'center' }} variant='h6'>
              Analytics for {searchText}
          {
            eventType === 'bc' ? 
            <span style={{ color: 'black', padding: '0.5rem', border: '0.5px solid #BBB8B2', margin: '0.5rem' }}>  
              last 30 days
               </span>

            :
            dateRange.selected ? (
              <span style={{ color: 'white', padding: '0.5rem', background:'rgb(45, 52, 54)',borderRadius:'10px' ,margin: '0.5rem' }}>  {new Date(dateRange.start).toDateString()} - {new Date(dateRange.end).toDateString()}  </span>

            ) : (
              <span style={{ color: 'white', padding: '0.5rem', background:'rgb(45, 52, 54)',borderRadius:'10px' ,margin: '0.5rem' }}>  {new Date(eventStartTime).toDateString()} - {new Date(eventEndTime).toDateString()}  </span>

            )
          }


              {
               eventType !== 'bc'  && eventStatus === 'live' && <BlinkedBox>Live</BlinkedBox>
              }
              {
                eventType !== 'bc'  &&  eventStatus === 'stopped' && <span style={{ color: 'black', padding: '0.5rem', border: '0.5px solid #BBB8B2', margin: '0.5rem' }}> Event Ended </span>
              }
              {
                eventType !== 'bc'  &&  eventStatus === 'ondemand' && <span style={{ color: 'black', padding: '0.5rem', border: '0.5px solid #BBB8B2', margin: '0.5rem' }}> On Demand </span>
              }



            </Typography>
          </Grid>
        </Grid>
      }

      {
        globalFilter === 'event' ? (

          (loading ? (<Spinner />) : (
          <SocketContainer socketWc={socketWc} event_ID={event_ID} >
            <Stats socketWc={socketWc}/>
          </SocketContainer>
          
          ))

        ) : (
          globalFilter === 'company' ? (
            (autoCompleteLoading ? (<Spinner />) : (
              error ? (<></>) : (<EventByCompany />)
            ))
          ) : (
            <p>   </p>
          )
        )
      }


      {/* // Results => (list of companies or event) */}

    </Box>


  );
};

export default connect()(StatsHome);
